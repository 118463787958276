import React, {useEffect, useRef, useState} from 'react';
import HeaderNewLandingPage from "../HeaderNewLandingPage/HeaderNewLandingPage";
import FooterNewLandingPage from "../FooterNewLandingPage/FooterNewLandingPage";
import {Alert, Button, Form, FormGroup} from "react-bootstrap";
import {GoogleLogin} from "react-google-login";
import FacebookLoginWithButton from "react-facebook-login";
import FacebookIcon from "../../../assets/images/LandingPageNew/Facebook_icon.svg";
import AppleIcon from "../../../assets/images/apple-logo.png";
import GoogleIcon from "../../../assets/images/search.png";
import "./LoginPage.css"
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import {app} from "../../../Firebase";
import Cookie from "../../../utils/cookie";

const LoginPage = () => {
    const history = useHistory();
    const [username, setUsername] = useState(localStorage.getItem('email') !== null ? localStorage.getItem('email') : '');
    const [password, setPassword] = useState();
    const [loginerror, setemailerror] = useState(false);
    const [errormessage, seterrormessage] = useState("");
    const [sDeviceID, setCurrentIp] = useState(null);
    const [hidden, setHidden] = useState(true);
    const inputRef = useRef();


    useEffect(() => {
        getCurrentIp();
        inputRef.current.focus();
        seterrormessage("Wrong username/password");
    }, []);

    const submitClickBtn = (e) => {
        e.preventDefault();
        const data = {
            email: username,
            password: password,
            sDeviceID,
            sDeviceType: "WEB",
        };
        axios.post(process.env.REACT_APP_SERVER + "auth/login", data, {
            headers: {"Content-Type": "application/json",},
        }).then((res) => {
            Cookie.save("isFirstTime","1");
            document.cookie = `serviceplanid=${
                res.data.users.serviceplanid
            }; expires=${new Date(Date.now() + 10800000).toUTCString()}; path=/`;
            localStorage.setItem("dashboardLayout", res.data.users.checkinbuttononly);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("login", true);
            localStorage.setItem("userid", res.data.users.id);
            localStorage.setItem("email", res.data.users.email);
            localStorage.setItem("name", res.data.users.fname + ' ' + res.data.users.lname);
            localStorage.setItem("isLogin", 1);
            const user = res.data.users
            if (user.newuser === 1 && user.twofactorauth === 1) {
                localStorage.setItem('isAuthenticatedOTP', '0');
                localStorage.setItem('dashboardLayout', 0);
            } else if (user.newuser === 1) {
                localStorage.setItem('isBtnClicked', false)
                localStorage.setItem('isAuthenticatedOTP', '1');
                localStorage.setItem('dashboardLayout', 1);
            } else {
                localStorage.setItem('isAuthenticatedOTP', res.data.users.twofactorauth === 0 ? '1' : '0');
            }
            history.push("/maindashboard");
        }).catch((err) => {
            setemailerror(true);
            seterrormessage("Wrong Username/Password");
        });
    };

    const getCurrentIp = () => {
        axios.get("https://geolocation-db.com/json/")
            .then((res) => {
                setCurrentIp(res.data.IPv4);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const responseGoogle = (response) => {
        axios.post(process.env.REACT_APP_SERVER + 'auth/loginviaapp', {
            email: response.profileObj.email,
            name: response.profileObj.name,
            accessToken: response.accessToken,
            type: 'google'
        }).then((res) => {
            localStorage.setItem("login", true);
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('email', res.data.users.email)
            localStorage.setItem('name', res.data.users.name)
            localStorage.setItem("isLogin", 1);
            localStorage.setItem("userid", res.data.users.id);
            history.push('/maindashboard')
        }).catch((err) => {
            console.log(err)
        })
    }

    const responseFacebook = (response) => {
        axios.post(process.env.REACT_APP_SERVER + 'auth/loginviaapp', {
            email: response.email,
            name: response.name,
            accessToken: response.accessToken,
            type: 'facebook'
        }).then((res) => {
            localStorage.setItem("login", true);
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('email', res.data.users.email)
            localStorage.setItem('name', res.data.users.name)
            localStorage.setItem("isLogin", 1);
            localStorage.setItem("userid", res.data.users.id);
            history.push('/maindashboard')
        }).catch((err) => {
            console.log(err)
        })
    }

    const provider = new OAuthProvider('apple.com');
    const auth = getAuth(app);
    const appleResponse = ()=>{
        signInWithPopup(auth, provider)
            .then((result) => {
                const user = result.user;
                axios.post(process.env.REACT_APP_SERVER + 'auth/loginviaapp', {
                    email: user.email,
                    name: user.displayName,
                    accessToken:  user.accessToken,
                    type: 'apple'
                }).then((res) => {
                    localStorage.setItem("login", true);
                    localStorage.setItem('token', res.data.token)
                    localStorage.setItem('email', res.data.users.email)
                    localStorage.setItem('name', res.data.users.name)
                    localStorage.setItem("isLogin", 1);
                    localStorage.setItem("userid", res.data.users.id);
                    history.push('/maindashboard')
                }).catch((err) => {
                    console.log(err)
                })
            })
            .catch((error) => {
                console.log(error.message)
            });
    }


    return (
        <>
            <HeaderNewLandingPage/>
            <div className="signup_form login_form">
                <div className="form_content">
                    <h2 className="form_title text-center">Login</h2>
                    <Form action="" onSubmit={submitClickBtn}>
                        {loginerror === true && (
                            <Alert
                                key="wrong-login"
                                variant="danger"
                            >
                                {errormessage}
                            </Alert>
                        )}
                        <FormGroup controlId="exampleForm.ControlInput3">
                            <Form.Control
                                ref={inputRef}
                                type="email"
                                placeholder="Email Address"
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup controlId="exampleForm.ControlInput3" className="position-relative">
                            <Form.Control
                                placeholder="Password"
                                type={hidden ? "password" : "text"}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            {hidden ? (
                                <VisibilityOffIcon
                                    className="pass_show"
                                    onClick={() => setHidden(!hidden)}
                                />
                            ) : (
                                <VisibilityIcon
                                    className="pass_show"
                                    onClick={() => setHidden(!hidden)}
                                />
                            )}
                        </FormGroup>
                        <p>
                            <Link to={'/new-password'} css>Forgot Password?</Link>
                        </p>
                        <Button variant="primary" type="submit" className="btn-lg btn-block custom-btn">
                            <span>Login</span>
                        </Button>
                        <div>
                            <div className="googleLogin">
                                <GoogleLogin
                                    className={'googleLoginBtn'}
                                    clientId="766078001860-4nnidh2hnfgqu02ekgsa7g9v59bo0k6d.apps.googleusercontent.com"
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                >
                                    <img src={GoogleIcon} alt="FacebookIcon" style={{marginRight: "10px"}} height="28px" />
                                    <span>Continue with Google</span></GoogleLogin>
                            </div>
                            <div>
                                <FacebookLoginWithButton
                                    className={'FbLoginButton'}
                                    appId="949279355668718"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    onClick={responseFacebook}
                                    callback={responseFacebook}
                                    textButton={"Continue with Facebook"}
                                    icon={<img src={FacebookIcon} alt="FacebookIcon" style={{marginRight: "10px"}}/>}/>
                            </div>
                        </div>
                    </Form>
                    <div className="bottom-link">
                        <Link to={"/new-signup"}>Create an account</Link>
                    </div>
                </div>
            </div>
            <FooterNewLandingPage/>
        </>
    );
};

export default LoginPage;
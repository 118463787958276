import React, {useEffect, useState} from "react";
import {Button, Form, Modal} from "react-bootstrap";
import {
    Checkbox,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import "../../components/newNotesform/Layout/SendTextEmailModal.css";
import axios from "axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import {wish} from "../../utils/wishes.utils";

const headCells = [
    {
        id: "fname",
        numeric: false,
        disablePadding: true,
        label: "Contact",
        align: "left",
    },
    {
        id: "phonenumber",
        numeric: false,
        disablePadding: true,
        label: "Phone Number",
        align: "left",
    },
    {
        id: "email",
        numeric: false,
        disablePadding: true,
        label: "Email Address",
        align: "left",
    },
    {
        id: "sendText",
        numeric: true,
        disablePadding: false,
        label: "Send Text",
        align: "center",
    },
    {
        id: "sendEmail",
        numeric: true,
        disablePadding: false,
        label: "Send Email",
        align: "center",
    },
];

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (

                    <TableCell
                        key={index}
                        align={headCell.align}
                        style={{fontSize: "16px"}}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    container: {
        maxHeight: 500,
    },
    customCell: {
        padding: "8px 16px",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    customHeaderCell: {
        fontSize: "16px",
    },
    shareLinkModalHeader: {
        background: '#007bff',
        color: 'white',
    },
}));

const Invitemodel = ({
         sendShow,
         sendTextEmaildata,
         closeModal,
         sendInviteData,
         nContactID,
         GetContactData
}) => {
    const classes = useStyles();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [isLoading, setIsLoading] = useState(false)

    const getReadMe = (response) => {
        return axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=60`)
            .then((res) => {
                return res.data[0].thetext.replace("&lt;username&gt;",response.sSendersName);
            }).catch((err) => {
            console.log(err)
        })
    }

    const handleShow = () => {
        const token = localStorage.getItem("token");
        const params = {
            params :{
                nUserID: localStorage.getItem("userid"),
                nContactID:nContactID
            },
            headers: {Authorization: `Bearer ${token}`}
        }
        setIsLoading(true)
        axios.post(process.env.REACT_APP_SERVER + 'inventory/shareRequestOwnerSave',{},params)
            .then((response)=> {
                getReadMe(response.data[0]).then((thetext)=>{
                        axios.post(process.env.REACT_APP_SERVER + 'usersendsms', {
                            sphonenumber: (isCheckPhoneNumber && sendInviteData?.phonenumber) ? sendInviteData?.phonenumber : '',
                            smessage: thetext,
                            ssubject: 'keys to my wishes Verification Code',
                            swhoto: sendInviteData?.fname,
                            semailaddress: sendInviteData?.email ? sendInviteData?.email : '',
                            nUserID: localStorage.getItem("userid"),
                            nContactID: nContactID,
                            bTextSent: !!isCheckPhoneNumber,
                            bEmailSent: !!ischeckEmailaddress,
                            sRequestCode:response.data[0].sRequestCode,
                        }).then((res) => {
                            GetContactData();
                            setShow(true);
                            setIsLoading(false)
                        }).catch((err)=>{
                            console.log(err)
                        })
                    })
            }).catch((err)=>{
            console.log(err)
        })
    }


    const handleChangeTxtMsgChange = (value) => {
        setIsCheckPhoneNumber(!isCheckPhoneNumber);
    }

    const handleChangeEmail = (value) => {
        setIsEmailaddress(!ischeckEmailaddress)
    }

    const [isCheckPhoneNumber, setIsCheckPhoneNumber] = useState(!!sendInviteData?.phonenumber);
    const [ischeckEmailaddress, setIsEmailaddress] = useState(!!sendInviteData?.email);

    return (
        <>
            <Modal className="Welcome_modals" show={show} onHide={handleClose}>
                <Modal.Header
                    style={{backgroundColor: "#7030a0", color: "white", border: "none"}}
                >
                    <Modal.Title className="d-flex justify-content-center position-relative">
                        <div className="icon-box">
                            <i className="material-icons">&#xE876;</i>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <div className="mb-4">
                        <div>Your message was successfully sent!.</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={sendTextEmaildata}
                        style={{
                            backgroundColor: "#7030a0",
                            color: "white",
                            border: "none",
                        }}
                    >
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>

            {show === false && (
                <Modal
                    size="xl"
                    show={sendShow}
                    onHide={() => closeModal()}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="ShareRelease-Modal-Container blue-border"
                >
                    <Modal.Header closeButton  className={classes.shareLinkModalHeader}>
                        <Modal.Title style={{fontSize: "1.2rem"}}>
                            Send Text/Email to Share Wish(es)
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="firstdata boldtata" style={{fontSize: "14px"}}>
                                Clicking the Send button will send an email/text to your Contact letting them know you want to Share information with them.
                                Once they have Signed up and login they will enter the Code that will be sent to them. Once they have done that the system will send you a text/email letting you know they are now in the system.
                            </div>
                        </div>
                        <div className="mt-3">
                            <div>
                                <Paper className={classes.paper}>
                                    <TableContainer className={classes.container}>
                                        <Table
                                            className={classes.table}
                                            aria-labelledby="tableTitle"
                                            aria-label="enhanced table"
                                            stickyHeader
                                        >
                                            <EnhancedTableHead/>
                                            <TableBody>
                                                <TableRow hover>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                        style={{fontSize: "16px"}}
                                                    >
                                                        {sendInviteData?.fname + " " + sendInviteData?.lname}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                        style={{fontSize: "16px"}}
                                                    >
                                                        {sendInviteData?.phonenumber}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="left"
                                                        style={{fontSize: "16px"}}
                                                    >
                                                        {sendInviteData?.email}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Checkbox
                                                            checked={isCheckPhoneNumber}
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label": "secondary checkbox",
                                                            }}
                                                            value={sendInviteData?.phonenumber}
                                                            onChange={(e) => handleChangeTxtMsgChange(e.target.value)}
                                                            disabled={!sendInviteData?.phonenumber}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Checkbox
                                                            color="primary"
                                                            inputProps={{
                                                                "aria-label": "secondary checkbox",
                                                            }}
                                                            checked={ischeckEmailaddress}
                                                            value={sendInviteData?.email}
                                                            onChange={(e) => handleChangeEmail(e.target.value)}
                                                            disabled={!sendInviteData?.email}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-center">
                        <Button variant="primary" className="custom-btn"
                                style={{backgroundColor: "#007bff", border: "none", height: '40px', width: '70px'}}
                                onClick={handleShow}
                                disabled={(!isCheckPhoneNumber && !ischeckEmailaddress) || isLoading}>
                            {
                                isLoading
                                    ? <CircularProgress/>
                                    : <span>SEND</span>
                            }
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default Invitemodel;

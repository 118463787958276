export const wish = {
    text:function (text = "") {
        return text.replace("(s)","s").replace("*****","");
    },
    removeHtml:function (html = "") {
        return html.replace(/<\/?[^>]+>/g, "");
    },
    accessApproved:` 
        Your Access Request has been sent to {username}.<br/>
        The person will now be able to view any Wishes you have Shared with them.`,
}
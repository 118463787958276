import React, {useEffect, useState} from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import {Typography, Grid, Card, Box, styled} from "@material-ui/core";
import axios from "axios";
import {Col, Form, FormControl, Row} from "react-bootstrap";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import './Section.css';

const OptionalSection = (props) => {
    const { handleChangeField, optionalData  } = props;
    const [states,setStates] = useState();
    const [expanded,setExpanded] = useState(false);
    const [hidden, setHidden] = useState(true);
    const pathname = window.location.pathname

    useEffect(()=>{
        axios.get(process.env.REACT_APP_SERVER + 'getstates').
        then(res => {
            setStates(res.data);
        });
    },[]);

    useEffect(()=>{
        const { Xbusinessname, Xaccountnumber, Xaddress, Xcity, Xzip, Xurl, Xusername, Xpword, Xstateid } = optionalData;
        if(Xbusinessname || Xaccountnumber || Xaddress || Xcity || Xzip || Xurl || Xusername || Xpword || Xstateid) {
            setExpanded(true);
        }
    },[optionalData])

    const MoreIcon = styled(ExpandMoreIcon)({
        height:"30px !important",
        width:"30px !important"
    })

        return (
        <div style={{marginBottom: 10}} className="accordion_main">
            <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}>
            <div>
                {pathname !== "/newDashboard" &&
                <div style={{color: 'red',paddingTop:"8px",paddingLeft:"8px"}}>
                    Optional Fields
                </div>}
                <AccordionDetails style={{display:'block'}}>
                    <Card style={{boxShadow: 'none'}}>
                        <Typography style={{margin:"10px 10px 0 10px"}}>
                            <Grid container>
                                <Grid item lg={12}>
                                    <Typography  style={{marginRight:15}}>
                                        <Typography>
                                            <Form.Group
                                                as={Row}
                                                controlId="exampleForm.ControlInput2"
                                                className={"businessFormGroup"}
                                            >
                                                <Col sm="3">
                                                    <Form.Label>Business Name:</Form.Label>
                                                </Col>
                                                <Col sm="9">
                                                    <Form.Control
                                                        type="text"
                                                        className="postalCode sort-input-field"
                                                        value={optionalData.Xbusinessname}
                                                        name={"Xbusinessname"}
                                                        placeholder=""
                                                        onChange={handleChangeField}
                                                        maxLength="170"
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Typography>
                                        <Typography>
                                            <Form.Group
                                                as={Row}
                                                controlId="exampleForm.ControlInput2"
                                                className={"formGroup"}
                                            >
                                                <Col sm="3">
                                                    <Form.Label>Account # : </Form.Label>
                                                </Col>
                                                <Col sm="9">
                                                    <Form.Control
                                                        value={optionalData.Xaccountnumber}
                                                        name={"Xaccountnumber"}
                                                        placeholder=""
                                                        onChange={handleChangeField}
                                                        maxLength="170"
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Typography>
                                        <Typography>
                                            <Form.Group
                                                as={Row}
                                                controlId="exampleForm.ControlInput2"
                                                className={"formGroup"}
                                            >
                                                <Col sm="3">
                                                    <Form.Label>Address:</Form.Label>
                                                </Col>
                                                <Col sm="9">
                                                    <Form.Control
                                                        type="text"
                                                        value={optionalData.Xaddress}
                                                        name={"Xaddress"}
                                                        placeholder=""
                                                        onChange={handleChangeField}
                                                        maxLength="170"
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Typography>
                                        <Box>
                                            <Form.Group
                                                as={Row}
                                                controlId="exampleForm.ControlInput2"
                                                className={"formGroup"}
                                            >
                                                <Col sm="3">
                                                    <Form.Label>City:</Form.Label>
                                                </Col>
                                                <Col sm="3" className="mb-sm-0 mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        className="sort-input-field"
                                                        value={optionalData.Xcity}
                                                        name={"Xcity"}
                                                        onChange={handleChangeField}
                                                        maxLength="170"
                                                    />
                                                </Col>
                                                <Col sm="3" sx={{padding:{sm:'0 5px'}}} className="mb-sm-0 mb-3">
                                                    <FormControl
                                                        as="select"
                                                        value={optionalData.Xstateid}
                                                        name={"Xstateid"}
                                                        className="sort-input-field"
                                                        onChange={handleChangeField}
                                                    >
                                                        {states &&
                                                        states.map((e) => {
                                                            return (
                                                                <option key={e.id} value={e.id}>
                                                                    {e.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </FormControl>
                                                </Col>
                                                <Col sm="3" className="mb-sm-0 mb-3">
                                                    <Form.Control
                                                        type="text"
                                                        className="postalCode sort-input-field"
                                                        value={optionalData.Xzip}
                                                        name={"Xzip"}
                                                        maxLength='25'
                                                        placeholder="Zip code"
                                                        onChange={handleChangeField}
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Box>
                                        <Typography>
                                            <Form.Group
                                                as={Row}
                                                controlId="exampleForm.ControlInput2"
                                                className={"formGroup"}
                                            >
                                                <Col sm="3">
                                                    <Form.Label>URL :</Form.Label>
                                                </Col>
                                                <Col sm="9">
                                                    <Form.Control
                                                        type="text"
                                                        value={optionalData.Xurl}
                                                        name={"Xurl"}
                                                        placeholder=""
                                                        onChange={handleChangeField}
                                                        maxLength='170'
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Typography>
                                        <Typography>
                                            <Form.Group
                                                as={Row}
                                                controlId="exampleForm.ControlInput2"
                                                className={"formGroup"}
                                            >
                                                <Col sm="3">
                                                    <Form.Label>User Name :</Form.Label>
                                                </Col>
                                                <Col sm="9">
                                                    <Form.Control
                                                        type="text"
                                                        className="postalCode sort-input-field"
                                                        value={optionalData.Xusername}
                                                        name={"Xusername"}
                                                        placeholder=""
                                                        onChange={handleChangeField}
                                                        maxLength='170'
                                                    />
                                                </Col>
                                            </Form.Group>
                                        </Typography>
                                        <Typography>
                                        <Form.Group
                                            as={Row}
                                            controlId="exampleForm.ControlInput2"
                                            className={"formGroup position-relative"}
                                        >
                                            <Col sm="3">
                                                <Form.Label>Password : </Form.Label>
                                            </Col>
                                            <Col sm="9">
                                                <Form.Control
                                                    type={hidden ? "password" : "text"}
                                                    className="postalCode sort-input-field"
                                                    value={optionalData.Xpword}
                                                    name={"Xpword"}
                                                    placeholder=""
                                                    onChange={handleChangeField}
                                                    maxLength='170'
                                                />
                                                {hidden ? (
                                                    <VisibilityOffIcon
                                                        className="pass_show"
                                                        onClick={() => setHidden(!hidden)}
                                                    />
                                                ) : (
                                                    <VisibilityIcon
                                                        className="pass_show"
                                                        onClick={() => setHidden(!hidden)}
                                                    />
                                                )}
                                            </Col>
                                        </Form.Group>
                                    </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} sm={12} style={{height:'100%'}}>
                                    <Typography style={{marginRight:20}}>
                                        <Form.Group
                                            as={Row}
                                            controlId="exampleForm.ControlInput2"
                                            className={"formGroup"}
                                        >
                                            <Col sm="3">
                                                <Form.Label>Contact Info : </Form.Label>
                                            </Col>
                                            <Col sm="9">
                                                {/*<Editor*/}
                                                {/*    value={optionalData.Xcontactinfo}*/}
                                                {/*    editorState={optionalData.Xcontactinfo}*/}
                                                {/*    init={{*/}
                                                {/*        menubar: false,*/}
                                                {/*        toolbar: false,*/}
                                                {/*        plugins: " fullpage  fullscreen  link advlist lists  ",*/}
                                                {/*        content_style: "body p { line-height: 1.5; margin: 0 ; height:100%;}",*/}
                                                {/*        height:80,*/}
                                                {/*    }}*/}
                                                {/*    onEditorChange={(e)=>handleChangeField(e,'richText')}*/}
                                                {/*/>*/}
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder=""
                                                    rows={2}
                                                    name={"Xcontactinfo"}
                                                    required
                                                    onChange={(e) => handleChangeField(e,'richText')}
                                                    style={{ overflowY: "hidden" }}
                                                    maxLength='170'
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Typography>
                    </Card>
                </AccordionDetails>
            </div>
            </Accordion>
        </div>
    );
}

export default OptionalSection;
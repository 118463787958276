import React, {useEffect, useState} from 'react';
import Header from "../../components/NewHeader/Header";
import {Col, Row} from "react-bootstrap";
import Back from "../../assets/images/Back_white.png";
import {useHistory} from "react-router-dom";
import howToVideo from "../../assets/videos/howToVideo.mp4";
import "./how-to-screen.css"
import axios from "axios";

const HowToScreen = () => {
    let history = useHistory()
    const [readmeContent, setReadmeContent] = useState(false);

    useEffect(()=>{
        getReadMe();
    },[])

    const getReadMe = () => {
        return axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=65`)
            .then((res) => {
                setReadmeContent(res.data[0]);
            }).catch((err) => {
                console.log(err)
            })
    }
    return (
        <>
            <Header title="HOW TO" />
            <Row className="mx-auto text-center mobile-button back-logout-btn">
                <Col
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="p-2 text-start profile-left-btn d-flex btnall"
                >
                    <span className="btn custom-link btn-primary" onClick={()=>history.goBack()}>
                        <img src={Back} alt="Back" className="w-5" /> Back
                    </span>
                </Col>
            </Row>

            <div className="how_to_video_container mt-5">
                <video
                    controls
                    autoPlay
                    height="600"
                    src={howToVideo}
                    className="how_to_video"
                />
                <div dangerouslySetInnerHTML={{__html:readmeContent.thetext}}/>
            </div>
        </>
    );
};

export default HowToScreen;
import React from "react";
import './FooterNewLandingPage.css';
import keysNewLogo from "../../../assets/images/LandingPageNew/keytomywishesnew.png";
import {Link} from "react-router-dom";
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';


const FooterNewLandingPage = () => {
    return (
        <div className='main_footer'>
            <div className="logo_img text-center">
                    <img src={keysNewLogo}/>
            </div>
            <div className="social_media_icons">
                <ul className="justify-content-center flex-wrap">
                    <li className="social_media_items"><p><TwitterIcon/></p></li>
                    <li className="social_media_items"><p><YouTubeIcon/></p></li>
                    <li className="social_media_items"><p><InstagramIcon/></p></li>
                    <li className="social_media_items"><p><FacebookIcon/></p></li>
                </ul>
            </div>
            <div className="footer_menu">
                <ul className="justify-content-center flex-wrap">
                    <li><Link to="/About-Us">About Us</Link></li>
                    <li><Link to="/how-its-work">How its Works</Link></li>
                    <li><Link to="/terms-of-use">Terms of Services</Link></li>
                    <li><Link to="/privacy-policy">Privacy Notice</Link></li>
                    <li><Link to="/faq">FAQs</Link></li>
                </ul>
            </div>
            <div className="copyright">
                <p className="m-0 text-center">© 2022 Keystomywishes All Right Reserved</p>
            </div>
        </div>
    )
}
export default FooterNewLandingPage;
import React from 'react';
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";

const PhoneNumberField = ({phoneNumber,setPhoneNumber}) => {
    return (
        <PhoneInput
            country="us"
            onlyCountries={['us']}
            countryCodeEditable={false}
            disableDropdown
            value={phoneNumber}
            onChange={(value) => {
                const phoneNumber = `+${value.slice(0,11)}`;
                setPhoneNumber(phoneNumber);
            }}
        />
    );
};

PhoneNumberField.propTypes ={
    setPhoneNumber:PropTypes.func,
    value:PropTypes.any,
}

export default PhoneNumberField;
import React, { Component, useState } from "react";
import "./HelpingSection.css";
import banner_right_image from "../../../assets/images/LandingPageNew/banner_right_image.png";
import app_store from "../../../assets/images/LandingPageNew/app_store.svg";
import google_play from "../../../assets/images/LandingPageNew/google_play.svg";
import main_circle from "../../../assets/images/LandingPageNew/BannerBgImage/main_circle.svg";
import circle1 from "../../../assets/images/LandingPageNew/BannerBgImage/circle_1.svg";
import circle2 from "../../../assets/images/LandingPageNew/BannerBgImage/circle_2.svg";
import triangle1 from "../../../assets/images/LandingPageNew/BannerBgImage/triangle_1.svg";
import triangle2 from "../../../assets/images/LandingPageNew/BannerBgImage/triangle_2.svg";
import triangle3 from "../../../assets/images/LandingPageNew/BannerBgImage/triangle_3.svg";
import arrow_line from "../../../assets/images/LandingPageNew/BannerBgImage/arrow_line.svg";
import key from "../../../assets/images/LandingPageNew/key.svg";
import RepresentativesContentBg from "../../../assets/images/LandingPageNew/representatives_content_bg.svg";
import RepresentativesLeftBg from "../../../assets/images/LandingPageNew/representatives_left_bg.svg";
import FeaturesLeftBg from "../../../assets/images/LandingPageNew/features_left_bg.svg";
import FeaturesRightBg from "../../../assets/images/LandingPageNew/features_right_bg.svg";
import thought_left_img from "../../../assets/images/LandingPageNew/thought_left_img.jpg";
import FeaturesCard1 from "../../../assets/images/LandingPageNew/features_card1.svg";
import FeaturesCard2 from "../../../assets/images/LandingPageNew/features_card2.svg";
import FeaturesCard3 from "../../../assets/images/LandingPageNew/features_card3.svg";
import FeaturesCard4 from "../../../assets/images/LandingPageNew/features_card4.svg";
import FeaturesCard5 from "../../../assets/images/LandingPageNew/features_card5.svg";
import FeaturesCard6 from "../../../assets/images/LandingPageNew/features_card6.svg";
import rightIcon from "../../../assets/images/LandingPageNew/right_icon.svg";
import rightIconMobile from "../../../assets/images/rightIcon.png";
import ScenariosCardImg1 from "../../../assets/images/LandingPageNew/scenarios_card_img_1.svg";
import ScenariosCardImg2 from "../../../assets/images/LandingPageNew/scenarios_card_img_2.svg";
import ClarityEmailLine from "../../../assets/images/LandingPageNew/clarity_email-line.svg";
import CarbonPhone from "../../../assets/images/LandingPageNew/carbon_phone.svg";
import solutionBG from "../../../assets/images/LandingPageNew/solutionBG.svg";
import solutionBGRight from "../../../assets/images/LandingPageNew/solutionBGRight.svg";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { TextField } from "@material-ui/core";
import {Button, Form, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import Maskgroup1 from "../../../assets/images/Maskgroup1.svg";
import  Maskgroup2 from "../../../assets/images/Maskgroup2.svg";
import  Maskgroup3 from "../../../assets/images/Maskgroup3.svg";
import  Maskgroup4 from "../../../assets/images/Maskgroup4.svg";
import  Maskgroup5 from "../../../assets/images/Maskgroup5.svg";
import  Maskgroup6 from "../../../assets/images/Maskgroup6.svg";
import Standardfeatures from "../../../components/standardfeatures/standardfeatures";

class HelpingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: "",
      email: "",
      Message: "",
      pricingType: "yearly",
      sentSuccess: false,
      massagemodel: true,
      toggleModal:false,
    };
  }

  handleSubmitContact = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { fname, email, message } = this.state;
    axios
      .post(process.env.REACT_APP_SERVER + "usergetandsendemail", {
        sfullname: fname,
        semailaddress: email,
        smessage: message,
      })
      .then((res) => {
        this.setState({
          fname: null,
          email: null,
          message: null,
          sentSuccess: true,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handlecloseMassageModel = () => {
    this.setState({ fname: "" });
    this.setState({ email: "" });
    this.setState({ message: "" });
    this.setState({ massagemodel: false });
  };

  handlecloseDownloadModal =()=>{
      this.setState({ toggleModal: false });
  }
  handleShowModal = () =>{
      this.setState({ toggleModal: true });
  }

  render() {
    const {
      message,
      email,
      fname,
      pricingType,
      isLoading,
      sentSuccess,
      massagemodel,
      toggleModal
    } = this.state;
    return (
      <>
        {sentSuccess && (
          <Modal show={massagemodel} onHide={this.handlecloseMassageModel}>
            <Modal.Header
              closeButton
              style={{ backgroundColor: "#7030a0", color: "white" }}
            >
              <Modal.Title>Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>
                Thank you. Your email was sent to Keys To My Wishes Support
                Staff and we will get back to you soon!
              </span>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btngenie"
                onClick={this.handlecloseMassageModel}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}

          <Modal show={toggleModal} onHide={this.handlecloseDownloadModal}>
              <Modal.Header
                  closeButton
                  style={{ backgroundColor: "#7030a0", color: "white" }}
              >
                    <Modal.Title>Coming Soon</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  You will be able to download our Mobile app very soon.<br/>
                   Check back with us.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button className="btngenie" onClick={this.handlecloseDownloadModal}>
                  Close
                </Button>
              </Modal.Footer>
          </Modal>
        <div className="banner_section">
          <div className="banner_content">
            <div className="row m-0 justify-content-center">
              <div className="col-xl-6 col-lg-6 mb-3 align-self-center">
                <div className="banner_details">
                  <h1 className="banner_title"> We've got your back!</h1>
                  <div className="your-info-section">
                    <p className={"our_plan"}>
                      Your Information, Your Control: Our <br/>
                      Commitment to Confidentiality and Your Privacy
                    </p>
                    <p className={"our_plan"}>
                      You decide what and when we should <br/> release your important information to your <br/> loved ones.
                    </p>
                  </div>


                  <p className="banner_description">
                    If you were in an accident today would your loved ones know
                    where to find your private important information/documents?
                  </p>
                  <div className="download_btn_group">
                    <a>
                      <img src={app_store} alt="app_store" onClick={this.handleShowModal} />
                    </a>
                    <a>
                      <img src={google_play} alt="google_play" onClick={this.handleShowModal} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="banner_right_img text-center">
                  <img src={banner_right_image} />
                </div>
              </div>
            </div>
          </div>
          <div className="banner_bg_image">
            <img
              src={main_circle}
              className="bg_img main_circle"
              alt="main_circle"
            />
            <img src={circle1} className="bg_img circle1" alt="circle1" />
            <img
              src={circle2}
              className="bg_img circle1 circle2"
              alt="circle2"
            />
            <img src={triangle1} className="bg_img triangle1" alt="triangle1" />
            <img
              src={triangle1}
              className="bg_img triangle1_1"
              alt="triangle1"
            />
            <img src={triangle2} className="bg_img triangle2" alt="triangle2" />
            <img src={triangle3} className="bg_img triangle3" alt="triangle3" />
            <img
              src={arrow_line}
              className="bg_img arrow_line"
              alt="arrow_line"
            />
          </div>
        </div>


        <div className="representatives_section">
          <div className="representatives_content d-flex flex-column justify-content-center">
            <h3 className={"text-center representatives_content_header"}>
              OUR GOAL IS TO CONVEY YOUR FINAL WISHES TO YOUR LOVED ONES - IN YOUR WORDS AND IN YOUR WAY!
            </h3>
            <p className="representatives_label text-center my-5">
              Real-Life Representatives to Keep In Touch with You
            </p>
            <div className=" m-auto p-0 m-0 representatives_label_main">
              <div className="row h-100 m-0 justify-content-between representatives_section_list">
                <div className="tab_content d-flex align-items-center">
                  <img src={key} className="mr-3" alt="key" />
                  <Link to="/keep-in-touch">Keep In Touch/CHECK-IN!</Link>
                </div>
                <div className="tab_content d-flex align-items-center">
                  <OverlayTrigger
                      overlay={
                        <Tooltip id={`tooltip-top`} style={{zIndex:'999999999999'}}>
                          Break the Glass is the feature that takes place no matter which plan you choose. At anytime something happens to the account holder our process is triggered to verify that you are ok or not. If not we will release your Wishes based on your settings.
                        </Tooltip>
                      }
                  >
                    <div>
                      <img src={key} className="mr-3" alt="key" />
                        <Link to="/break-the-glass">
                        Break the Glass!
                      </Link>
                    </div>
                  </OverlayTrigger>
                </div>
                <div className="tab_content d-flex align-items-center">
                  <img src={key} className="mr-3" alt="key" />
                  <Link to="/how-it-can-help-me">How it can Help Me?</Link>
                </div>
                <div className="tab_content d-flex align-items-center">
                  <img src={key} className="mr-3" alt="key" />
                  <Link to="/why-it-matters">Why it Matters?</Link>
                </div>
                <div className="tab_content d-flex align-items-center">
                  <img src={key} className="mr-3" alt="key" />
                  <Link to="/Confidential">Confidential</Link>
                </div>
                <div className="tab_content d-flex align-items-center">
                  <img src={key} className="mr-3" alt="key" />
                  <Link to="/easy-steps">4 Easy Steps!</Link>
                </div>
              </div>
              <img
                src={RepresentativesContentBg}
                className="RepresentativesContentBg"
                alt="RepresentativesContentBg"
              />
            </div>
            <h2 className="representatives_title text-center my-5">
              We Convey Your Wishes When You Cannot!!!
            </h2>
          </div>
          <img
            src={RepresentativesLeftBg}
            className="representatives_bg"
            alt="RepresentativesLeftBg"
          />
        </div>

        <div className="thought_section">
          <div className="title text-center">
            <h2>Give it Some Thought!</h2>
          </div>
          <div className="thought_content">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 mb-3">
                <div className="thought_left">
                  <img src={thought_left_img} alt="" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 ">
                <ul className="thought_right">
                  <li className="thought_item">
                    <p>
                      Imagine the heartbreak, anguish, grief and tears of your
                      spouse, children, relatives and friends after you have
                      passed. Imagine these things along with the stress and
                      arduous task of trying to locate all your vital documents.
                      Is that what you want for your loved ones to go through?
                    </p>
                  </li>
                  <li className="thought_item">
                    <p>
                      For various reasons, it can be difficult or even
                      inappropriate to discuss your final wishes with loved
                      ones. However most agree it is important for loved ones to
                      know of their final wishes after they are gone. What do
                      you have in place to insure that loved ones can locate and
                      find out these things easily at the end of your life?
                    </p>
                  </li>
                  <li className="thought_item">
                    <p>
                      If due to some sudden and unforeseen life changing event,
                      you are no longer able to communicate to your loved ones
                      your wishes or other important information, what impact
                      would that have on them?
                    </p>
                  </li>
                  <li className="thought_item">
                    <p>
                      Hurricanes, floods, earthquakes, wild fires, tornados,
                      seem to be in the news more frequently. These natural
                      disasters can destroy everything making it difficult to
                      rebuild lives. Are your wishes and important information
                      backed up so they cannot be impacted by a natural
                      disaster?
                    </p>
                  </li>
                  <li className="thought_item">
                    <p>
                      If necessary, can you easily access your important
                      information anytime from anywhere?
                    </p>
                  </li>
                  <li className="thought_item">
                    <p>
                      Have you tried to convey your wishes or information about
                      your final wishes to family members, especially young
                      adults, and they did not want to listen? Did they pretend
                      to listen but you knew they really weren’t paying
                      attention because it was too painful to think of you not
                      being here?
                    </p>
                  </li>
                  <li className="thought_item">
                    <p>
                      Have you thought about updating your Will or Trust lately
                      but it seems like too much of a hassle? Do you want to be
                      reminded to do so periodically?
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="solution_section">
          <div className="title text-center">
            <h2>KeysToMyWishes IS Your Solution</h2>
            <p>Store the LOCATION of your Digital Assets</p>
          </div>
          <div className="solution_content">
            <div className="row m-0 align-items-center">
              <div className="solution_tab">
                <div className="solution_tab_content">
                  <span>Bank Accounts</span>
                </div>
                <div className="solution_tab_content">
                  <span>P.O. Box</span>
                </div>
              </div>
              <div className="solution_tab">
                <div className="solution_tab_content">
                  <span>Stocks Bonds</span>
                </div>
                <div className="solution_tab_content">
                  <span>Passwords</span>
                </div>
                <div className="solution_tab_content">
                  <span>Medical Information</span>
                </div>
                <div className="solution_tab_content">
                  <span>Employment Info</span>
                </div>
              </div>
              <div className="solution_tab">
                <div className="solution_tab_content">
                  <span>My Will</span>
                </div>
                <div className="solution_tab_content">
                  <span>Living Trusts</span>
                </div>
                <div className="solution_tab_content">
                  <span>Life Insurance</span>
                </div>
                <div className="solution_tab_content">
                  <span>Safe Deposit Box</span>
                </div>
              </div>
              <div className="solution_tab">
                <div className="solution_tab_content">
                  <span>Real Estate</span>
                </div>
                <div className="solution_tab_content">
                  <span>Social Security</span>
                </div>
                <div className="solution_tab_content">
                  <span>My Pension</span>
                </div>
                <div className="solution_tab_content">
                  <span>My Directives</span>
                </div>
              </div>
              <div className="solution_tab">
                <div className="solution_tab_content">
                  <span>Attorney Information</span>
                </div>
                <div className="solution_tab_content">
                  <span>Crypto Currency</span>
                </div>
              </div>
            </div>
          </div>
          <img
            src={solutionBG}
            className="solutionBGLeftTop"
            alt="solutionBG"
          />
          <img
            src={solutionBGRight}
            className="solutionBGRight"
            alt="solutionBGRight"
          />
        </div>

        <div className="features_section">
          <div className="title text-center">
            <p>App Features</p>
            <h2>Get surprised by amazing features </h2>
          </div>
          <div className="features_content d-flex">
            <div className="row m-0 d-flex">
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="features_card">
                  <div className="features_img_content features_img_content_1">
                    <div className="features_img">
                      <img src={FeaturesCard1} alt="FeaturesCard1" />
                    </div>
                  </div>
                  <h4 className="features_title">Family Protection</h4>
                  <p className="features_description">
                    App allows you to easily store your important instructions
                    and documents. We will release your information to your
                    loved ones based on your instructions as to when to release
                    the information. We convey what you cannot convey.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="features_card">
                  <div className="features_img_content features_img_content_2">
                    <div className="features_img">
                      <img src={FeaturesCard2} alt="FeaturesCard2" />
                    </div>
                  </div>
                  <h4 className="features_title">Keep In Touch</h4>
                  <p className="representatives_link">
                    <a href="">Real-life Representatives</a>
                  </p>
                  <p className="features_description">
                    App allows you to easily store your important instructions
                    and documents. We will release your information to your
                    loved ones based on your instructions as to when to release
                    the information. We convey what you cannot convey.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="features_card">
                  <div className="features_img_content features_img_content_3">
                    <div className="features_img">
                      <img src={FeaturesCard3} alt="FeaturesCard3" />
                    </div>
                  </div>
                  <h4 className="features_title">Security</h4>
                  <p className="features_description">
                    Security is our top priority We use bank-level 256-bit
                    encryption for your data and storage of files <br /> Two
                    Factor Authentication <br /> Biometrics - mobile apps
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="features_card">
                  <div className="features_img_content features_img_content_4">
                    <div className="features_img">
                      <img src={FeaturesCard4} alt="FeaturesCard4" />
                    </div>
                  </div>
                  <h4 className="features_title">Your Data</h4>
                  <div className="d-flex your_date_content">
                    <div className="w-50 px-2">
                      <p className="representatives_link">
                        <a href="">Private/Confidential</a>
                      </p>
                      <p className="features_description">
                        Only you have access to your data. You can set up who
                        should receive what information when the inevitable
                        happens. .
                      </p>
                    </div>
                    <div className="w-50 px-2">
                      <p className="representatives_link">
                        <a href="">Share Capability</a>
                      </p>
                      <p className="features_description">
                        Our app allows you to share information that you want to
                        your loved ones to be aware of even before you become
                        incapacitated or die.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="features_card">
                  <div className="features_img_content features_img_content_5">
                    <div className="features_img">
                      <img src={FeaturesCard5} alt="FeaturesCard5" />
                    </div>
                  </div>
                  <h4 className="features_title">Convey Your Wishes</h4>
                  <p className="features_description">
                    Should you become Incapacitated or Death each of our plans
                    provide the ability for your Final Wishes to be conveyed by
                    our system when you cannot. This feature is totally
                    configurable by you to say when to Release information, to
                    Whom and what to Release.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-3">
                <div className="features_card">
                  <div className="features_img_content features_img_content_6">
                    <div className="features_img">
                      <img src={FeaturesCard6} alt="FeaturesCard6" />
                    </div>
                  </div>
                  <h4 className="features_title">Use From Anywhere</h4>
                  <p className="features_description">
                    Used from Anywhere at Anytime Web, iOS, Android information
                    is at your fingertips at all times
                  </p>
                </div>
              </div>
            </div>
          </div>
          <img
            src={FeaturesLeftBg}
            className="FeaturesLeftBg"
            alt="FeaturesLeftBg"
          />
          <img
            src={FeaturesRightBg}
            className="FeaturesRightBg"
            alt="FeaturesRightBg"
          />
        </div>

        <div className="service_plan_section">
          <div className="title text-center">
            <h2 className="service_plan_title">CHOOSE A PLAN AND START YOUR<br/>
              14-DAY TRIAL!</h2>
            <h6 className="service_plan_des">Save 2 Months Free with Annual Billing</h6>
            <div className="plan_btn_group">
              <button
                className={`${
                  pricingType === "monthly" ? "btn active" : "btn"
                }`}
                onClick={() => this.setState({ pricingType: "monthly" })}
              >
                Monthly
              </button>
              <button
                className={`${pricingType === "yearly" ? "btn active" : "btn"}`}
                onClick={() => this.setState({ pricingType: "yearly" })}
              >
                Annual
              </button>
            </div>
          </div>
        </div>

        <div className="service_plan_section">
          <div className="service_plan_main">
          <table className="text-center service_plan_table w-100">
            <tbody>
             <tr>
               <td className="sticky-col"></td>
               <td>
                 <div className="d-flex justify-content-center">
                 <div className="service_plan_box">
                   <img src={Maskgroup1}/>
                 </div>
                 </div>
               </td>
               <td>
                 <div className="d-flex justify-content-center">
                   <div className="service_plan_box">
                     <img src={Maskgroup2}/>
                   </div>
                 </div>
               </td>
               <td>
                 <div className="d-flex justify-content-center">
                   <div className="service_plan_box">
                     <img src={Maskgroup3}/>
                   </div>
                 </div>
               </td>
               <td>
                 <div className="d-flex justify-content-center">
                   <div className="service_plan_box">
                     <img src={Maskgroup4}/>
                   </div>
                 </div>
               </td>
               <td>
                 <div className="d-flex justify-content-center">
                   <div className="service_plan_box">
                     <img src={Maskgroup5}/>
                   </div>
                 </div>
               </td>
               <td>
                 <div className="d-flex justify-content-center">
                   <div className="service_plan_box">
                     <img src={Maskgroup6}/>
                   </div>
                 </div>
               </td>


             </tr>
            <tr>
              <td className="sticky-col"></td>
              <td>Yearly</td>
              <td>Quarterly</td>
              <td>Monthly</td>
              <td>Weekly</td>
              <td>3-days</td>
              <td>Daily</td>
            </tr>
            <tr>
              <td className="sticky-col"></td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
              <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
            </tr>
            <tr>
              <td className="text-left price-lable sticky-col">Price</td>
              <td>
                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $4.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $49.90<span>/yr</span>
                    </h2>
                )}
              </td>
              <td>

                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $6.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $69.90<span>/yr</span>
                    </h2>
                )}

              </td>
              <td>
                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $9.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $99.90<span>/yr</span>
                    </h2>
                )}
              </td>
              <td>
                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $14.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $149.90<span>/yr</span>
                    </h2>
                )}
              </td>
              <td>
                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $19.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $199.90<span>/yr</span>
                    </h2>
                )}
              </td>
              <td>
                {pricingType === "monthly" ? (
                    <h2 className="plan_price">
                      $24.99<span>/mo</span>
                    </h2>
                ) : (
                    <h2 className="plan_price">
                      $249.90<span>/yr</span>
                    </h2>
                )}
              </td>
            </tr>
            <tr>
              <td className="text-left sticky-col">Check In Frequency</td>
              <td>Yearly</td>
              <td>Quarterly</td>
              <td>Monthly</td>
              <td>Weekly</td>
              <td>3 Days/Week</td>
              <td>Daily</td>
            </tr>
            <tr>
              <td className="text-left sticky-col"> KTMW Standard Features</td>
              <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
              <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
              <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
              <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
              <td><img src={rightIcon} alt="rightIcon" width="34px" /></td>
              <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
            </tr>
            <tr>
              <td className="sticky-col"></td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
              <td>
                <p className="plan_start_btn">
                  <Link to="new-signup" className="btn">
                    Start For Free
                  </Link>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
          </div>
        </div>

        <Standardfeatures box={true}/>


        <div className="scenarios_section">
          <div className="title text-center">
            <p>SCENARIOS</p>
            <h2>What People Are Talking About. </h2>
          </div>
          <div className="scenarios_content">
            <div className="row m-0 scenarios-slider">
              <Carousel
                className="d-none d-sm-block"
                showThumbs={false}
                autoPlay={false}
                swipeable={false}
                emulateTouch={false}
                showStatus={true}
                dynamicHeight={false}
                showArrows={true}
                showIndicators={false}
                interval={8000}
                infiniteLoop={true}
              >
                {/*<div className="col-lg-6">*/}
                <div className="d-flex">
                  <div className="scenarios_card scenarios_card_1">
                    <div className="scenarios_card_img">
                      <img src={ScenariosCardImg1} alt="ScenariosCardImg1" />
                    </div>
                    <h2 className="scenarios_card_title">- Sarah W</h2>
                    <p className="scenarios_card_description">
                      “When my Mother passed away suddenly, I had no idea where
                      to start looking for her insurance policy or Will. I was
                      pretty sure she had those things in place but where she
                      kept them was a mystery to me and my siblings. We were all
                      devastated and stressed as we mourned her loss and had the
                      arduous task of trying to locate her papers. It took 10
                      hard and stress-filled months before we found everything.”
                    </p>
                    <p className="solution_link mb-2">
                      <a href="">Keys to My Wishes Solution</a>
                    </p>
                    <p className="scenarios_card_description">
                      Our service is designed to store either the location of
                      these types of documents or to upload them into our
                      “information storage vault”. Grieving families can then
                      have a centralized place to go and easily locate this
                      important information. While it doesn’t lessen the pain of
                      losing a loved one, it does take away much of the stress
                      and frustration associated with trying to find those vital
                      documents.
                    </p>
                    {/*</div>*/}
                  </div>
                  {/*<div className="col-lg-6">*/}
                  <div className="scenarios_card scenarios_card_2">
                    <div className="scenarios_card_img">
                      <img src={ScenariosCardImg2} alt="ScenariosCardImg2" />
                    </div>
                    <h2 className="scenarios_card_title">- Margaret H</h2>
                    <p className="scenarios_card_description">
                      I am 77 years old and a widow now living alone.
                      Unfortunately my son lives in another State and even
                      though he wants me to move closer, I can’t bring myself to
                      leave the home and memories me and George built for over
                      50 years. Also, living in a colder climate would never
                      work for me. Even though I talk to my son and
                      grandchildren weekly, my biggest fear is falling, not
                      being able to get up and not being discovered for another
                      week. By then it may be too late
                    </p>
                    <p className="solution_link mb-2">
                      <a href="">Keys to My Wishes Solution</a>
                    </p>
                    <p className="scenarios_card_description">
                      With our “daily” keep in touch service, you will check-in
                      to our service between your selected time frame to
                      indicate you are not incapacitated. If we have not heard
                      from you, we will send you 2 text message reminders to
                      check-in. If we haven’t heard from you, we place 2
                      separate phone calls to verify you are ok. If you do not
                      respond, we then will call your emergency contact(s) to
                      perform a wellness check on you. With our “daily” keep in
                      touch service, you are never alone. You can have peace of
                      mind knowing we will contact your designated love ones if
                      we do not hear from you.
                    </p>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="d-flex">
                  <div className="scenarios_card scenarios_card_1">
                    <div className="scenarios_card_img">
                      <img src={ScenariosCardImg1} alt="ScenariosCardImg1" />
                    </div>
                    <h2 className="scenarios_card_title">- Linda P</h2>
                    <p className="scenarios_card_description">
                      “After a brief illness my husband passed away. He took
                      care of all the household finances, paid all the bills,
                      made all the investments, hired all the handymen to do the
                      renovations and repairs in our home. He did everything and
                      I was perfectly happy with that. When he became ill, I was
                      in denial that it was not that serious and knew he would
                      get well. He didn’t. After he passed away it was up to me
                      to keep things going. Where to begin and what to do was a
                      mystery. Where was all the paperwork to everything? Who
                      did I need to contact to get things done? I became
                      depressed and found myself wanting to give up because it
                      seemed overwhelming.”
                    </p>
                    <p className="solution_link mb-2">
                      <a href="">Keys to My Wishes Solution</a>
                    </p>
                    <p className="scenarios_card_description">
                      With Keys to My Wishes, Linda’s husband could have all
                      this important information stored electronically in our
                      “information storage vault”. Linda would then have easy
                      access to things such as creditors, handyman contacts,
                      list of investments and all relevant information, document
                      location, passwords, etc. This may have helped ease the
                      feeling of overwhelm and wanting to give up. She could
                      have had a road map to continue managing their financial
                      affairs.
                    </p>
                    {/*</div>*/}
                  </div>
                  {/*<div className="col-lg-6">*/}
                  <div className="scenarios_card scenarios_card_2">
                    <div className="scenarios_card_img">
                      <img src={ScenariosCardImg2} alt="ScenariosCardImg2" />
                    </div>
                    <h2 className="scenarios_card_title">- Barbara A</h2>
                    <p className="scenarios_card_description">
                      “Both my God-parents were elderly and ailing. My
                      Godfather, however, was determined he should be the main
                      caregiver for the love of his life, who was bed ridden as
                      a result of a stroke. Fortunately once a week a RN would
                      come to the house to provide additional care for my
                      Godmother. When my Godfather failed to answer the door,
                      the RN contacted the police department to enter the home
                      and perform a welfare check. My Godfather was found on the
                      kitchen floor and it was obvious he had been there for a
                      few days since my Godmother was soiled, dehydrated and had
                      not been fed.”
                    </p>
                    <p className="solution_link mb-2">
                      <a href="">Keys to My Wishes Solution</a>
                    </p>
                    <p className="scenarios_card_description">
                      If a family member had registered this elderly man for
                      Keys to My Wishes, “daily” Keep in Touch check-in aspect
                      of our service and if he had failed to check-in or respond
                      to the reminder alerts, we would have called people on
                      their emergency contact listed. A welfare check could have
                      been made within a matter of hours versus days. While
                      there’s no guarantee it would have been in time to save
                      the life of the man, the wife would have received care
                      within hours so she did not have to suffer and be in the
                      bad shape in which she was found.
                    </p>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="d-flex">
                  <div className="scenarios_card scenarios_card_1">
                    <div className="scenarios_card_img">
                      <img src={ScenariosCardImg1} alt="ScenariosCardImg1" />
                    </div>
                    <p className="scenarios_card_description">
                      “How often do we hear in the news of natural disasters
                      such as hurricanes, tornados, wild fires, floods,
                      destroying people’s homes? We see that way too often
                      little if anything is saved including important documents
                      and irreplaceable possessions. How are those things
                      replaced?”
                    </p>
                    <p className="solution_link mb-2">
                      <a href="">Keys to My Wishes Solution</a>
                    </p>
                    <p className="scenarios_card_description">
                      With our service, your important documents can be uploaded
                      and stored in our electronic “information storage vault”.
                      Each client receives 3 GB of free disk storage for such
                      important documents and additional disk storage can be
                      purchased for a nominal amount. Using your log in
                      credentials, you can gain access to your important
                      documents from any electronic device. If you don’t
                      remember your log in credentials, simply contact us and
                      after verifying your identity, we can help you access your
                      account. The benefit to you is your important documents
                      are centralized in one easy to access location which saves
                      you many sleepless nights and long hours trying to
                      reconstruct these things.
                    </p>
                    {/*</div>*/}
                  </div>
                  {/*<div className="col-lg-6">*/}
                  <div className="scenarios_card scenarios_card_2">
                    <div className="scenarios_card_img">
                      <img src={ScenariosCardImg2} alt="ScenariosCardImg2" />
                    </div>
                    <h2 className="scenarios_card_title">- Michael C</h2>
                    <p className="scenarios_card_description">
                      “Mom passed away over 30 years ago. In 2021 someone told
                      me that my name was seen on a “claim property” website. I
                      discovered that mom had stock in one of the airplane
                      producing companies years ago and neither me nor my
                      siblings knew anything about it. Fortunately we were able
                      to recover the proceeds many years later.”
                    </p>
                    <p className="solution_link mb-2">
                      <a href="">Keys to My Wishes Solution</a>
                    </p>
                    <p className="scenarios_card_description">
                      Michael and his siblings were lucky they were able to get
                      the proceeds from their mom’s investments from many years
                      prior. Of course our service was not around then; however,
                      if Michael or his siblings make such an investments today
                      and stored that information into Keys to My Wishes, their
                      children will be able to locate that information much
                      sooner and easier. They will be able to reap the financial
                      rewards of their parent’s investment today and not have to
                      wait to discover such investments 30 years later.
                    </p>
                  </div>
                  {/*</div>*/}
                </div>
              </Carousel>
              <Carousel
                className="d-block d-sm-none "
                showThumbs={false}
                autoPlay={false}
                swipeable={false}
                showStatus={false}
                dynamicHeight={false}
                showArrows={true}
                showIndicators={false}
                interval={8000}
                infiniteLoop={true}
              >
                <div className="d-flex">
                  <div className="scenarios_card scenarios_card_1">
                    <div className="scenarios_card_img">
                      <img src={ScenariosCardImg1} alt="ScenariosCardImg1" />
                    </div>
                    <h2 className="scenarios_card_title">- Sarah W</h2>
                    <p className="scenarios_card_description">
                      “When my Mother passed away suddenly, I had no idea where
                      to start looking for her insurance policy or Will. I was
                      pretty sure she had those things in place but where she
                      kept them was a mystery to me and my siblings. We were all
                      devastated and stressed as we mourned her loss and had the
                      arduous task of trying to locate her papers. It took 10
                      hard and stress-filled months before we found everything.”
                    </p>
                    <p className="solution_link mb-2">
                      <a href="">Keys to My Wishes Solution</a>
                    </p>
                    <p className="scenarios_card_description">
                      Our service is designed to store either the location of
                      these types of documents or to upload them into our
                      “information storage vault”. Grieving families can then
                      have a centralized place to go and easily locate this
                      important information. While it doesn’t lessen the pain of
                      losing a loved one, it does take away much of the stress
                      and frustration associated with trying to find those vital
                      documents.
                    </p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="scenarios_card scenarios_card_2">
                    <div className="scenarios_card_img">
                      <img src={ScenariosCardImg2} alt="ScenariosCardImg2" />
                    </div>
                    <h2 className="scenarios_card_title">- Margaret H</h2>
                    <p className="scenarios_card_description">
                      “When my Mother passed away suddenly, I had no idea where
                      to start looking for her insurance policy or Will. I was
                      pretty sure she had those things in place but where she
                      kept them was a mystery to me and my siblings. We were all
                      devastated and stressed as we mourned her loss and had the
                      arduous task of trying to locate her papers. It took 10
                      hard and stress-filled months before we found everything.”
                    </p>
                    <p className="solution_link mb-2">
                      <a href="">Keys to My Wishes Solution</a>
                    </p>
                    <p className="scenarios_card_description">
                      Our service is designed to store either the location of
                      these types of documents or to upload them into our
                      “information storage vault”. Grieving families can then
                      have a centralized place to go and easily locate this
                      important information. While it doesn’t lessen the pain of
                      losing a loved one, it does take away much of the stress
                      and frustration associated with trying to find those vital
                      documents.
                    </p>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="d-flex">
                  <div className="scenarios_card scenarios_card_1">
                    <div className="scenarios_card_img">
                      <img src={ScenariosCardImg1} alt="ScenariosCardImg1" />
                    </div>
                    <h2 className="scenarios_card_title">- Sarah W</h2>
                    <p className="scenarios_card_description">
                      “When my Mother passed away suddenly, I had no idea where
                      to start looking for her insurance policy or Will. I was
                      pretty sure she had those things in place but where she
                      kept them was a mystery to me and my siblings. We were all
                      devastated and stressed as we mourned her loss and had the
                      arduous task of trying to locate her papers. It took 10
                      hard and stress-filled months before we found everything.”
                    </p>
                    <p className="solution_link mb-2">
                      <a href="">Keys to My Wishes Solution</a>
                    </p>
                    <p className="scenarios_card_description">
                      Our service is designed to store either the location of
                      these types of documents or to upload them into our
                      “information storage vault”. Grieving families can then
                      have a centralized place to go and easily locate this
                      important information. While it doesn’t lessen the pain of
                      losing a loved one, it does take away much of the stress
                      and frustration associated with trying to find those vital
                      documents.
                    </p>
                    {/*</div>*/}
                  </div>
                </div>
              </Carousel>
            </div>
            <div className="scenarios_mobile_content">
              <div className="scenarios_card scenarios_card_1">
                <div className="scenarios_card_img">
                  <img src={ScenariosCardImg1} alt="ScenariosCardImg1" />
                </div>
                <h2 className="scenarios_card_title">- Sarah W</h2>
                <p className="scenarios_card_description">
                  “When my Mother passed away suddenly, I had no idea where
                  to start looking for her insurance policy or Will. I was
                  pretty sure she had those things in place but where she
                  kept them was a mystery to me and my siblings. We were all
                  devastated and stressed as we mourned her loss and had the
                  arduous task of trying to locate her papers. It took 10
                  hard and stress-filled months before we found everything.”
                </p>
                <p className="solution_link mb-2">
                  <a href="">Keys to My Wishes Solution</a>
                </p>
                <p className="scenarios_card_description">
                  Our service is designed to store either the location of
                  these types of documents or to upload them into our
                  “information storage vault”. Grieving families can then
                  have a centralized place to go and easily locate this
                  important information. While it doesn’t lessen the pain of
                  losing a loved one, it does take away much of the stress
                  and frustration associated with trying to find those vital
                  documents.
                </p>
                {/*</div>*/}
              </div>
              <div className="scenarios_card scenarios_card_2">
                <div className="scenarios_card_img">
                  <img src={ScenariosCardImg2} alt="ScenariosCardImg2" />
                </div>
                <h2 className="scenarios_card_title">- Margaret H</h2>
                <p className="scenarios_card_description">
                  I am 77 years old and a widow now living alone.
                  Unfortunately my son lives in another State and even
                  though he wants me to move closer, I can’t bring myself to
                  leave the home and memories me and George built for over
                  50 years. Also, living in a colder climate would never
                  work for me. Even though I talk to my son and
                  grandchildren weekly, my biggest fear is falling, not
                  being able to get up and not being discovered for another
                  week. By then it may be too late
                </p>
                <p className="solution_link mb-2">
                  <a href="">Keys to My Wishes Solution</a>
                </p>
                <p className="scenarios_card_description">
                  With our “daily” keep in touch service, you will check-in
                  to our service between your selected time frame to
                  indicate you are not incapacitated. If we have not heard
                  from you, we will send you 2 text message reminders to
                  check-in. If we haven’t heard from you, we place 2
                  separate phone calls to verify you are ok. If you do not
                  respond, we then will call your emergency contact(s) to
                  perform a wellness check on you. With our “daily” keep in
                  touch service, you are never alone. You can have peace of
                  mind knowing we will contact your designated love ones if
                  we do not hear from you.
                </p>
              </div>
              <div className="scenarios_card scenarios_card_1">
                <div className="scenarios_card_img">
                  <img src={ScenariosCardImg1} alt="ScenariosCardImg1" />
                </div>
                <h2 className="scenarios_card_title">- Linda P</h2>
                <p className="scenarios_card_description">
                  “After a brief illness my husband passed away. He took
                  care of all the household finances, paid all the bills,
                  made all the investments, hired all the handymen to do the
                  renovations and repairs in our home. He did everything and
                  I was perfectly happy with that. When he became ill, I was
                  in denial that it was not that serious and knew he would
                  get well. He didn’t. After he passed away it was up to me
                  to keep things going. Where to begin and what to do was a
                  mystery. Where was all the paperwork to everything? Who
                  did I need to contact to get things done? I became
                  depressed and found myself wanting to give up because it
                  seemed overwhelming.”
                </p>
                <p className="solution_link mb-2">
                  <a href="">Keys to My Wishes Solution</a>
                </p>
                <p className="scenarios_card_description">
                  With Keys to My Wishes, Linda’s husband could have all
                  this important information stored electronically in our
                  “information storage vault”. Linda would then have easy
                  access to things such as creditors, handyman contacts,
                  list of investments and all relevant information, document
                  location, passwords, etc. This may have helped ease the
                  feeling of overwhelm and wanting to give up. She could
                  have had a road map to continue managing their financial
                  affairs.
                </p>
                {/*</div>*/}
              </div>
              <div className="scenarios_card scenarios_card_2">
                <div className="scenarios_card_img">
                  <img src={ScenariosCardImg2} alt="ScenariosCardImg2" />
                </div>
                <h2 className="scenarios_card_title">- Barbara A</h2>
                <p className="scenarios_card_description">
                  “Both my God-parents were elderly and ailing. My
                  Godfather, however, was determined he should be the main
                  caregiver for the love of his life, who was bed ridden as
                  a result of a stroke. Fortunately once a week a RN would
                  come to the house to provide additional care for my
                  Godmother. When my Godfather failed to answer the door,
                  the RN contacted the police department to enter the home
                  and perform a welfare check. My Godfather was found on the
                  kitchen floor and it was obvious he had been there for a
                  few days since my Godmother was soiled, dehydrated and had
                  not been fed.”
                </p>
                <p className="solution_link mb-2">
                  <a href="">Keys to My Wishes Solution</a>
                </p>
                <p className="scenarios_card_description">
                  If a family member had registered this elderly man for
                  Keys to My Wishes, “daily” Keep in Touch check-in aspect
                  of our service and if he had failed to check-in or respond
                  to the reminder alerts, we would have called people on
                  their emergency contact listed. A welfare check could have
                  been made within a matter of hours versus days. While
                  there’s no guarantee it would have been in time to save
                  the life of the man, the wife would have received care
                  within hours so she did not have to suffer and be in the
                  bad shape in which she was found.
                </p>
              </div>
              <div className="scenarios_card scenarios_card_1">
                <div className="scenarios_card_img">
                  <img src={ScenariosCardImg1} alt="ScenariosCardImg1" />
                </div>
                <p className="scenarios_card_description">
                  “How often do we hear in the news of natural disasters
                  such as hurricanes, tornados, wild fires, floods,
                  destroying people’s homes? We see that way too often
                  little if anything is saved including important documents
                  and irreplaceable possessions. How are those things
                  replaced?”
                </p>
                <p className="solution_link mb-2">
                  <a href="">Keys to My Wishes Solution</a>
                </p>
                <p className="scenarios_card_description">
                  With our service, your important documents can be uploaded
                  and stored in our electronic “information storage vault”.
                  Each client receives 3 GB of free disk storage for such
                  important documents and additional disk storage can be
                  purchased for a nominal amount. Using your log in
                  credentials, you can gain access to your important
                  documents from any electronic device. If you don’t
                  remember your log in credentials, simply contact us and
                  after verifying your identity, we can help you access your
                  account. The benefit to you is your important documents
                  are centralized in one easy to access location which saves
                  you many sleepless nights and long hours trying to
                  reconstruct these things.
                </p>
                {/*</div>*/}
              </div>
              <div className="scenarios_card scenarios_card_2">
              <div className="scenarios_card_img">
                <img src={ScenariosCardImg2} alt="ScenariosCardImg2" />
              </div>
              <h2 className="scenarios_card_title">- Michael C</h2>
              <p className="scenarios_card_description">
                “Mom passed away over 30 years ago. In 2021 someone told
                me that my name was seen on a “claim property” website. I
                discovered that mom had stock in one of the airplane
                producing companies years ago and neither me nor my
                siblings knew anything about it. Fortunately we were able
                to recover the proceeds many years later.”
              </p>
              <p className="solution_link mb-2">
                <a href="">Keys to My Wishes Solution</a>
              </p>
              <p className="scenarios_card_description">
                Michael and his siblings were lucky they were able to get
                the proceeds from their mom’s investments from many years
                prior. Of course our service was not around then; however,
                if Michael or his siblings make such an investments today
                and stored that information into Keys to My Wishes, their
                children will be able to locate that information much
                sooner and easier. They will be able to reap the financial
                rewards of their parent’s investment today and not have to
                wait to discover such investments 30 years later.
              </p>
            </div>
            </div>
          </div>

          <img
            src={FeaturesLeftBg}
            className="FeaturesLeftBg"
            alt="FeaturesLeftBg"
          />
          <img
            src={FeaturesRightBg}
            className="FeaturesRightBg"
            alt="FeaturesRightBg"
          />
        </div>

        <div className="contactUs_section">
          <div className="title text-center">
            <h2>Contact Us</h2>
          </div>
          <div className="contactUs_content">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="map_content">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d476.6881429760869!2d-118.59982335750263!3d34.178868630341725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c29ea173fd8371%3A0x56b0a4b1470948b7!2s21550%20W%20Oxnard%20St%20Suite%20%23300%2C%20Woodland%20Hills%2C%20CA%2091367%2C%20USA!5e0!3m2!1sen!2sin!4v1644304651070!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 pl-3">
                <div className="contactUs_form">
                  <form action="" onSubmit={this.handleSubmitContact}>
                    <div className="form_content">
                      <div className="d-flex tag">
                        <div className="email_tag">
                          <img src={ClarityEmailLine} alt="ClarityEmailLine" />
                          <p className="email_text m-0">Contact Us</p>
                        </div>
                        <div className="phone_tag">
                          <img src={CarbonPhone} alt="CarbonPhone" />
                          <p className="email_text m-0">
                            Call Us At: 818-642-1540
                          </p>
                        </div>
                      </div>
                      <h2 className="form_label">Leave us a Message</h2>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="emailHelp"
                          placeholder="Full Name"
                          value={fname}
                          onChange={(e) =>
                            this.setState({ fname: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          value={email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="form-group textArea">
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder="Write Your Message"
                          rows="5"
                          value={message}
                          onChange={(e) =>
                            this.setState({ message: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      {isLoading ? <CircularProgress /> : "Submit"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>


      </>
    );
  }
}

export default HelpingSection;

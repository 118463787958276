import React, {useEffect, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import axios from "axios";
import PermissionModal from "./PermissionModal";
import moment from "moment";
import Cookie from "../../utils/cookie";

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                <TableCell
                    style={{fontSize: "16px"}}
                >
                    Contact
                </TableCell>
                <TableCell
                    style={{fontSize: "16px"}}
                >
                    Date of Request
                </TableCell>
                <TableCell
                    style={{fontSize: "16px"}}
                >
                    Request Status
                </TableCell>
                <TableCell
                    style={{fontSize: "16px"}}
                >
                    Date of Response
                </TableCell>
                <TableCell>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

const SharedAccessApprovalModal = ({isOpen, toggleApprovalModal}) => {
    const [selectedData, setSelected] = useState(null)
    const [accessData, setAccessApprovalData] = useState(null);
    const [isOpenPermissions, setOpenPermissions] = useState(false);

    useEffect(() => {
        if (isOpen){
            shareRequestOwnerGet();
        }
    }, [isOpen]);

    const togglePermissionModal = (item = null) => {
        setSelected(item);
        setOpenPermissions(!isOpenPermissions);
    }

    const shareRequestOwnerGet = () => {
        const token = localStorage.getItem("token");
        const nUserID = localStorage.getItem("userid");
        axios.get(`${process.env.REACT_APP_SERVER}inventory/shareRequestOwnerGet?nUserID=${nUserID}`,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then((response) => {
            setAccessApprovalData(response.data)
        })
    }

    const handleCloseModal = () => {
        toggleApprovalModal();
        localStorage.setItem("isApprovedModal","0");
    }

    return (
        <Modal show={isOpen} onHide={handleCloseModal} centered size="xl">
            <Modal.Header closeButton className="ShareRelease-Modal-Header">
                <Modal.Title>Share Access Approval</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TableContainer>
                    <Table stickyHeader>
                        <EnhancedTableHead/>
                        <TableBody>
                            {(accessData || []).map((ele,index) => (
                                <TableRow hover key={index}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="left"
                                    >
                                        {ele.fname} {ele.lname}
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="left"
                                    >
                                        {moment(ele["Date Created"]).format("ll")}
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        align="left"
                                    > {ele["Status"] ? ele["Status"] : ""}
                                    </TableCell>
                                    <TableCell align="left">
                                        {ele["ResponseDate"] ? moment(ele["ResponseDate"]).format("ll") : ""}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button
                                            onClick={() => {
                                                togglePermissionModal(ele);
                                                Cookie.save("isPendingApproval","0");
                                            }}
                                            color="success"
                                            className="btn-success px-4"
                                        >Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <Button color="success" className="btn-secondary" onClick={handleCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
            <PermissionModal
                selectedData={selectedData}
                isOpenPermissions={isOpenPermissions}
                togglePermissionModal={togglePermissionModal}
                shareRequestOwnerGet={shareRequestOwnerGet}
            />
        </Modal>
    );
};

export default SharedAccessApprovalModal;
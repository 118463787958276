import React, { useEffect, useState } from "react";
import "@pathofdev/react-tag-input/build/index.css";
import {Button, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner'
import { Link, useHistory } from "react-router-dom";
import AddItemDescriptionWizard from "./AddItemDescriptionWizard";
import AddItemNotesWizard from "./AddItemNotesWizard";
import AddItemIncapacitatedDeceasedWizard from "./AddItemIncapacitatedDeceasedWizard";
import AddItemSharedWithWizard from "./AddItemSharedWithWizard";
import AddItemReleaseToWizard from "./AddItemReleaseToWizard";
import "./AdditemDiscriptionWizard.css";
import axios from "axios";
import FileAttachment from "./FileAttachment";
import Star_icon from "../../../assets/images/start_icon.png";
import Close from "../../../assets/images/close.png";
import genie_img from "../../../assets/images/genie_image.jpg";
import ReactModal from 'react-modal-resizable-draggable';
import {EditorState} from "draft-js";
import OptionalSection from "../OptionalSection";

const AddNewItemContainer = ({
  showUseWizard = false,
  getAllItems,
  existnIncapacitated,
  existnDeceased,
  BackToClear,
}) => {
  const [stepModalShow, setStepModalShow] = React.useState(false);
  const [suggestionsData, setSuggestionsData] = useState([]);
  const [stepNumber, setStepNumber] = React.useState(1);
  const [itemName, setItemName] = useState("");
  const [itemNotes, setItemNotes] = useState("");
  const [nIncapacitated, setnIncapacitated] = useState(0);
  const [nDeceased, setnDeceased] = useState(0);
  const [contactsList, setContactsList] = useState([]);
  const [isSharedReleaseGridShow, setIsSharedReleaseGridShow] = useState(1);
  const [itemID, setItemID] = React.useState("");
  const [contactsData, setContactsData] = React.useState([]);

  const [Immediatly1, setImmediatly1] = useState(0);
  const [Immediatly2, setImmediatly2] = useState(0);
  const [option1, setOption1] = React.useState(Immediatly1);
  const [option2, setOption2] = React.useState(Immediatly2);
  const [nIncapDays, setNIncapDays] = useState(0);
  const [nDeceasedDays, setNDeceasedDays] = useState(0);
  const [loading,setLoading] = useState(false);
  const [optionalData, setOptionalData] = useState({
    Xbusinessname: null,
    Xaccountnumber: null,
    Xaddress: null,
    Xcity: null,
    Xzip: null,
    Xurl: null,
    Xusername: null,
    Xpword: null,
    Xcontactinfo: null,
    Xstateid:null,
  })

  useEffect(() => {
    setOption1(Immediatly1);
  }, [Immediatly1]);

  useEffect(() => {
    setOption2(Immediatly2);
  }, [Immediatly2]);

  const handleChange1 = (event) => {
    setOption1(event.target.value);
    setImmediatly1(event.target.value);
  };

  const handleChange2 = (event) => {
    setOption2(event.target.value);
    setImmediatly2(event.target.value);
  };

  const handleDaysIncapacitateChange = (event) => {
    const { value } = event.target;
    setNIncapDays(value > 1 && value <= 30 ? value : "");
  };

  const handleDaysPassedAwayChange = (event) => {
    const { value } = event.target;
    setNDeceasedDays(value > 1 && value <= 30 ? value : "");
  };

  const handleNIncapacitatedChange = (event) => {
    setnIncapacitated(event.target.checked ? 1 : 0);
  };

  const handleNDeceasedChange = (event) => {
    setnDeceased(event.target.checked ? 1 : 0);
  };

  useEffect(() => {
    setContactsList(
      (contactsData || []).map((r) => {
        return {
          ...r,
          editsharedwith: r.sharedwith,
          editreleaseto: r.releaseto,
        };
      })
    );
  }, [contactsData]);

  useEffect(() => {
    setnDeceased(existnDeceased);
  }, [existnIncapacitated, existnDeceased]);

  function getAllSuggestions() {
    var token = localStorage.getItem("token");
    axios
      .get(process.env.REACT_APP_SERVER + `inventory/getsuggestions`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setSuggestionsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getAllContacts() {
    const data = {
      nUserID: localStorage.getItem("userid"),
    };
    var token = localStorage.getItem("token");
    axios
      .post(process.env.REACT_APP_SERVER + "contact/listall", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const data =
          res && res.data && res.data[0].length
            ? res.data[0].map((r) => {
                return {
                  ...r,
                  sharedwith: 0,
                  releaseto: 0,
                };
              })
            : [];
        setContactsData(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function backStepModal() {
    setStepNumber(stepNumber - 1);
  }

  function closeStepModal() {
    setItemName("");
    setItemNotes("");
    setIsSharedReleaseGridShow(1);
    setContactsList(
      contactsData?.map((r) => {
        return {
          ...r,
          editsharedwith: r?.sharedwith,
          editreleaseto: r?.releaseto,
        };
      })
    );
    setnIncapacitated(existnIncapacitated);
    setnDeceased(existnDeceased);
    setStepNumber(1);
    setItemID("");
    setStepModalShow(false);
      getAllItems();
  }

  const handleIsSharedReleaseGridChange = (event) => {
    setIsSharedReleaseGridShow(event.target.value);
    if (Number(event.target.value) === 0) {
      setContactsList(
        contactsData.map((r) => {
          return {
            ...r,
            editsharedwith: r.sharedwith,
            editreleaseto: r.releaseto,
          };
        })
      );
    }
  };

  const submitNewItem = (e) => {
    e.preventDefault();
    if (stepNumber === 1 || stepNumber === 2) {
      saveNewItemDetails();
      setStepNumber(stepNumber + 1);
    } else if (stepNumber === 3) {
      saveNewItemDetails();
      setStepNumber(stepNumber + 1);
    } else if (stepNumber === 4) {
      if (Number(isSharedReleaseGridShow) === 1) {
        if (
          contactsList.some(
            (sharedRelease) =>
              Number(sharedRelease.editsharedwith) !==
              Number(sharedRelease.sharedwith)
          )
        ) {
          const allContactIds = contactsList
            .filter(
              (fContact) =>
                Number(fContact.editsharedwith) !== Number(fContact.sharedwith)
            )
            .map((c) => c.contactid)
            .join(",")
            .toString();
          assignExistingSharedToReleasedTo(itemID, allContactIds, "SharedWith");
        }
      }
      setStepNumber(stepNumber + 1);
    } else if (stepNumber === 5) {
      if (
        contactsList.some(
          (sharedRelease) =>
            Number(sharedRelease.editreleaseto) !==
            Number(sharedRelease.releaseto)
        )
      ) {
        const allContactIds = contactsList
          .filter(
            (fContact) =>
              Number(fContact.editreleaseto) !== Number(fContact.releaseto)
          )
          .map((c) => c.contactid)
          .join(",")
          .toString();
        assignExistingSharedToReleasedTo(itemID, allContactIds, "ReleaseTo");
      }
      setStepNumber(stepNumber + 1);
    } else if (stepNumber === 6) {
      setLoading(true)
      if (files && files.length) {
        return files.map((f) => {
          var token = localStorage.getItem("token");
          var saveFiledata = new FormData();
          saveFiledata.append("nUserID", localStorage.getItem("userid"));
          saveFiledata.append("nInvItemID", itemID);
          saveFiledata.append("imgfile", f);

          return axios
            .post(process.env.REACT_APP_SERVER + "uploads3file", saveFiledata, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((res) => {
              var data = new FormData();
              data.append("nUserID", localStorage.getItem("userid"));
              data.append("nInvItemID", Number(itemID));
              data.append("sFileName", f.name);
              data.append("s3FileName", res.data.filename);
              data.append("nFileSize", f.size);

              axios.post(process.env.REACT_APP_SERVER + "inventory/addmedia", data,{headers: { Authorization: `Bearer ${token}` }})
                .then((res) => {
                  setStepModalShow(false);
                  setLoading(false)
                  addNewItems();
                }).catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            })
            .catch((err) => {
              console.log(err);
              setLoading(false)
            });
        });
      } else {
        setStepModalShow(false);
        setLoading(false);
        addNewItems();
      }
    }
  };

  function saveNewItemDetails() {
    var token = localStorage.getItem("token");
    const fieldValues = {...optionalData,Xstateid:parseInt(optionalData.Xstateid)}

    const data = {
      nUserID: localStorage.getItem("userid"),
      sItemName: itemName,
      sItemNotes: itemNotes,
      nIsShared: 0,
      nIsTimeRelease: 0,
      nIncapDays: Immediatly1 === "after" ? nIncapDays : Immediatly1,
      nDeceasedDays: Immediatly2 === "after" ? nDeceasedDays : Immediatly2,
      nIsDeceased: nDeceased,
      nIsIncapacitated: nIncapacitated,
      ...(itemID ? { nID: itemID } : {}),
      ...fieldValues,
    };

    axios
      .post(process.env.REACT_APP_SERVER + "inventory/additem", data, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setItemID(res.data[0].ID);
        history.push(`/newDashboard/${res.data[0].ID}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function saveReleaseMyInformation() {
    var token = localStorage.getItem("token");

    axios
      .post(
        process.env.REACT_APP_SERVER + "user/incapacitateddeceasedsettings",
        {
          nUserID: localStorage.getItem("userid"),
          nIncapacitated: Immediatly1 === "after" ? nIncapDays : Immediatly1,
          nDeceased: Immediatly2 === "after" ? nDeceasedDays : Immediatly2,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  }

  function assignExistingSharedToReleasedTo(
    nInvItemID,
    sContacts,
    sSharedOrReleaseTo
  ) {
    const data = {
      nUserID: localStorage.getItem("userid"),
      nInvItemID: nInvItemID,
      sContacts: sContacts,
      sSharedOrReleaseTo: sSharedOrReleaseTo,
    };

    var token = localStorage.getItem("token");
    axios
      .post(
        process.env.REACT_APP_SERVER + "inventory/addsharedwithreleaseto",
        data,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        getAllItems();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [files, setFiles] = useState([]);
  const addNewItems = () => {
    setItemName("");
    setItemNotes("");
    setFiles([]);
    setIsSharedReleaseGridShow(1);
    setContactsList(
      contactsData.map((r) => {
        return {
          ...r,
          editsharedwith: r.sharedwith,
          editreleaseto: r.releaseto,
        };
      })
    );
    setnIncapacitated(existnIncapacitated);
    setnDeceased(existnDeceased);
    setStepNumber(1);
    setStepModalShow(false);
    setItemID("");
    getAllItems();
  };


  function isBackButtonDisabled() {
    if (stepNumber === 1) {
      return itemName.length === 0;
    } else if (stepNumber === 2) {
      return false;
    } else if (stepNumber === 3) {
      return false;
    } else if (stepNumber === 4) {
      return false;
    } else if (stepNumber === 5) {
      return false;
    } else if (stepNumber === 6){
      return loading;
    }
  }

  function isSaveButtonDisabled() {
    if (stepNumber === 1) {
      return itemName.length === 0;
    } else if (stepNumber === 2) {
      return false;
    } else if (stepNumber === 3) {
      return false;
    } else if (stepNumber === 4) {
      return false;
    } else if (stepNumber === 5) {
      return false;
    } else if (stepNumber === 6){
      return loading;
    }
  }

  const history = useHistory();

  const CreateNewWish = () => {
    history.push("/newAddNewItem");
    BackToClear();
  };

  const [planNotSelected, setSelectPlan] = useState(false);

  const handleClosePlan = () => {
    setSelectPlan(false);
  };

  const onChangeFiles = (e) => {
    if (e.target.files && e.target.files.length) {
      setFiles((prev)=>([...prev,...e.target.files]));
    }
  };

  const [getstartData, setgetstartData] = React.useState("");
  const [showLegend, setShowLegend] = useState(false);

  const handleLegendDailog = () => {
    handleletstart()
    setShowLegend(true);
  };

  const handleCloseLegend = () => {
    setShowLegend(false);
  };
  const handleletstart = () => {
    axios
        .get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=37`)
        .then((res) => {
          setgetstartData(res.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const handleChangeField = (e,name) => {
    if (name === 'richText'){
      setOptionalData((oldProps)=>({
        ...oldProps,
        Xcontactinfo:e.target.value
      }))
    } else {
      setOptionalData((oldProps)=>({
        ...oldProps,
        [e.target.name]:e.target.value
      }))
    }
  }

  return (
    <>
      <ReactModal
          initWidth={400}
          initHeight={700}
          top={-143}
          left={0}
          minHeight={700}
          minWidth={400}
          onFocus={() => {}}
          className={"my-modal-custom-class"}
          onRequestClose={handleCloseLegend}
          isOpen={showLegend}>
        <h4 className="header_Contact">
          <div className="pr-2 start_icon">
            <OverlayTrigger
                overlay={
                  <Tooltip id={`tooltip-top`} style={{zIndex:'999999999999'}}>
                    You can move this screen anywhere by clicking in the title bar
                  </Tooltip>
                }
            >
              <img src={Star_icon} height={25} width={25}/>
            </OverlayTrigger>
          </div>
          <div dangerouslySetInnerHTML={{ __html: getstartData.theheader }}/>
          <div className="pr-2 close_icon" onClick={handleCloseLegend}>
            <img src={Close} height={20} width={20}/>
          </div>
        </h4>
        <div className="body">
          <div
              className={"ml-3 pt-5 dataMain"}
              dangerouslySetInnerHTML={{ __html: getstartData.thetext }}
          />
        </div>
      </ReactModal>
      <Modal show={planNotSelected} onHide={handleClosePlan}>
        <Modal.Header closeButton className={"modelHeader"}>
          <Modal.Title>Please Select Your Plan!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please Select Your Plan!</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ backgroundColor: "#7030a0" }}
            onClick={handleClosePlan}
          >
            Okay
          </Button>
        </Modal.Footer>
      </Modal>

      {showUseWizard ? (
        <Button
          variant="success"
          className="btn mb-1 mb-sm-0  mr-2 text-nowrap"
          onClick={() => {
            getAllContacts();
            setStepModalShow(true);
            setStepNumber(1);
            BackToClear();
          }}
          style={{ width: "fit-content" }}
        >
          + Add Wish
        </Button>
      ) : (
        <Button
          variant="success"
          className="btn  mr-2 mb-1 mb-sm-0 text-nowrap"
          style={{ width: "fit-content" }}
          onClick={CreateNewWish}>+ Add Wish</Button>
      )}
      {stepModalShow ? (
        <Modal
          className="add_items add_wishes_modal"
          keyboard={false}
          size={stepNumber === 6 ? "xl" : "lg"}
          show={stepModalShow}
          onHide={() => {closeStepModal()}}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="ShareRelease-Modal-Header">
            {stepNumber === 1 && (
              <div className="modal-title h4">What Is Your Wish Item</div>
            )}
            {stepNumber === 2 && (
              <div className="modal-title h4">Remarks/Instructions for this Wish</div>
            )}
            {stepNumber === 3 && (
              <div className="modal-title h4">Release of Wishes</div>
            )}
            {stepNumber === 4 && (
              <div className="modal-title h4">
                Who do you Want to SHARE this Wish with?
              </div>
            )}
            {stepNumber === 5 && (
              <div className="modal-title h4">Release This Wish To?</div>
            )}
            {stepNumber === 6 && (
              <div className="modal-title h4">Attach Files to this Wish</div>
            )}
          </Modal.Header>
          <Modal.Body>
            {stepNumber === 1 && (
            <>

              <div  style={{
                display: "flex",
              }}>
                <div  style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "5px"
                }}>
                  <img src={genie_img} className="genie" onClick={handleLegendDailog} style={{marginBottom:'1rem'}}/>
                </div>

                <div
                    style={{
                      border: "1px solid #ced4da",
                      padding: "8px",
                      marginBottom: "20px",
                    }}
                >
                  <b>
                    Enter your Wish into the system. You can be as detailed as
                    you'de like or not by informing your loved ones where to find
                    your Wish items.
                    <br />
                  </b>
                  You can either click the Suggestions button to see Wish items you can use, or you can enter your own Wish item.
                </div>
              </div>

            </>
            )}
            {stepNumber === 2 && (
              <>
                <div style={{
                  border: "1px solid #ced4da",
                  padding: "8px",
                  marginBottom: "20px",
                }}>
                  <b>
                    Do you have a Story that goes with this Wish or do you want to leave Instructions for this Wish?
                  </b>
                </div>
                <div style={{
                  border: "1px solid #ced4da",
                  padding: "8px",
                  marginBottom: "20px",
                }}>
                  <b className={'text-danger'}>
                    <span>Here is Information you may want to consider leaving as instructions for this Wish:</span><br/>
                    <span>Location of item, Account #, Serial#, Purchase Date, Purchased from, Special Instructions</span>
                  </b>
                </div>
              </>
            )}
            {stepNumber === 1 ? (
              <AddItemDescriptionWizard
                itemName={itemName}
                setItemName={setItemName}
                setItemNotes={setItemNotes}
                suggestionsData={suggestionsData}
                getAllSuggestions={getAllSuggestions}
                stepNumber={stepNumber}
                optionalData={optionalData}
                setOptionalData={setOptionalData}
                handleChangeField={handleChangeField}
                isItemNameInput={true}
              />
            ) : stepNumber === 2 ? (
              <AddItemNotesWizard
                itemNotes={itemNotes}
                itemName={itemName}
                setItemNotes={setItemNotes}
                stepNumber={stepNumber}
                setItemName={setItemName}
                isItemNotesInput={true}
              />
            ) : stepNumber === 3 ? (
              <AddItemIncapacitatedDeceasedWizard
                nIncapacitated={nIncapacitated}
                setnIncapacitated={setnIncapacitated}
                nDeceased={nDeceased}
                setnDeceased={setnDeceased}
                handleNIncapacitatedChange={handleNIncapacitatedChange}
                handleNDeceasedChange={handleNDeceasedChange}
                handleChange1={handleChange1}
                handleChange2={handleChange2}
                handleDaysIncapacitateChange={handleDaysIncapacitateChange}
                handleDaysPassedAwayChange={handleDaysPassedAwayChange}
                Immediatly1={Immediatly1}
                Immediatly2={Immediatly2}
                option1={option1}
                option2={option2}
                nIncapDays={nIncapDays}
                nDeceasedDays={nDeceasedDays}
                setImmediatly1={setImmediatly1}
                setImmediatly2={setImmediatly2}
                setOption1={setOption1}
                setOption2={setOption2}
                setNIncapDays={setNIncapDays}
                setNDeceasedDays={setNDeceasedDays}
                stepNumber={stepNumber}
              />
            ) : stepNumber === 4 ? (
              <AddItemSharedWithWizard
                isSharedReleaseGridShow={isSharedReleaseGridShow}
                handleIsSharedReleaseGridChange={handleIsSharedReleaseGridChange}
                contactsList={contactsList}
                setContactsList={setContactsList}
                stepNumber={stepNumber}
              />
            ) : stepNumber === 5 ? (
              <AddItemReleaseToWizard
                isSharedReleaseGridShow={isSharedReleaseGridShow}
                contactsList={contactsList}
                setContactsList={setContactsList}
                stepNumber={stepNumber}
              />
            ) : stepNumber === 6 ? (
              <FileAttachment
                files={files}
                onChangeFiles={onChangeFiles}
                setFiles={setFiles}
                stepNumber={stepNumber}
              />
            ) : null}
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            {stepNumber === 1 ? (
              <Button
                color=""
                className="cancel_btn"
                onClick={() => setStepModalShow(false)}
              >
                {"Cancel"}
              </Button>
            ) : (
              <Button
                color=""
                className="cancel_btn"
                onClick={() => backStepModal()}
                disabled={isBackButtonDisabled()}
              >
                {"Back"}
              </Button>
            )}

            <Button
              variant="primary"
              className="custom-btn"
              onClick={(e) => submitNewItem(e)}
              disabled={isSaveButtonDisabled()}
            >
              {
                stepNumber === 1 ? "Submit" :
                stepNumber <= 5 ? 'Next' :
                stepNumber === 6 ? "Done" : "Submit"
              }
              {loading && <Spinner animation="border" size="sm" style={{marginLeft:"5px"}}/>}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

export default AddNewItemContainer;

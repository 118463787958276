import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/images/LandingPage/logo.png";
import pattern from "../../assets/images/LandingPage/patterns/pattern.jpg";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Button, TextField } from "@material-ui/core";
import OtpInput from "react-otp-input";
import { Alert, Form, Modal } from "react-bootstrap";
import axios from "axios";
import "./NewPassword.css";

const NewPassword = () => {
  let history = useHistory();
  //const [mobileno, setPhone] = useState();
  const [email, setEmail] = useState();
  const [showModel, setshowModel] = useState(false);
  const [handleShow, SetHandleshow] = useState(false);
  const [handleAlert, SetHandleAlert] = useState(false);
  const [handleShowErr, SetHandleshowErr] = useState("");
  const [otp, setOptp] = React.useState();
  const [response, setResponse] = React.useState("");

  const handleChange = (otp) => {
    setOptp(otp);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSent = () => {
    localStorage.setItem("ForgetEmail", email);
    axios
      .post(process.env.REACT_APP_SERVER + `otp/usersendemailotp`, {
        emailaddress: `${email}`,
      })
      .then((res) => {
        if (res) {
          SetHandleAlert(true);
          SetHandleshow(true);
          SetHandleshowErr("OTP sent Successfully to your mail!");
        }
      })
      .catch(() => {
        SetHandleAlert(true);
        SetHandleshowErr("Enter a valid Email!");
      });
  };
  const HandleValidate = (e) => {
    e.preventDefault();
    otp?.toString().length !== 6 && setshowModel(true);

    axios
      .post(process.env.REACT_APP_SERVER + `otp/userverifyemailotp`, {
        'emailaddress': email,
        'otpcode' : otp,
      })
      .then((res) => {
        console.log("res", res);
        if (res.data.retValue === 1) {
          history.push(`/update-new-password`);
        } else {
          SetHandleAlert(true);
          SetHandleshowErr("OTP Verification Failed!");
        }
      })
      .catch(() => {
        SetHandleAlert(true);
        SetHandleshowErr("OTP Verification Failed!");
      });
  };

  const handleCloseModel = () => {
    setshowModel(false);
  };
  return (
    <>
      <Modal show={showModel} onHide={handleCloseModel}>
        <Modal.Header closeButton className={"modelHeader"}>
          <Modal.Title>OTP Must be 6 Digit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>*OTP Must be 6 Digit</span>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btngenie" onClick={handleCloseModel}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={"newPassword"}>
        <div className={`navigation`}>
          <div className="navigation-wrapper navigation_Header">
            <div className="container">
              <div className="navigation-inner login-header">
                <div className="navigation-logo">
                  <Link to="/">
                    <img src={logo} alt="orions-logo" height="60px" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-form-section forget_form">
          <div className="contact-form-section-wrapper">
            <div className="container">
              <div className="row gx-5 contact-form-section-row justify-content-center">
                <div className="col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-sm-8 col-12 offset-1">
                  <div className="contact-form drop-shadow-2">
                    <div className="contact-form-wrapper forgot-pwd-padding">
                      <div className="section-heading section-heading-2 center">
                        <div className="main-heading c-blue forgot_header">
                          <h1 className={""}>
                            Forgot Password
                          </h1>
                          <Link to="/change-password" className="back_btn">
                            <KeyboardBackspaceIcon className="back_icon" />
                          </Link>
                        </div>
                      </div>
                      <div className={"mb-3"}>
                        {handleAlert && (
                          <Alert
                            key="wrong-login"
                            variant={
                              handleShowErr === "OTP sent Successfully to your mail!"
                                ? "success"
                                : "danger"
                            }
                          >
                            {handleShowErr}
                          </Alert>
                        )}
                      </div>
                      <Form onSubmit={HandleValidate}>
                        <div
                          className={
                            "d-flex justify-content-between align-items-center forgot_password"
                          }
                        >
                          <div
                            className="form-floating2"
                            style={{width: '320px'}}
                          >
                            <TextField
                              id="userEmail"
                              label="Email"
                              type={"email"}
                              fullWidth
                              value={email}
                              onChange={handleEmailChange}
                              required
                            />
                          </div>

                          {!handleShow && (
                              <div className={"mt-2 cord_btn"} >
                                <Button
                                    color={"primary"}
                                    variant={"contained"}
                                    onClick={handleSent}
                                >
                                  Send Code
                                </Button>
                              </div>
                          )}

                        </div>
                        {handleShow && (
                          <div>
                            <div className=" mt-3 otpInput">
                              <p className={"mb-0"}>Enter OTP</p>
                              <OtpInput
                                className={"MuiInputBase-input MuiInput-input"}
                                value={otp}
                                onChange={handleChange}
                                numInputs={6}
                                separator={<span>&nbsp;</span>}
                              />
                            </div>

                            <div className={"btnDiv"}>
                              <button type="submit" className={`button button-3`}>
                            <span className="button-inner">
                              <span className="button-content">
                                <span className="text">SUBMIT</span>
                              </span>
                            </span>
                              </button>
                            </div>
                          </div>
                        )}
                      </Form>
                    </div>
                    <div className="background-pattern background-pattern-radius">
                      <div
                        className="background-pattern-img background-loop"
                        style={{ backgroundImage: `url(${pattern})` }}
                        data-width="1850"
                        data-height="1960"
                      />
                      <div className="background-pattern-gradient" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassword;

import React, {useState} from 'react';
import {Tooltip} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import '@pathofdev/react-tag-input/build/index.css';
import ShareReleaseAlbumModel from './ShareReleaseAlbumModel';
import Add from '../../../assets/images/Add.png';
import ic_users from '../../../assets/images/Release.png';
import axios from 'axios';
import {useHistory} from "react-router-dom";

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
    },
}));

const ReleaseAlbumContainer = ({itemDetails,getAllItems}) => {
    let history = useHistory();
    const [releasedModalShow, setReleasedModalShow] = React.useState(false);
    const [existingReleasedWith, setExistingReleasedWith] = useState([]);
    const classes = useStylesBootstrap();

    function handleReleasedModalShow() {
        getExistingReleasedWithList(true);
    }

    const saveIsShareToIsReleaseChange = (event) => {
        saveIsShareIsReleaseto(itemDetails.WishID);
    };

    function getExistingReleasedWithList(isModalOpen) {
        var token = localStorage.getItem('token');
        var sWhichOne = 'RELEASETO'
        axios
            .get(process.env.REACT_APP_SERVER +
                `contact/getisshareisreleaseto?nUserID=${localStorage.getItem(
                    'userid'
                )}&WishID=${itemDetails.WishID}&sWhichOne=${sWhichOne}`,
                {headers: {Authorization: `Bearer ${token}`}}
            )
            .then((res) => {
                const data = (res.data || []).length ? res.data : [];
                setExistingReleasedWith(
                    data.map((r) => {
                        return {
                            ...r,
                            editreleaseto: r.releaseto,
                        };
                    })
                );
                setReleasedModalShow(isModalOpen);
            })
            .catch((err) => {
                console.log(err);
            });
    }



    function saveIsShareIsReleaseto() {
        const allSelectedReleasedWith = existingReleasedWith.filter(
            (f) => f.editreleaseto === 1
        );
        let data;
        if (allSelectedReleasedWith.length) {
            data = {
                WishID: history.location.search.replace("?",""),
                sContacts: allSelectedReleasedWith
                    .map((s) => s.contactid)
                    .join(",")
                    .toString(),
                type: "ReleaseTo",
            };

        } else {
            data = {
                WishID: history.location.search.replace("?",""),
                sContacts: "",
                type: "ReleaseTo",
            };
        }

        assignExistingSharedToReleasedTo(data.WishID, data.sContacts, data.type);
        getExistingReleasedWithList(false);
    }

    function assignExistingSharedToReleasedTo(
        WishID,
        sContacts,
        sSharedOrReleaseTo
    ) {
        const data = {
            nUserID: localStorage.getItem("userid"),
            WishID: itemDetails.WishID,
            sContacts: sContacts,
            sSharedOrReleaseTo: sSharedOrReleaseTo,
        };

        var token = localStorage.getItem("token");
        return axios
            .post(
                process.env.REACT_APP_SERVER + "inventory/addsharedwithreleaseto",
                data,
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((res) => {
                getAllItems()
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleIsReleaseToChange = (id, checked) => {
        const newContactsData = existingReleasedWith.map((contact) => {
            return {
                ...contact,
                ...(contact.contactid === Number(id)
                    ? {editreleaseto: checked ? 1 : 0}
                    : {}),
            };
        });
        setExistingReleasedWith(newContactsData);
    };

    function isSaveButtonDisabled() {
        return !existingReleasedWith.some(
            (sharedRelease) => sharedRelease.editreleaseto !== sharedRelease.releaseto
        );
    }

    const closeShareReleaseModalShow = () => {
        const newContactsData = existingReleasedWith.map((contact) => {
            return {
                ...contact,
                editreleaseto: contact.releaseto,
            };
        });
        setExistingReleasedWith(newContactsData);
        setReleasedModalShow(false);
    };

    return (
        <>

            {!!itemDetails.hasReleaseTo ? (
                <div className="d-flex justify-content-center">
                    <Tooltip
                        arrow
                        classes={classes}
                        placement="top"
                        title={`Wish will be Released To:${itemDetails.releaseto}`}
                    >
                        <img
                            src={ic_users}
                            alt="shared-with-icon"
                            className="w-5"
                            style={{width: '35px', height: '35px',cursor:'pointer'}}
                            onClick={() => handleReleasedModalShow()}
                        />
                    </Tooltip>
                </div>
            ) : (
                <div className="d-flex justify-content-center">
                    <Tooltip
                        arrow
                        classes={classes}
                        placement="top"
                        title={itemDetails.releaseto}
                    >
                        <img
                            src={Add}
                            alt="Add"
                            className="w-5"
                            style={{width: '25px',paddingTop:'10px',cursor:'pointer'}}
                            onClick={() => handleReleasedModalShow()}
                        />
                    </Tooltip>
                </div>
            )}
            {releasedModalShow ? (
                <ShareReleaseAlbumModel
                    ShareReleaseModalShow={releasedModalShow}
                    closeShareReleaseModalShow={closeShareReleaseModalShow}
                    rowid={itemDetails.WishID}
                    existingSharedReleaseWithTags={existingReleasedWith}
                    shareReleaseContacts={[]}
                    handleIsReleaseToChange={handleIsReleaseToChange}
                    saveIsShareToIsReleaseChange={saveIsShareToIsReleaseChange}
                    isShowShareColumn={false}
                    isShowReleaseColumn={true}
                    isSaveButtonDisabled={isSaveButtonDisabled}
                />
            ) : null}
        </>
    );
};

export default ReleaseAlbumContainer;

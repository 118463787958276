import React from 'react';
import { Checkbox } from '@material-ui/core';
import '@pathofdev/react-tag-input/build/index.css';

const Section1 = ({
	isLabelExist,
	nIncapacitated,
	handleNIncapacitatedChange,
	nDeceased,
	handleNDeceasedChange,
}) => {
	return (
		<div className="box d-flex align-items-center">
			{isLabelExist ? <div className="mr-2">*	Section 1</div> : null}
			<div className="mr-2 font-weight-bold">
				\This Wish Item is used in case I become:{' '}
			</div>
			<div className="d-flex align-items-center justify-content-between font-weight-bold section1-custom-checkbox">
				<span>
					<Checkbox
						checked={!!nIncapacitated}
						onChange={handleNIncapacitatedChange}
						color="primary"
						inputProps={{
							'aria-label': 'secondary checkbox',
						}}
						disabled={isLabelExist}
					/>{' '}
					Incapacitated
				</span>
				<span className="mx-2">And</span>
				<span>
					<Checkbox
						checked={!!nDeceased}
						onChange={handleNDeceasedChange}
						color="primary"
						inputProps={{
							'aria-label': 'secondary checkbox',
						}}
						disabled={isLabelExist}
					/>{' '}
					Deceased
				</span>
			</div>
		</div>
	);
};

export default Section1;

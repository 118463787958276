import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from "axios";
import HandleConfirmation from "../handleConfirmation";
import PhoneNumberField from "../../components/PhoneNumber";
import {isValidPhoneNumber, phoneNumber} from "../../utils/phone";


const useStyles = makeStyles((theme) => ({
    formControl: {
        padding:0,
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const token = localStorage.getItem("token");

const SentInvitaionModel = (props) => {
    const {isOpen,handleCloseNewmodel} = props;
    const classes = useStyles();
    const [contactid, setContatId] = React.useState(0);
    const [value, setValue] = useState("");
    const [sEmail, setsEmail] = useState();
    const [fName, setfName] = useState('');
    const [lName, setlName] = useState('');
    const [contactdata, setContactdata] = useState([]);
    const [confirmation, setConfirmation] = useState(false);
    const [isSendingInvitation, setIsSendingInvitation] = useState(false);
    const [phoneError, setPhoneError] = React.useState(false);


    const handleConfiramtion = () =>{
        if(isValidPhoneNumber(value)) {
            setConfirmation(!confirmation);
            setPhoneError(false);
        } else {
            setPhoneError(true);
        }
    }


    useEffect(() => {
        GetContactData();
    },[])

    function GetContactData() {
        const data = { nUserID: localStorage.getItem("userid") };
        var token = localStorage.getItem("token");
        axios.post(process.env.REACT_APP_SERVER + "contact/listall", data, {
                headers: { Authorization: `Bearer ${token}` },
            }).then((res) => {
                setContactdata(res.data[0]);
            }).catch((err) => {
                console.log(err);
            });
    }

    const handleContactId = (event) => {
        const filteredData = event.target.value ? contactdata.find((item)=>item.contactid === event.target.value) : null;
        if (filteredData) {
            const phone = filteredData.phonenumber ? filteredData.phonenumber.replace('+11', '+1') : "";
            setContatId(filteredData.contactid);
            setValue(phone);
            setsEmail(filteredData.email);
            setfName(filteredData.fname);
            setlName(filteredData.lname);
        }
    };

    const handleSentInvitation = () =>{
        const filteredData = contactid ? contactdata.find((item)=>item.contactid === contactid) : null;
        inviteapi(filteredData);
    }

    const getReadMe = () => {
        const userName = localStorage.getItem("name");
        const contactName = `${fName} ${lName}`;
        return axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=40`)
            .then((res) => {
                return res.data[0].thetext.replace("&lt;username&gt;",userName).replace("&lt;contact name&gt;",contactName);
            }).catch((err) => {
                console.log(err)
            })
    }


    const inviteapi = (filteredData) =>{
        setIsSendingInvitation(true);
        const email = contactid ? filteredData.email : sEmail;
        const sphonenumber = contactid ? filteredData.phonenumber : value;
        let rec_name = fName + ' ' + lName;
        if(contactid) {
            rec_name = filteredData.fname + ' ' + filteredData.lname;
        }
        getReadMe().then((response)=>{
            const data = {
                emailaddress:email,
                subject: rec_name + ' Invitation for Important Information',
                body: response,
                nUserID: localStorage.getItem("userid"),
                url: 'https://keystomywishes.com',
                rec_name: rec_name,
                username: localStorage.getItem('name'),
                sphonenumber: phoneNumber(sphonenumber)
            }
            axios.post(process.env.REACT_APP_SERVER + "contact/sendnewuserinvitation", data, {
                headers: { Authorization: `Bearer ${token}` },
            }).then((res) => {
                setContatId('');
                setValue('');
                setsEmail('');
                setfName('');
                setlName('');
                setConfirmation(false);
                handleCloseNewmodel();
                handleConfiramtion();
                setIsSendingInvitation(false);
            })
            .catch((err) => {
                setIsSendingInvitation(false);
            });
        })
    }

    return (
        <Modal show={isOpen} onHide={handleCloseNewmodel} centered size="lg">
            <Modal.Header closeButton className="ShareRelease-Modal-Header justify-content-center">
                <Modal.Title>Send Invitation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Col md={12}>
                    {phoneError && (
                        <Alert key="wrong-login" variant="danger">
                            The phone number does not appear to be valid!
                        </Alert>
                    )}
                    <form
                        onSubmit={(e)=>{
                            e.preventDefault();
                            handleConfiramtion();
                        }}
                        className="ml-4 New-Contact-form-data"
                    >
                        <div className="clearfix"/>
                        <Form.Group
                            controlId="exampleForm.ControlInput2"
                            className="float-none clearfix"
                        >
                            <Row>
                                <Col md={3} className="d-flex  align-items-center">
                                    <label className="pt-2">First Name:</label>
                                </Col>
                                <Col md={9}>
                                    <Form.Control
                                        type="text"
                                        maxLength="50"
                                        onChange={(e) => setfName(e.target.value)}
                                        value={fName}
                                        required={contactid === ''}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput2">
                            <Row>
                                <Col md={3} className="d-flex  align-items-center">
                                    <label className="pt-2">Last Name:</label>
                                </Col>
                                <Col md={9}>
                                    <Form.Control
                                        type="text"
                                        maxLength="50"
                                        onChange={(e) => setlName(e.target.value)}
                                        value={lName}
                                        required={contactid === ''}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput2">
                            <Row>
                                <Col md={3} className="d-flex  align-items-center">
                                    <label className="pt-2">Phone:</label>
                                </Col>
                                <Col md={9}>
                                    <PhoneNumberField
                                        setPhoneNumber={(event)=> setValue(event)}
                                        phoneNumber={value}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlInput2">
                            <Row>
                                <Col md={3} className="d-flex  align-items-center">
                                    <label className="pt-2">Email :</label>
                                </Col>
                                <Col md={9}>
                                    <Form.Control
                                        maxLength="100"
                                        type={contactid === '' ? "email" :'text'}
                                        onChange={(e) => setsEmail(e.target.value)}
                                        value={sEmail}
                                        required={contactid === ''}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group
                            controlId="exampleForm.ControlInput2"
                            className="mb-4">
                            <Col md={9} className={'float-right p-0'}>
                                <p className={'text-center'}>
                                    ---------- OR ----------
                                </p>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-outlined-label">Select Contact</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={contactid}
                                        onChange={handleContactId}
                                        label="Select Contact"
                                    >
                                        <MenuItem value="0">
                                            <em>None</em>
                                        </MenuItem>
                                        {(contactdata && contactdata).map((item,key)=>
                                            <MenuItem key={key} value={item.contactid}>
                                                {item.fname + ' ' + item.lname}
                                            </MenuItem>
                                        )}
                                    </Select>
                                    <Form.Group
                                        controlId="exampleForm.ControlInput2"
                                        className="mb-0"
                                    >
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            style={{maxWidth: "85px", marginTop: "15px"}}
                                            className="btn-lg btn-block custom-btn btn-hover mx-auto"
                                        >
                                            Send
                                        </Button>
                                    </Form.Group>
                                </FormControl>

                            </Col>
                        </Form.Group>
                    </form>
                </Col>
            </Modal.Body>
            <HandleConfirmation
                isLoading={isSendingInvitation}
                isOpen={confirmation}
                handleClose={()=> setConfirmation(false)}
                onConfirm={handleSentInvitation}
                question={'Are you sure you want to send an Invitation?'}
            />
        </Modal>
    );
};

export default SentInvitaionModel;
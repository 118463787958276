import React, { useEffect, useState } from "react";
import Header from "../../components/NewHeader/Header";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import Back from "../../assets/images/Back_white.png";
import Logout from "../../components/logout/Logout";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import "./MyAlbums.css";
import Like from "../../assets/images/Like.png";
import gallary_upload from "../../assets/images/gallary_upload.png";
import axios from "axios";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Favorite from "@material-ui/icons/Favorite";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  container: {
    maxHeight: 500,
    overflowX: "scroll",
  },
  customCell: {
    padding: "8px 16px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  customHeaderCell: {
    fontSize: "16px",
  },
}));

const headCells = [
  {
    id: "fname",
    disablePadding: true,
    label: "File Name",
    align: "left",
  },
  {
    id: "fsize",
    numeric: true,
    disablePadding: false,
    label: "File Size",
    align: "left",
  },
  {
    id: "uploadDate",
    numeric: true,
    disablePadding: false,
    label: "Upload Date",
    align: "center",
  },
  {
    id: "remove",
    numeric: true,
    disablePadding: false,
    label: "remove",
    align: "center",
  },
];

const AlbumsImages = () => {
  const history = useHistory();
  const [List, setList] = React.useState([]);
  const [fileModel, setFileModel] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const { id } = useParams();
  const openModelUpload = () => {
    setFileModel(true);
  };
  const CloseModelUpload = () => {
    setFileModel(false);
    setFiles("");
  };
  const classes = useStyles();

  useEffect(() => {
    getDefaultData();
  }, []);
  const getDefaultData = () => {
    var token = localStorage.getItem("token");
    var userId = localStorage.getItem("userid");
    axios
      .get(
        process.env.REACT_APP_SERVER +
          `user/albumsgetbyid?nUserID=${userId}&nAlbumID=${id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageChange = (e) => {
    setIsUploadingImage(true);
    let data = [];

    for (let j = 0; j < files.length; j++) {
      data.push(files[j]);
    }

    for (let i = 0; i < e.target.files.length; i++) {
      data.push(e.target.files[i]);
    }
    handlesubmitAlbumMediaSave(data);
  };

  const handlesubmitAlbumMediaSave = (files) => {
    if (files && files.length) {
      return files.map((f) => {
        var token = localStorage.getItem("token");
        var saveFileUpload = new FormData();
        saveFileUpload.append("nUserID", localStorage.getItem("userid"));
        saveFileUpload.append("imgfile", f);

        return axios
          .post(process.env.REACT_APP_SERVER + "uploads3file", saveFileUpload, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            var saveFiledata = new FormData();
            saveFiledata.append("nUserID", localStorage.getItem("userid"));
            saveFiledata.append("nAlbumID", id);
            saveFiledata.append("nMediaID", 0);
            saveFiledata.append("sFileName", f.name);
            saveFiledata.append("s3FileName", res.data.filename);
            saveFiledata.append("nFileSize ", f.size);
            saveFiledata.append("sFileType", f.type);
            saveFiledata.append("xactiontype", "A");
            axios
              .post(
                process.env.REACT_APP_SERVER + "user/albumsmediasave",
                saveFiledata,
                { headers: { Authorization: `Bearer ${token}` } }
              )
              .then((res) => {
                  setIsUploadingImage(false);
                getDefaultData();
              })
              .catch((err) => {
                setIsUploadingImage(false);
                console.log(err);
              });
          })
          .catch((err) => {
            setIsUploadingImage(false);
            console.log(err);
          });
      });
    }
    setFiles([]);
  };

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell key={index} align={headCell.align}>
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const onChangeFiles = (e) => {
    let data = [];

    for (let j = 0; j < files.length; j++) {
      data.push(files[j]);
    }

    for (let i = 0; i < e.target.files.length; i++) {
      data.push(e.target.files[i]);
    }
    setFiles(data);
  };

  function removedFiles(index) {
    let newFiles = [];
    files.map((file, fIndex) =>
      index !== fIndex ? newFiles.push(file) : null
    );
    setFiles(newFiles);
  }

  return (
    <div>
      <Header title={localStorage.getItem("albumName")} />
      <Row className="mx-auto text-center mobile-button back-logout-btn">
        <Col
          lg={6}
          md={6}
          sm={6}
          xs={6}
          className="p-2 text-start profile-left-btn d-flex btnall"
        >
          <Link to="/my-albums" className="btn custom-link btn-primary">
            <img src={Back} alt="Back" className="w-5" /> Back
          </Link>
        </Col>
        <Col lg={6} md={6} sm={6} xs={6} className="btnall">
          <Logout />
        </Col>
      </Row>
      <Container className="alb_img container-fluid d-flex flex-column">
        <div className="w-100 pl-2 pt-2">
          <Button className="upload_btn btn-success">
            {!isUploadingImage ? <img alt='galary_upload' src={gallary_upload} height="4%" width="25px" /> : null}
            <input
              className="image_input"
              style={{ position: "absolute" }}
              type="file"
              name="file"
              accept="video/mp4,video/.mov,image/png, image/jpeg,image/jpg,image/bmp,image/.tif,image/.eps,image/tif,image/jfif,"
              multiple
              onChange={handleImageChange}
              disabled={isUploadingImage}
            />
            {isUploadingImage ? <CircularProgress color="inherit" /> : 'Photos/Videos'}
          </Button>
        </div>
        <Grid
          container
          spacing={2}
          className={
            "cardContainer d-flex align-items-center pt-2 card_image_main"
          }
        >
          {(List || []).map((e, index) => {
            return (
              <Grid item className="card_grid card_albums_Images" key={index}>
                <Card className="card_image_body">
                  <Link
                    to={`/image-download/${id}/${e.mediaid}/${e.s3filename}/${index}`}
                  >
                    <CardContent className="card_contain_image">
                      {e.storeagelocation == null ||
                      e.s3filename === "undefined" ? (
                        <img
                          src={
                            "https://reactnotes.s3.us-east-2.amazonaws.com/a_defaultbucket/Blueguysmall_2.jpg"
                          }
                          className="albums_image"
                        />
                      ) : (
                        <div className="h-100 d-flex justify-content-center">
                          {e.s3filename.slice(-3) === "mp4" ? (
                            <video width="200" height="100%" controls>
                              <source
                                src={`https://reactnotes.s3.us-east-2.amazonaws.com/${e.s3filename}`}
                                type="video/mp4"
                              />
                            </video>
                          ) : (
                            <img
                              src={e.s3PresignedUrl}
                              className="albums_image"
                            />
                          )}
                        </div>
                      )}
                    </CardContent>
                  </Link>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <Modal
        size="lg"
        show={fileModel}
        onHide={() => {
          CloseModelUpload();
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="ShareRelease-Modal-Container"
      >
        <Modal.Header closeButton className="ShareRelease-Modal-Header">
          <Modal.Title>Image Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-left">
            <div className="pt-3 pb-3">
              <b>Item Attachments</b>
            </div>
          </div>
          <div>
            <Form.Group controlId="exampleForm.ControlInput2">
              <label>
                <b>Upload: </b>
              </label>
              <Form.File
                id="custom-file"
                label="Select File to Upload..."
                custom
                onChange={(e) => onChangeFiles(e)}
                multiple
              />
            </Form.Group>
          </div>
          <div>
            <Paper className={classes.paper}>
              <TableContainer className={classes.container}>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  aria-label="enhanced table"
                  stickyHeader
                >
                  <EnhancedTableHead />
                  <TableBody>
                    {files && files.length
                      ? files.map((row, index) => {
                          return (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.name}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="left"
                              >
                                {row.size}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {moment(row.lastModified).format("d/M/YYYY")}
                              </TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                              >
                                <Button
                                  variant="danger"
                                  className="mx-2 btn-sm-height"
                                  onClick={() => {
                                    removedFiles(index);
                                  }}
                                >
                                  Remove
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            variant="primary"
            className="custom-btn"
            onClick={() => {
              handlesubmitAlbumMediaSave();
              CloseModelUpload();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default AlbumsImages;

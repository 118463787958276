import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import './NewLandingPage.css'

const Accordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const FaqSection1 = ({faqList}) => {
    const [expandedId, setExpandedId] = React.useState('panel1');

    useEffect(() => {
        setExpandedId(faqList.length ? faqList[0]['id'] : '')
    }, [faqList]);

    const handleChange = (id) => (event, newExpanded) => {
        setExpandedId(newExpanded ? id : '');
    };

    return (
        <div className="faq-section">
            <div className="faq-section-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-10 offset-xxl-1 col-lg-12 offset-lg-0 col-12 offset-1">
                            <div className="section-heading center width-64">
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-9 col-md-8 col-12 mb-5">
                            <div className="faq-wrapper">
                                <div className="faq" id="faq-accordion">
                                    {faqList.map((data) => {
                                        return (
                                            <div key={data.id}>
                                                <Accordion square expanded={expandedId === data.id}
                                                           onChange={handleChange(data.id)}>
                                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                        <Typography
                                                            className={`accordion-button ${expandedId === data.id ? '' : 'collapsed'}`}>
                                                            <span dangerouslySetInnerHTML={{__html: data.thequestion}}/>
                                                        </Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <span dangerouslySetInnerHTML={{__html: data.theanswer}}/>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqSection1
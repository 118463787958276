import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  CircularProgress,
  Tooltip,
  Grid,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";
import SharedWithContainer from "./sharedwithContainer";
import ReleasedWithContainer from "./releasedWithContainer";
import FileAttachedContainer from "./fileAttachedContainer";
import "./notestable.css";
import Menutiles from "../../assets/images/menutile.png";
import Menutiles2 from "../../assets/images/menu-tile-2.png";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import axios from "axios";
import Notesform from "../newNotesform/notesform";
import { Row, Col } from "react-bootstrap";
import GenieMen from "../../screens/contacts/GenieMen.png";

const headCells = [
    // {
    //     id: "sortOrder",
    //     numeric: true,
    //     disablePadding: false,
    //     label: "Sort Order",
    //     isShorting: false,
    //     align: "center",
    // },
    {
        id: "itemname",
        numeric: false,
        disablePadding: true,
        label: "Wish Description Item",
        isShorting: true,
        align: "left",
    },
    {
        id: "hasSharedWith",
        numeric: true,
        disablePadding: false,
        label:  <span className="tool tooltip_small" data-tip="Indicates that this item will not be private and you are giving the contacts that you choose here, immediate access
        to this information via a text or an email" style={{cursor: 'pointer'}}>Shared With</span>,
        isShorting: false,
        align: "center",
    },
    {
        id: "hasReleaseTo",
        numeric: true,
        disablePadding: false,
        label:<span className="tool tooltip_small" data-tip="Indicates the contactson your list that you would like for us to notify and share this
         particular wish with in case of emergency" style={{cursor: 'pointer'}}>Released To</span>,
        isShorting: false,
        align: "center",
    },
    {
        id: "hasAttachments",
        numeric: true,
        disablePadding: false,
        label: <span className="tool tooltip_small" data-tip="Allows you to upload photos,voice memos, videos, documentation and directions for locating
        information for your loved ones to fully understand and execute your final wishes" style={{cursor: 'pointer'}}>Has Attachments</span>,
        isShorting: false,
        align: "center",
    },
    {
        id: "inCapacitated",
        numeric: true,
        disablePadding: false,
        label: <span className="tool tooltip_small" data-tip="Incapacitated - means that you are unable to function in a normal way and you would like this
         information released immediately or after a specific # of days" style={{cursor: 'pointer'}}>In - capacitated</span>,
        isShorting: false,
        align: "center",
    },
    {
        id: "isPassedAway",
        numeric: true,
        disablePadding: false,
        label: <span className="tool Mytool" data-tip="Deceased – Indicates how soon you would like for us to release this wish, immediately, after a specific
        # of days or upon receipt of death certificate" style={{cursor: 'pointer'}}><span>Deceased</span></span>,
        isShorting: false,
        align: "center",
    },
    {
        id: "itemNotes",
        numeric: false,
        disablePadding: true,
        label: <span className="tool" data-tip="This column displays the Instructions you have for the Wish item" style={{cursor: 'pointer'}}>Notes</span>,
        isShorting: true,
        align: "left",
    },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="tableheading">
      <TableRow>
        {headCells.map((headCell, index) => {
          return headCell.isShorting ? (
            <TableCell
              key={index}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                key={index}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden} key={index}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={index} align={"center"}>
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  container: {
    maxHeight: 750,
  },
  customCell: {
    padding: "8px",
    marginBottom: "0px",
  },
  /* customRow: {
          cursor: 'pointer',
      },*/
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: "lightgrey ",
    },
    cursor: "pointer",
  },
  selected: {
    backgroundColor: "lightgrey",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis =
    (array || []).length && array.map((el, index) => [el, index]);
  (stabilizedThis || []).length &&
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
  return (stabilizedThis || []).length && stabilizedThis.map((el) => el[0]);
}

const Notestable = ({
  notesData,
  getAllItems,
  isProcess,
  selected,
  setSelected,
  handleDeleteBtn,
  BackToClear,
  isLoadedNotesData,
}) => {
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const toolTipClasses = useStylesBootstrap();
  const classes = useStyles();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const history = useHistory();
  let id = useLocation().search;
  const rowid = id.replace("?", "");

    const [fnameuser, setFnameuser] = useState("");
    const [lnameuser, setLnameuser] = useState("");

    const [noDataContent, setNoDataContent] = useState([]);

  useEffect(() => {
    const data = rowid ? Number(rowid) : null;
    setSelected(data);
    getAllItems();
    var token = localStorage.getItem("token");
    axios
      .get(process.env.REACT_APP_SERVER + "profile/detail", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        res &&
          res.data &&
          res.data.forEach((element) => {
            settile(element.wishlayout);
            setAlignment(element.wishlayout === 0 ? "left" : "center");
              setFnameuser(element.fname);
              setLnameuser(element.lname);
          });
      })
      .catch((err) => {
        console.log(err);
      });

      axios
          .get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=55`).then((res) => {
          setNoDataContent(res.data[0])
      }).catch((err) => { console.log(err)});
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    setSelected(id);
  };

  const isSelected = (id) => selected === id;

  const styles = {
    handleLoading: {
      position: "relative",
      left: "50%",
      top: "20px",
      height: "100px",
      color: "red",
    },
  };

  const handleEditBtn = (id) => {
    history.push(`/newAddNewItem/?${id}`);
  };

  const wishLayout =
    localStorage.getItem("wishlayout") !== null
      ? parseInt(localStorage.getItem("wishlayout"))
      : "";
  const [tile, settile] = useState(wishLayout);
  const [alignment, setAlignment] = React.useState(
    wishLayout === 1 ? "center" : "left"
  );

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      if (newAlignment === "left") {
        settile(0);
        localStorage.setItem("wishlayout", 0);
      } else if (newAlignment === "center") {
        settile(1);
        localStorage.setItem("wishlayout", 1);
      }

      const data = {
        nUserID: localStorage.getItem("userid"),
        nFlag: newAlignment === "left" ? 0 : 1,
      };

      var token = localStorage.getItem("token");
      axios
        .post(process.env.REACT_APP_SERVER + "user/userwishlayoutsave", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className={"notes-content-stable"}>
        <div className="float-right mb-2 d-flex tile_parent">
          <div className={classes.toggleContainer}>
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton value="left" aria-label="left aligned">
                <img src={Menutiles} className="tile-img" />
              </ToggleButton>
              <ToggleButton value="center" aria-label="centered">
                <img src={Menutiles2} className="tile-img" />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        {
          <>
            <Notesform
              isLoadedNotesData={isLoadedNotesData}
              BackToClear={BackToClear}
              getAllItems={getAllItems}
              selected={selected}
              setSelected={setSelected}
              tile={tile}
              notesData={notesData}
            />
            {isLoadedNotesData && notesData.length === 0 ? (
              <Row className="align-items-center justify-content-center">
                <Col md={6} className="position-relative">
                  <div className="Genie genie_parent_wish">
                    <img
                      src={GenieMen}
                      className="GenieMen"
                    />
                  </div>
                    <div
                        className="contact_description"
                        dangerouslySetInnerHTML={{ __html: noDataContent.thetext }}
                    />
                </Col>
              </Row>
            ) : (
              <div>
                {tile ? (
                  <Paper className={classes.paper}>
                    <TableContainer className={classes.container}>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                        stickyHeader
                        style={{ border: "1px solid #80808069" }}
                      >
                        <EnhancedTableHead
                          classes={classes}
                          numSelected={selected && selected.length}
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          rowCount={notesData.length}
                        />
                          <TableBody>
                            {(
                              stableSort(
                                notesData,
                                getComparator(order, orderBy)
                              ) || []
                            ).map((row, index) => {
                              const isItemSelected = isSelected(row.id);
                              const labelId = `enhanced-table-checkbox-${index}`;
                              const itemName = row.itemname;
                              return (
                                <>
                                  <TableRow
                                    hover
                                    onClick={(event) =>
                                      handleClick("clicked", row.id)
                                    }
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={index}
                                    selected={isItemSelected}
                                    classes={{
                                      hover: classes.hover,
                                      selected: classes.selected,
                                    }}
                                    className={classes.tableRow}
                                  >
                                    {/*<TableCell*/}
                                    {/*  align="center"*/}
                                    {/*  width={50}*/}
                                    {/*  className={classes.customCell}*/}
                                    {/*  style={{ textAlign: "center" }}*/}
                                    {/*>*/}
                                    {/*  <span className={"font-weight-bold"}>*/}
                                    {/*    {row.sortorder}*/}
                                    {/*  </span>*/}
                                    {/*</TableCell>*/}
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      align="left"
                                      className={classes.customCell}
                                      style={{ maxWidth: "415px" }}
                                    >
                                      <CardContent className="wrapped-custom-line ">
                                        <div className="new-line-text colorCode underline-text">
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: itemName
                                                .split("\n")
                                                .join("<br/>"),
                                            }}
                                            onClick={() =>
                                              handleEditBtn(row.id)
                                            }
                                          />
                                        </div>
                                      </CardContent>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width={50}
                                      className={classes.customCell}
                                    >
                                      <SharedWithContainer
                                        itemDetails={row}
                                        getAllItems={getAllItems}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width={50}
                                      className={classes.customCell}
                                    >
                                      <ReleasedWithContainer
                                        itemDetails={row}
                                        getAllItems={getAllItems}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width={50}
                                      className={classes.customCell}
                                    >
                                      <FileAttachedContainer
                                        itemDetails={row}
                                        getAllItems={getAllItems}
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width={50}
                                      className={classes.customCell}
                                    >
                                      <Tooltip
                                        title={`Indicates Incapacitated is selected or not`}
                                        arrow
                                        placement="top"
                                      >
                                        <Checkbox
                                          checked={row.isincapacitated === 1}
                                          color="primary"
                                          inputProps={{
                                            "aria-label": "secondary checkbox",
                                          }}
                                        />
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      width={50}
                                      className={classes.customCell}
                                    >
                                      <Tooltip
                                        title="Indicates Deceased is selected or not"
                                        arrow
                                        placement="top"
                                      >
                                        <Checkbox
                                          checked={row.isdeceased === 1}
                                          color="primary"
                                          inputProps={{
                                            "aria-label": "secondary checkbox",
                                          }}
                                        />
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell
                                      component="th"
                                      id={labelId}
                                      scope="row"
                                      align="left"
                                      className={classes.customCell}
                                    >
                                      <Tooltip
                                        arrow
                                        classes={toolTipClasses}
                                        placement="top-start"
                                        title={
                                          <div
                                            className="toolTipData"
                                            dangerouslySetInnerHTML={{
                                              __html: row.itemnotes,
                                            }}
                                          />
                                        }
                                      >
                                        <div
                                          className="wrapped-2"
                                          dangerouslySetInnerHTML={{
                                            __html: row.itemnotes,
                                          }}
                                          style={{ width: "500px" }}
                                        />
                                      </Tooltip>
                                    </TableCell>
                                  </TableRow>
                                </>
                              );
                            })}
                          </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                ) : (
                  <Grid container spacing={2} className="card-container-wishes">
                    {notesData?.map((e) => {
                      return (
                        <Grid item className="notestable_card" key={e.id}>
                          <Card className="contact_card_table">
                            <CardContent className="card_content_header p-2 d-flex justify-content-between">
                              <span
                                className="edit_btn"
                                onClick={() => handleEditBtn(e.id)}
                              >
                                <b>View/Edit</b>
                              </span>
                              <span
                                className="delete_btn"
                                onClick={() => handleDeleteBtn(e.id)}
                              >
                                <b>Delete</b>
                              </span>
                            </CardContent>
                            <CardContent className="card_body">
                              <div className="user_name">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: e.itemname.replaceAll(
                                      "\n",
                                      "<br/>"
                                    ),
                                  }}
                                />
                              </div>
                            </CardContent>
                            <CardActions className="justify-content-between">
                              <div className="wishes_btn">
                                  <div className="card_name"><strong>Shared<br/>
                                      With </strong></div>
                                <SharedWithContainer
                                  itemDetails={e}
                                  getAllItems={getAllItems}
                                />
                              </div>
                              <div className="wishes_btn card_name">
                                  <div><strong>Release<br/>To</strong></div>
                                <ReleasedWithContainer
                                  itemDetails={e}
                                  getAllItems={getAllItems}
                                />
                              </div>
                              <div className="wishes_btn card_name">
                                  <div><strong>Files
                                  <br/>&nbsp;</strong></div>
                                <FileAttachedContainer
                                  itemDetails={e}
                                  getAllItems={getAllItems}
                                />
                              </div>
                              <div className="wishes_btn">
                                  <div className="card_name"><strong>Incap-<br/>
                                      acitated</strong></div>
                                  <Tooltip
                                      title={`Indicates Incapacitated is selected or not`}
                                      arrow
                                      placement="top"
                                  >
                                      <Checkbox
                                          checked={e.isincapacitated === 1}
                                          color="primary"
                                          disabled={true}
                                          inputProps={{
                                              "aria-label": "secondary checkbox",
                                          }}
                                      />
                                  </Tooltip>
                              </div>
                              <div className="wishes_btn">
                                 <div className="card_name"><strong>Death<br/>
                                     &nbsp;</strong></div>
                                 <Tooltip
                                     title="Indicates Deceased is selected or not"
                                     arrow
                                     placement="top"
                                 >
                                     <Checkbox
                                         checked={e.isdeceased === 1}
                                         color="primary"
                                         disabled={true}
                                         inputProps={{
                                             "aria-label": "secondary checkbox",
                                         }}
                                     />
                                 </Tooltip>
                             </div>
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </div>
            )}
          </>
        }
      </div>
    </>
  );
};

export default Notestable;

import React from 'react';
import {Button, Modal} from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";


const HandleConfirmation = (props) => {
    const {
        isOpen,
        isLoading,
        handleClose,
        onConfirm,
        title,
        question,
        yesText,
        noText,
        color
    } = props;

    const data = {
        "primary":"#7030a0",
        "secondary":""
    }
    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            style={{zIndex: 1051}}
            centered
        >
            <Modal.Header style={{backgroundColor: "#7030a0", color: "white"}}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
                <div dangerouslySetInnerHTML={{__html:question}}/>
            </Modal.Body>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onConfirm();
                }}
            >
                <Modal.Footer>
                    {noText && (
                        <Button type="button" variant="secondary" onClick={handleClose}>
                            No
                        </Button>
                    )}
                    <Button type="submit" style={{backgroundColor: data[color]}} className="invite_btn">
                        {isLoading ? <CircularProgress/> : <span>{yesText ? yesText : "Yes"}</span>}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

HandleConfirmation.defaultProps = {
    noText:"No",
    title:"Confirmation",
    color:"primary"
}

HandleConfirmation.propTypes ={
    title:PropTypes.string,
    color:PropTypes.oneOf(["primary","secondary"]),
    isOpen:PropTypes.bool
}

export default HandleConfirmation;
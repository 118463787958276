import React, {useEffect} from "react";
import {Checkbox, TextField} from "@material-ui/core";
import "@pathofdev/react-tag-input/build/index.css";
import {makeStyles} from "@material-ui/core/styles";
import './Layout/Section1.css'

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            width: "50px",
            minWidth: "50px",
            textAlign: "center",
            border: "solid 2px",
            height: "22px",
        },
    },
}));


const Section1 = ({
  isLabelExist,
  nIncapacitated,
  nIncapDays,
  nDeceasedDays,
  Immediatly1,
  Immediatly2,
  setImmediatly1,
  setImmediatly2,
  handleNIncapacitatedChange,
  nDeceased,
  handleNDeceasedChange,
  handleDaysIncapacitateChange,
  handleDaysPassedAwayChange,
  sortOrder,
  setSortOrder
}) => {
    const styles = {
        containerFirst: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
        },
        RadioBtn: {
            display: "flex",
            flexDirection: "row",
        },
    };

    const [option1, setOption1] = React.useState(Immediatly1)
    const [option2, setOption2] = React.useState(Immediatly2)

    useEffect(() => {
        setOption1(Immediatly1);
    }, [Immediatly1]);

    useEffect(() => {
        setOption2(Immediatly2);
    }, [Immediatly2]);

    const classes = useStyles();
    const handleChange1 = (event) => {
        setOption1(event.target.value);
        setImmediatly1(event.target.value);
    };
    const handleChange2 = (event) => {
        setOption2(event.target.value);
        setImmediatly2(event.target.value);
    };


    return (
        <div className="box align-items-center section1">
            <div className={'float-right'}>
                <span>Sort Order{' '}</span>
                <TextField
                    className={'sort-order'}
                    id="outlined-basic"
                    variant="outlined"
                    type="number"
                    value={sortOrder?.length > 3 ? '' : sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                />
            </div>
            {isLabelExist ? <div className="mr-2">*Section 1</div> : null}
            <div className="mr-2 font-weight-bold">
                This Wish Item is used in case I become:{" "}
            </div>
            <div className="d-flex align-items-center justify-content-between section1-custom-checkbox w-100">
        <span style={styles.containerFirst}>
            <div className="d-flex release_items">
                    <p style={{width: "60px",color:'red'}}>If I am : </p>
                  <p style={{width: "100px"}}>Incapacitated</p>
                  <Checkbox
                      checked={nIncapacitated === 1}
                      onChange={handleNIncapacitatedChange}
                      color="primary"
                      inputProps={{
                          "aria-label": "secondary checkbox",
                      }}
                      disabled={isLabelExist}
                  />{" "}
          </div>
            <div className="d-flex release_items marginLeft align-items-center">
                <span>Release this Wish:</span>
                <div className="align-items-center ml-2 mr-2 newReleaseMyInfo-container">
                <form className={'mt-1'}>
                <select style={{width: '220px'}} className={'selectOption'} value={option1} onChange={handleChange1}>
                   <option value={0}>Immediately</option>
                   <option value={'after'}>After # of Days</option>
               </select>

                    {
                        option1 === 'after' &&
                        <span className={classes.root}>
                            <TextField
                            style={{border: "none", marginLeft: '10px'}}
                            id="outlined-basic"
                            variant="outlined"
                            value={Immediatly1 === "Immediately" || nIncapDays === 0 ? '' : nIncapDays}
                            type="number"
                            onChange={handleDaysIncapacitateChange}
                            disabled={Immediatly1 === "Immediately"}
                        />
                    <span className={'ml-1'}>{' '}days</span>
                   </span>
                    }
            </form>
          </div>
            </div>
        </span>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-between section1-custom-checkbox w-100">
        <span style={styles.containerFirst}>
            <div className="d-flex release_items">
                <span style={{width: "60px",color:'red'}}>If I am : </span>
          <span style={{width: "100px"}}>Deceased</span>
          <Checkbox
              checked={nDeceased === 1}
              onChange={handleNDeceasedChange}
              color="primary"
              inputProps={{
                  "aria-label": "secondary checkbox",
              }}
              disabled={isLabelExist}
          />{" "}
          </div>
            <div className="d-flex release_items marginLeft align-items-center">
                <span>Release this Wish:</span>
                <div className="align-items-center ml-2 mr-2 newReleaseMyInfo-container">
                <form className={'mt-2 d-flex'}>
                <select style={{width: '220px'}} className={'selectOption'} value={option2} onChange={handleChange2}>
                   <option value={0}>Immediately</option>
                   <option value={'after'}>After # of Days</option>
                     <option value={100}>Receipt of Death Certificate</option>
               </select>
                {
                    option2 === 'after'
                    && <span className={classes.root}>
                      <TextField
                          style={{border: "none", marginLeft: '10px'}}
                          id="outlined-basic"
                          variant="outlined"
                          value={Immediatly2 === "Immediately" || nDeceasedDays === 0 ? '' : nDeceasedDays}
                          type="number"
                          onChange={handleDaysPassedAwayChange}
                          disabled={Immediatly2 === "Immediately"}
                      />
                <span className={'ml-1'}>{' '}days</span>
                </span>
                }

            </form>
          </div>
           </div>
        </span>
            </div>
        </div>
    );
};

export default Section1;

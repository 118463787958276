import React, {useEffect, useState} from 'react'
import Header from "../../components/NewHeader/Header";
import {Card, Col, Row, Modal} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import Logout from "../../components/logout/Logout";
import {FormControl, RadioGroup, Button, TextField} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import "./layout/MyServicePlanSelection.css";
import axios from 'axios'
import genie_img from '../../assets/images/genie_image.jpg'
import Back from '../../assets/images/Back_white.png';
import SessionExpired from '../../components/SessionExpired/SessionExpired'
import moment from 'moment';
import rightIcon from "../../assets/images/LandingPageNew/right_icon.svg";
import Maskgroup1 from "../../assets/images/Maskgroup1.svg";
import Maskgroup2 from "../../assets/images/Maskgroup2.svg";
import Maskgroup3 from "../../assets/images/Maskgroup3.svg";
import Maskgroup4 from "../../assets/images/Maskgroup4.svg";
import Maskgroup5 from "../../assets/images/Maskgroup5.svg";
import Maskgroup6 from "../../assets/images/Maskgroup6.svg";
import sf1 from "../../assets/images/sf1.svg";
import sf2 from "../../assets/images/sf2.svg";
import sf3 from "../../assets/images/sf3.svg";
import sf4 from "../../assets/images/sf4.svg";
import sf5 from "../../assets/images/sf5.svg";
import sf6 from "../../assets/images/sf6.svg";
import sf7 from "../../assets/images/sf7.svg";
import sf8 from "../../assets/images/sf8.svg";
import sf9 from "../../assets/images/sf9.svg";
import sf10 from "../../assets/images/sf10.svg";
import Standardfeatures from "../../components/standardfeatures/standardfeatures";
import DraggebleModal from "../../components/DraggebleModal";

const priceplans = [
    {
        image : Maskgroup1,
        plan:"Starter",
        price : 4.99,
        billed:"Billed Monthly",
        CheckIn:"Yearly"
    },
    {
        image : Maskgroup1,
        plan:"Starter",
        price : 49.90,
        billed:"Billed Annually",
        CheckIn:"Yearly"
    },
    {
        image :Maskgroup2,
        plan:"Basic",
        price : 6.99,
        billed:"Billed Monthly",
        CheckIn:"Quarterly"
    },
    {
        image : Maskgroup2,
        plan:"Basic",
        price : 69.90,
        billed:"Billed Annually",
        CheckIn:"Quarterly"
    },
    {
        image : Maskgroup3,
        plan:"Silver",
        price : 9.99,
        billed:"Billed Monthly",
        CheckIn:"Monthly"
    },
    {
        image : Maskgroup3,
        plan:"Silver",
        price : 99.90,
        billed:"Billed Annually",
        CheckIn:"Monthly"
    },
    {
        image : Maskgroup4,
        price : 14.99,
        plan:"Gold",
        billed:"Billed Monthly",
        CheckIn:"Weekly"
    },
    {
        image : Maskgroup4,
        plan:"Gold",
        price : 149.90,
        billed:"Billed Annually",
        CheckIn:"Weekly"
    },
    {
        image : Maskgroup5,
        plan:"Platinum",
        price : 19.99,
        billed:"Billed Monthly",
        CheckIn:"3 Days/Week"
    },
    {
        image : Maskgroup5,
        price : 199.90,
        plan:"Platinum",
        billed:"Billed Annually",
        CheckIn:"3 Days/Week"
    },
    {
        image : Maskgroup6,
        plan:"Ultra",
        price : 24.99,
        billed:"Billed Monthly",
        CheckIn:"Daily"
    },
    {
        image : Maskgroup6,
        plan:"Ultra",
        price : 249.90,
        billed:"Billed Annually",
        CheckIn:"Daily"
    },
]

const styles = {
    mainForm: {
        maxWidth: "400px",
    },
    headerText: {
        justifyContent: 'center'
    },
    widthMax: {
        marginTop: "3px",
    },
    widthMax1: {
        marginTop: "3px",
        maxWidth: '800px',
        border: '1px solid grey',
        padding: '12px'
    }
};

const MyServicePlanSelection = () => {
    let history = useHistory()
    const [SelectedService, setService] = useState([]);
    const [showLegend, setShowLegend] = useState(false);
    const [TokenExpired, setTokenExpired] = useState(false);
    const [priceType,setPriceType] = useState('');
    const [lgShow, setLgShow] = useState(false);

    const handleLegendDailog = () => {
        setShowLegend(true)
    }

    useEffect(() => {
        getServicePlan()
    }, [])

    const getServicePlan = () => {
        var token = localStorage.getItem('token');
        const nUserID = localStorage.getItem('userid');
        const data = {nUserID}
        axios.post(process.env.REACT_APP_SERVER + `user/getuserserviceplan`, data,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then((res) => {
            if (res.data.status) {
                setTokenExpired(true)
            } else {
                if (res.data[0] == undefined || null) {
                    history.push('/SelectYourPlan')
                } else {
                    setService(res.data[0])
                    setPriceType(res.data[0].serviceplanfreq)
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
            {TokenExpired && <SessionExpired/>}
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Plan Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className={'mt-2'}>
                        <div style={{padding: '5px'}} className={'ml-2 font-weight-normal d-flex modelData'}>
                            <strong>Plan Name</strong>
                            <p className={'ml-2'}>:{
                                '  '}{SelectedService.name}</p>
                        </div>
                    </Card>
                    <Card className={'mt-2'}>
                        <div style={{padding: '5px'}} className={'ml-2 font-weight-normal d-flex modelData'}>
                            <strong>Plan Price</strong>
                            <p className={'ml-2'}>:{'  '}{SelectedService.monthlyrate}/month
                                or {SelectedService.yearlyrate}/year</p>
                        </div>
                    </Card>
                    <Card className={'mt-2'}>
                        <div style={{padding: '5px'}} className={'ml-2 font-weight-normal'}>
                            <strong>Plan Description:</strong>
                            <p className={'mt-2'}>{SelectedService.description}</p>
                        </div>
                    </Card>
                </Modal.Body>
            </Modal>

            <section className="MyService-Plan-section">
                <Header title="Service Plan"/>
                <Row className="mx-auto text-center mobile-button back-logout-btn m-0">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maindashboard" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <Logout/>
                    </Col>
                </Row>
                <div className="MyGenie">
                    <img src={genie_img} alt="genie_img" className="genie ml-5" onClick={handleLegendDailog}/>
                </div>
                {
                    SelectedService.length !== 0
                        ? (
                            <>
                            <Row style={styles.headerText} className="m-0">
                                <div className="service-plan-section">
                                    <div className={'d-flex mainContainerMyPlan px-3'}>
                                        <div className={'leftPanel'}>
                                            <span className={'font-weight-bold text-secondary plan-title'}>Your current subscription is:</span>
                                                {priceplans.filter(e => e.price == (priceType == "M" ? SelectedService.monthlyrate : SelectedService.yearlyrate)).map((e,idx)=>{
                                                    return (
                                                        <div className={`selected-plan-info ${e.plan} w-100 mb-4 mt-3`} key={idx}>
                                                            <div className="imag-box"><img src={e.image}/></div>
                                                            <div className="checkin-text">{e.CheckIn} - Service Plan</div>
                                                            <div className="billed-price">{e.billed}</div>
                                                            <div className="plan_price">${e.price.toFixed(2)}{e.billed == "Billed Annually" ? "/yr" : "/mo"}</div>
                                                            <div className="text-center d-flex align-content-center justify-content-center">
                                                                <img src={rightIcon} alt="rightIcon" width="30px"/>
                                                                <span style={{color:"#00d823",fontWeight:"bolder",fontSize:"20px",marginLeft:"12px"}}> Includes the Standard Features</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            {SelectedService.UsersPlanStatus != 1 ? (
                                                    <div className="d-flex justify-content-center">
                                                        <Link to={`/chooseServicePlan/${SelectedService.monthlyrate}`} className="d-inline-block ">
                                                            <Button variant="contained" color="primary"
                                                                    style={{height: '30px'}}>
                                                                {/*{SelectedService.UsersPlanStatus != 1 ? "Select Plan" : "Change Plan"}*/}
                                                                Select Plan
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                ) : (
                                                <div className="d-flex justify-content-center">
                                                    <Link to={`/changePlan`} className="d-inline-block ">
                                                        <Button variant="contained" color="primary"
                                                                style={{height: '30px'}}>
                                                            Change Plan
                                                        </Button>
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Row>
                                <div className="select-plan">
                                      <Standardfeatures box={true} />
                                </div>
                    </>
                        ) : null
                }
                <DraggebleModal
                    screen={64}
                    isOpen={showLegend}
                    setOpenModal={setShowLegend}
                />
            </section>
        </>
    )
}
export default MyServicePlanSelection
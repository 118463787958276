import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Checkbox, styled,
    TextField,
} from "@material-ui/core";
import "./AdditemDiscriptionWizard.css";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            width: "50px",
            minWidth: "50px",
            textAlign: "center",
            border: "solid 2px",
            height: "22px",
        },
    },
}));

const MuiLabel = styled(Typography)({

});

const AddItemIncapacitatedDeceasedWizard = ({
    nIncapacitated,
    nDeceased,
    handleNIncapacitatedChange,
    handleNDeceasedChange,
    handleChange1,
    handleChange2,
    handleDaysIncapacitateChange,
    handleDaysPassedAwayChange,
    Immediatly1,
    Immediatly2,
    option1,
    option2,
    nIncapDays,
    nDeceasedDays,
}) => {
    const classes = useStyles();
    const styles = {
        containerFirst: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
        },
        RadioBtn: {
            display: "flex",
            flexDirection: "row",
        },
    };
    return (
        <div>
            <div style={{border: "1px solid grey", padding: "5px"}}>
                <b>
                    This Screen allows you to be specific in which case you want the wish
                    item to be Released to one or more of your loved once and when it
                    shoud be released.
                </b>
            </div>
            <div className="mr-2 font-weight-bold mt-3">
                This Wish Item is used in case I become:{" "}
            </div>
            <div className="d-flex align-items-center justify-content-between section1-custom-checkbox">
                <span style={styles.containerFirst}>
                  <div className="d-flex">
                    <p style={{width: "100px"}}>Incapacitated</p>
                    <Checkbox
                        checked={nIncapacitated === 1}
                        onChange={handleNIncapacitatedChange}
                        color="primary"
                        inputProps={{
                            "aria-label": "secondary checkbox",
                        }}
                    />
                    </div>

                    <div className="d-flex align-items-center flex-wrap mb-3 mb-sm-0">
                  <span className={"marginLeft"}>Release this Wish:</span>

                  <div className="align-items-center ml-2 mr-2 newReleaseMyInfo-container">
                    <form className={"mt-1"}>
                      <select
                          style={{width: "220px"}}
                          className={"selectOption"}
                          value={option1}
                          onChange={handleChange1}
                      >
                        <option value={0}>Immediately</option>
                        <option value={"after"}>After # of Days</option>
                      </select>

                        {option1 === "after" && (
                            <span className={classes.root}>
                              <TextField
                                  style={{border: "none", marginLeft: "10px"}}
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={
                                      Immediatly1 === "Immediately" || nIncapDays === 0
                                          ? ""
                                          : nIncapDays
                                  }
                                  type="number"
                                  onChange={handleDaysIncapacitateChange}
                                  disabled={Immediatly1 === "Immediately"}
                              />
                              <span className={"ml-1"}>
                                  days
                              </span>
                              <span className="ml-1 text-danger max_days_label">(max 30 days)</span>
                            </span>
                        )}
                    </form>
                  </div>
                    </div>
                </span>
            </div>
            <div className="d-flex w-100 align-items-center justify-content-between section1-custom-checkbox">
        <span style={styles.containerFirst}>
            <div className="d-flex">
          <span style={{width: "100px"}}>Deceased</span>
           <Checkbox
               checked={nDeceased === 1}
               onChange={handleNDeceasedChange}
               color="primary"
               inputProps={{
                   "aria-label": "secondary checkbox",
               }}
           />
            </div>

            <div className="d-flex align-items-center flex-wrap ">
          <span className={"marginLeft"}>Release this Wish:</span>
          <div className="align-items-center ml-2 mr-2 newReleaseMyInfo-container">
            <form className={"mt-2 d-flex"}>
              <select
                  style={{width: "220px"}}
                  className={"selectOption"}
                  value={option2}
                  onChange={handleChange2}
              >
                <option value={0}>Immediately</option>
                <option value={"after"}>After # of Days</option>
                <option value={100}>Receipt of Death Certificate</option>
              </select>
                {option2 === "after" && (
                    <span className={classes.root}>
                      <TextField
                          style={{border: "none", marginLeft: "10px"}}
                          id="outlined-basic"
                          variant="outlined"
                          value={
                              Immediatly2 === "Immediately" || nDeceasedDays === 0
                                  ? ""
                                  : nDeceasedDays
                          }
                          type="number"
                          onChange={handleDaysPassedAwayChange}
                          disabled={Immediatly2 === "Immediately"}
                      />
                      <span className={"ml-1"}> days</span>
                      <span className="ml-1 text-danger max_days_label">(max 30 days)</span>
                    </span>
                )}
            </form>
          </div>
            </div>
        </span>
            </div>
        </div>
    );
};

export default AddItemIncapacitatedDeceasedWizard;

import React from 'react';
import {Form} from "react-bootstrap";
import {Editor} from '@tinymce/tinymce-react';

const NoteSection = (props) => {
    const {
        isItemNoteInput,
        isInputDisabled,
        itemNotes,
        setItemNotes,
        itemNameLabel
    } = props;

    const onEditorStateChange = (editorState) => {
        setItemNotes(editorState);
    };

    return (
        <div className="box">
            <div className="w-100 d-flex align-items-center justify-content-between pb-2 flex-wrap">
                <label>
                    <b>{itemNameLabel}</b>
                </label>
            </div>
            {isItemNoteInput && (
                <Form.Group
                    controlId="exampleForm.ControlInput2-Notes"
                    className="d-flex flex-column align-items-start"
                    id="demoEditor"
                >
                    {isInputDisabled ? (
                        <div
                            className="notes-content"
                            dangerouslySetInnerHTML={{__html: itemNotes}}
                        />
                    ) : (
                        <Editor
                            value={itemNotes}
                            onEditorChange={onEditorStateChange}
                            apiKey='97one8fo56cr08pc620j9rhz2pku2c3kvkxfg3xrs0n8absc'
                            id="demo"
                            init={{
                                plugins: "  fullpage  fullscreen  link advlist lists  ",
                                toolbar: " bold italic underline strikethrough | fontselect | fontsizeselect | formatselect | lineheight|alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor | casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
                                content_style: "body p { line-height: 0.5; }"
                            }}
                        />
                    )}
                </Form.Group>
            )}
        </div>
    );
};

export default NoteSection;
import React, {useState, useEffect} from 'react';
import {Row, Col, Button, Toast, Modal} from 'react-bootstrap';
import './addNewItem.css';
import {Link, useHistory, useLocation} from 'react-router-dom';
import Back from '../../assets/images/Back_white.png';
import axios from 'axios';
import Section2 from '../../components/newNotesform/Section2';
import Section1 from '../../components/newNotesform/Section1';
import {makeStyles} from '@material-ui/core/styles';
import Logout from '../../components/logout/Logout'
import Header from '../../components/NewHeader/Header'
import genie_img from "../../assets/images/genie_image.jpg";
import moment from "moment";
import OptionalSection from "../../components/newNotesform/OptionalSection";
import NoteSection from "../../components/newNotesform/NoteSection";
import { EditorState, ContentState, convertToRaw} from 'draft-js';
import ShowFilesList from "../../components/newNotesform/ShowFilesList";
import HandleConfirmation from "../handleConfirmation";
import {getDraftContent, getHtmlElement} from "../../utils/phone";

const AddNewItem = () => {
    const [itemName, setItemName] = useState('');
    const [itemNotes, setItemNotes] = useState('');
    const [showTost, setShowTost] = useState(false);
    const [existingSharedContacts, setExistingSharedContacts] = useState([]);
    const [nIncapacitated, setnIncapacitated] = useState(0);
    const [nDeceased, setnDeceased] = useState(0);
    const [createdDate, setCreatedDate] = useState(new Date());
    const [updatedDate, setUpdatedDate] = useState(new Date());
    const [nIncapDays, setNIncapDays] = useState(0)
    const [nDeceasedDays, setNDeceasedDays] = useState(0)
    const [mediaDetails, setMediaDetails] = useState([]);
    const [Immediatly1, setImmediatly1] = useState(0);
    const [Immediatly2, setImmediatly2] = useState(0);
    const [showLegend, setShowLegend] = useState(false);
    const [LegendData, setLegendData] = React.useState('');
    const [sortOrder, setSortOrder] = React.useState('');
    const [showEditWish,setshowEditWish] = React.useState(false);

    const [optionalData, setOptionalData] = useState({
        Xbusinessname: null,
        Xaccountnumber: null,
        Xaddress: null,
        Xcity: null,
        Xzip: null,
        Xurl: null,
        Xusername: null,
        Xpword: null,
        Xcontactinfo: null,
        Xstateid:null,
    })

    const handleChangeField = (e,name) => {
        setOptionalData((oldProps)=>({
            ...oldProps,
            [e.target.name]:e.target.value
        }))
    }

    let id = useLocation().search;
    const rowid = id.replace('?', '');


    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTost(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, [showTost]);

    const toggleTost = () => setShowTost(!showTost);


    useEffect(() => {
        if (rowid) {
            getDetails();
            getMedia();
        }
    }, [rowid]);

    const getDetails = () => {
        const data = {
            nUserID: localStorage.getItem('userid'), nID: rowid,
        };
        var token = localStorage.getItem('token');
        axios
            .post(process.env.REACT_APP_SERVER + 'inventory/getdetails', data, {headers: {Authorization: `Bearer ${token}`}})
            .then((res) => {
                if ((res.data || []).length) {
                    if (res.data[0].incapacitatedreleasedays != 0 && res.data[0].incapacitatedreleasedays != 100) {
                        setImmediatly1("after");
                    } else {
                        setImmediatly1(res.data[0].incapacitatedreleasedays);
                    }

                    if (res.data[0].deceasedreleasedays != 0 && res.data[0].deceasedreleasedays != 100) {
                        setImmediatly2("after");
                    } else {
                        setImmediatly2(res.data[0].deceasedreleasedays);
                    }

                    if (res.data[0]){
                        setSortOrder(res.data[0].sortorder);
                        setnIncapacitated(res.data[0] !== undefined ? res.data[0].isincapacitated : '')
                        setnDeceased(res.data[0] !== undefined ? res.data[0].isdeceased : '')
                        setNIncapDays(res.data[0] !== undefined ? res.data[0].incapacitatedreleasedays : '')
                        setNDeceasedDays(res.data[0] !== undefined ? res.data[0].deceasedreleasedays : '')
                        setItemName(res.data[0] !== undefined ? res.data[0].itemname : '');

                        const editor = res.data[0].itemnotes;
                        setItemNotes(editor);

                        setCreatedDate(res.data[0] !== undefined ? res.data[0].created : new Date());
                        setUpdatedDate(res.data[0] !== undefined ? res.data[0].updated : new Date());
                        setOptionalData({
                            Xbusinessname: res.data[0] !== undefined ? res.data[0].businessname : '',
                            Xaccountnumber: res.data[0] !== undefined ? res.data[0].accountnumber : '',
                            Xaddress: res.data[0] !== undefined ? res.data[0].address : '',
                            Xcity: res.data[0] !== undefined ? res.data[0].city : '',
                            Xzip: res.data[0] !== undefined ? res.data[0].zip : '',
                            Xurl: res.data[0] !== undefined ? res.data[0].url : '',
                            Xusername: res.data[0] !== undefined ? res.data[0].username : '',
                            Xpword: res.data[0] !== undefined ? res.data[0].pword : '',
                            Xcontactinfo: res.data[0] !== undefined ? res.data[0].contactinfo : "",
                            Xstateid: res.data[0] !== undefined ? res.data[0].stateid : '',
                        })
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const getMedia = () => {
        const token = localStorage.getItem('token');
        axios.post(process.env.REACT_APP_SERVER + 'inventory/getmedia',
            {nInvItemID: rowid},
            {headers: {Authorization: `Bearer ${token}`}}
        )
            .then((res) => {
                setMediaDetails(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const DataAllWishes = () => {
        const data = {
            nUserID: localStorage.getItem('userid'), nID: rowid,
        };
        var token = localStorage.getItem('token');
        axios
            .post(process.env.REACT_APP_SERVER + 'inventory/getdetails', data, {headers: {Authorization: `Bearer ${token}`}})
            .then((res) => {
                if ((res.data || []).length) {
                    if (res.data[0].incapacitatedreleasedays != 0 && res.data[0].incapacitatedreleasedays != 100) {
                        setImmediatly1("after");
                    } else {
                        setImmediatly1(res.data[0].incapacitatedreleasedays);
                    }

                    if (res.data[0].deceasedreleasedays != 0 && res.data[0].deceasedreleasedays != 100) {
                        setImmediatly2("after");
                    } else {
                        setImmediatly2(res.data[0].deceasedreleasedays);
                    }
                    setSortOrder(res.data[0].sortorder)
                    setnIncapacitated(res.data[0] !== undefined ? res.data[0].isincapacitated : '')
                    setnDeceased(res.data[0] !== undefined ? res.data[0].isdeceased : '')
                    setNIncapDays(res.data[0] !== undefined ? res.data[0].incapacitatedreleasedays : '')
                    setNDeceasedDays(res.data[0] !== undefined ? res.data[0].deceasedreleasedays : '')
                    setItemName(res.data[0] !== undefined ? res.data[0].itemname : '');
                    if (res.data[0]){
                        const editor = res.data[0].itemnotes;
                        setItemNotes(editor);
                    }
                    setCreatedDate(res.data[0] !== undefined ? res.data[0].created : new Date());
                    setUpdatedDate(res.data[0] !== undefined ? res.data[0].created : new Date());
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const submitNewItem = (e) => {
        e.preventDefault();
        if (itemName.length >= 500) {
            toggleTost();
        } else {
            var token = localStorage.getItem('token');
            const fieldValues = {...optionalData,Xstateid:parseInt(optionalData.Xstateid)}
            const data = {
                nID: Number(rowid),
                nUserID: localStorage.getItem('userid'),
                sItemName: itemName,
                sItemNotes:itemNotes,
                nIsShared: 0,
                nIsTimeRelease: 0,
                nIsDeceased: nDeceased,
                nIsIncapacitated: nIncapacitated,
                nIncapDays: Immediatly1 === "after" ? nIncapDays : Immediatly1,
                nDeceasedDays: Immediatly2 === "after" ? nDeceasedDays : Immediatly2,
                nSortOrder: sortOrder,
                ...fieldValues,
            };
            axios.post(process.env.REACT_APP_SERVER + 'inventory/additem', data,
                {headers: {Authorization: `Bearer ${token}`}})
                .then((res) => {
                    if (res.data.status) {
                    } else {
                        setItemName('');
                        setItemNotes("");
                        // history.push(`/newDashboard/?${data.nID}`);
                        modelEditConformationClose()
                        DataAllWishes()
                        modelEditConformationOpen();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleNIncapacitatedChange = (event) => {
        setnIncapacitated(event.target.checked ? 1 : 0);
    };
    const handleNDeceasedChange = (event) => {
        setnDeceased(event.target.checked ? 1 : 0);
    };

    const handleDaysIncapacitateChange = (event) => {
        const {value} = event.target;
        setNIncapDays(value > 0 && value <= 30 ? value : '');
    };

    const handleDaysPassedAwayChange = (event) => {
        const {value} = event.target;
        setNDeceasedDays(value > 0 && value <= 30 ? value : '');
    };

    const handleLegendDailog = () => {
        getLegendData()
        setShowLegend(true)
    };

    const handleCloseLegend = () => {
        setShowLegend(false)
    };

    const getLegendData = () => {
        axios.get(process.env.REACT_APP_SERVER + `readme?nWhichScreen=19`)
            .then((res) => {
                setLegendData(res.data[0])
            }).catch((err) => {
            console.log(err)
        })
    }

    const modelEditConformationOpen = () => {
        setshowEditWish(true)
    }
    const modelEditConformationClose = () => {
        setshowEditWish(false)
    }

    return (<>

        <Header title="Wish Item"/>
        <div
            aria-live="polite"
            aria-atomic="true"
            style={{
                position: 'relative', backgroundColor: 'red',
            }}
        >
            <Toast
                style={{
                    position: 'absolute', top: 10, right: 10, backgroundColor: 'red',
                }}
                show={showTost}
                onClose={toggleTost}
            >
                <Toast.Header>
                    <strong className="mr-auto">
                        Sorry Wish Description Item cannot exceed 500 characters.
                    </strong>
                </Toast.Header>
            </Toast>
        </div>
        <Row className="mx-auto text-center mobile-button back-logout-btn">
            <Col
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className="p-2 text-start profile-left-btn d-flex btnall"
            >
                <Link to="/newDashboard" className="btn custom-link btn-primary">
                    <img src={Back} alt="Back" className="w-5"/> Back
                </Link>
            </Col>
            <Col
                lg={6} md={6} sm={6} xs={6} className="btnall"
            >
                <Logout/>
            </Col>
        </Row>
        <div className="with_genie">
            <img src={genie_img} alt="genie_img" className="genie" onClick={handleLegendDailog}/>
        </div>
        <Row className="bg-white p-4 justify-content-center position-relative m-0">
            <Col lg={7} sm={10}>
                <div className="">
                    <div className="w-100 d-flex align-items-center justify-content-end pb-2">
                        {/*<div style={{width: '96%'}}>*/}
                        {/*        <span*/}
                        {/*            className="mr-5">Created Date : {moment(createdDate).format('MM/DD/YYYY hh:mm:A')}</span>*/}
                        {/*    <span>Updated Date : {moment(updatedDate).format('MM/DD/YYYY hh:mm:A')}</span>*/}
                        {/*</div>*/}
                        <div>
                            <Button
                                className="btn-sm-height"
                                disabled={
                                    (Immediatly1 === 'after' && nIncapDays < 1) ||
                                    (Immediatly2 === 'after' && nDeceasedDays < 1) ||
                                    (itemName.length === 0)
                                }
                                onClick={submitNewItem}
                            > Submit
                            </Button>
                        </div>
                    </div>
                    <Section1
                        isLabelExist={false}
                        nIncapacitated={nIncapacitated}
                        setNIncapDays={setNIncapDays}
                        setNDeceasedDays={setNDeceasedDays}
                        nIncapDays={nIncapDays}
                        nDeceasedDays={nDeceasedDays}
                        handleNIncapacitatedChange={handleNIncapacitatedChange}
                        nDeceased={nDeceased}
                        handleNDeceasedChange={handleNDeceasedChange}
                        handleDaysIncapacitateChange={handleDaysIncapacitateChange}
                        handleDaysPassedAwayChange={handleDaysPassedAwayChange}
                        Immediatly1={Immediatly1}
                        Immediatly2={Immediatly2}
                        setImmediatly1={setImmediatly1}
                        setImmediatly2={setImmediatly2}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                    />
                    <Section2
                        isInputDisabled={false}
                        itemName={itemName}
                        setItemName={setItemName}
                        setItemNotes={setItemNotes}
                        existingSharedContacts={existingSharedContacts}
                        isItemNameInput={true}
                        isViewSendTextEmail={true}
                        isViewSuggetion={true}
                        itemNameLabel="Wish Description Item:"
                    />
                    <OptionalSection
                        optionalData={optionalData}
                        setOptionalData={setOptionalData}
                        handleChangeField={handleChangeField}
                    />
                    <NoteSection
                        isItemNoteInput={true}
                        isInputDisabled={false}
                        itemNotes={itemNotes}
                        setItemNotes={setItemNotes}
                        itemNameLabel="Notes:"
                    />
                    <ShowFilesList
                        mediaDetails={mediaDetails}
                        getMedia={getMedia}
                    />
                </div>
            </Col>
        </Row>

        <Modal show={showLegend} onHide={handleCloseLegend}>
            <Modal.Header closeButton className={'modelHeader'}>
                <Modal.Title>{LegendData.theheader}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="model-genie"
                     dangerouslySetInnerHTML={{__html: LegendData.thetext}}
                >
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btngenie" onClick={handleCloseLegend}>
                    Okay
                </Button>
            </Modal.Footer>
        </Modal>

        <HandleConfirmation
            isOpen={showEditWish}
            handleClose={modelEditConformationClose}
            onConfirm={modelEditConformationClose}
            title="Wish Information"
            color=""
            question="Your Wish information has been successfully saved!"
            yesText="Okay"
            noText=""
        />
    </>);
};
export default AddNewItem;

import React, {useEffect, useState} from 'react';
import Header from "../../components/NewHeader/Header";
import {FormControl, FormControlLabel, InputLabel, MenuItem, RadioGroup, Select, TextField} from "@material-ui/core";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Radio, Button} from "@material-ui/core";
import {Link, useHistory, useParams} from "react-router-dom";
import Logout from "../../components/logout/Logout";
import './layout/MyServicePlanSelection.css'
import axios from "axios";
import SubscribePayment from "../../admin/screen/SubscribePlan/Payment/SubscribePayment";
import './layout/ChooseServicePlan.css'
import Back from '../../assets/images/Back_white.png';
import SessionExpired from '../../components/SessionExpired/SessionExpired'
import {makeStyles, withStyles} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import StarIcon from '@material-ui/icons/Star';
import Maskgroup1 from "../../assets/images/Maskgroup1.svg";
import Maskgroup2 from "../../assets/images/Maskgroup2.svg";
import Maskgroup3 from "../../assets/images/Maskgroup3.svg";
import Maskgroup4 from "../../assets/images/Maskgroup4.svg";
import Maskgroup5 from "../../assets/images/Maskgroup5.svg";
import Maskgroup6 from "../../assets/images/Maskgroup6.svg";
import rightIcon from "../../assets/images/LandingPageNew/right_icon.svg";
import Standardfeatures from "../../components/standardfeatures/standardfeatures";


import sf1 from "../../assets/images/sf1.svg";
import sf2 from "../../assets/images/sf2.svg";
import sf3 from "../../assets/images/sf3.svg";
import sf4 from "../../assets/images/sf4.svg";
import sf5 from "../../assets/images/sf5.svg";
import sf6 from "../../assets/images/sf6.svg";
import sf7 from "../../assets/images/sf7.svg";
import sf8 from "../../assets/images/sf8.svg";
import sf9 from "../../assets/images/sf9.svg";
import sf10 from "../../assets/images/sf10.svg";

const priceplans = [
    {
        image : Maskgroup1,
        plan:"Starter",
        price : 4.99,
        billed:"Billed Monthly",
        CheckIn:"Yearly",
        type:"monthly",
    },
    {
        image : Maskgroup1,
        plan:"Starter",
        price : 49.90,
        billed:"Billed Annually",
        CheckIn:"Yearly",
        type:"yearly",
    },
    {
        image :Maskgroup2,
        plan:"Basic",
        price : 6.99,
        billed:"Billed Monthly",
        CheckIn:"Quarterly",
        type:"monthly",
    },
    {
        image : Maskgroup2,
        plan:"Basic",
        price : 69.90,
        billed:"Billed Annually",
        CheckIn:"Quarterly",
        type:"yearly",
    },
    {
        image : Maskgroup3,
        plan:"Silver",
        price : 9.99,
        billed:"Billed Monthly",
        CheckIn:"Monthly",
        type:"monthly",
    },
    {
        image : Maskgroup3,
        plan:"Silver",
        price : 99.90,
        billed:"Billed Annually",
        CheckIn:"Monthly",
        type:"yearly",
    },
    {
        image : Maskgroup4,
        price : 14.99,
        plan:"Gold",
        billed:"Billed Monthly",
        CheckIn:"Weekly",
        type:"monthly",
    },
    {
        image : Maskgroup4,
        plan:"Gold",
        price : 149.90,
        billed:"Billed Annually",
        CheckIn:"Weekly",
        type:"yearly",
    },
    {
        image : Maskgroup5,
        plan:"Platinum",
        price : 19.99,
        billed:"Billed Monthly",
        CheckIn:"3 Days/Week",
        type:"monthly",
    },
    {
        image : Maskgroup5,
        price : 199.90,
        plan:"Platinum",
        billed:"Billed Annually",
        CheckIn:"3 Days/Week",
        type:"yearly",
    },
    {
        image : Maskgroup6,
        plan:"Ultra",
        price : 24.99,
        billed:"Billed Monthly",
        CheckIn:"Daily",
        type:"monthly",
    },
    {
        image : Maskgroup6,
        plan:"Ultra",
        price : 249.90,
        billed:"Billed Annually",
        CheckIn:"Daily",
        type:"yearly",
    },
]


const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const ChooseServicePlan = () => {
    const history = useHistory();
    const {id} = useParams();
    const [pricingType ,setpricingType] = useState("yearly");
    const [plan , setPlan] = useState();
    const [PaymentOpen, setPaymentOpen] = useState(false);
    const [selectedDays, setCurrentOption] = React.useState('');
    const [subscribePlan, setSubscribePlan] = useState('')
    const [PackageList, setPackageList] = useState([])
    const [servicePlan, setSelectedService] = React.useState('');
    const [nServicePlanID, setSelectedPlanId] = React.useState('');
    const [TokenExpired, setTokenExpired] = useState(false);
    const [timezone, setTimeZone] = React.useState([]);
    const [nTimeZoneID, setNTimeZoneID] = React.useState("");
    const [stripProdNumber,setStripProdNumber] = useState("");
    const [billingFrequency,setBillingFrequency] = useState("Billed Yearly");
    const description = (`${plan} Service Plan - ${billingFrequency}`)

    useEffect(() => {
        getAllPackageList()
    }, [])

    const getTimeZone = () => {
        axios.get(
            process.env.REACT_APP_SERVER + `user/timezonesget`, {
                headers: {Authorization: `Bearer ${token}`},
            }).then((res) => {
                setTimeZone(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };
    var token = localStorage.getItem('token');

    const getAllPackageList = () => {
        const nUserID = localStorage.getItem('userid');
        const data = {nUserID}
         axios.post(process.env.REACT_APP_SERVER + `service/getall`, data,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then((res) => {
            if (res.data.status) {
                setTokenExpired(true)
            } else {
                setPackageList(res.data)
                getTimeZone()
                setSelectedService(id);
                 const selected = res.data.find(r => r.monthlyrate == id || r.yearlyrate == id)
                setserviceplandata(selected?.description);
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const setSelectedData = () => {
        if (servicePlan){
            const selected = PackageList.find(r => r.monthlyrate == servicePlan || r.yearlyrate == servicePlan);
            setSubscribePlan(selected)
            setSelectedPlanId(selected.id)
            getServicePlanHeader(selected.id)
            // if(servicePlan == "49.99"){
            //     console.log("hii")
            // }
        }
    }

    const [serviceplandata,setserviceplandata] = useState('')

   const handleChangeServicePlan = (id,pkg = null) => {
        setSelectedService(id);
        setserviceplandata(pkg ? pkg.description : null)
    }


    const selectServicePlan = (plan = "") =>{
        const selected = PackageList.find(r => r.monthlyrate == plan || r.yearlyrate == plan);
        const priceProdNum = (pricingType === "monthly" || billingFrequency==="Billed Monthly") ? selected.stripe_productnumber_monthly : selected.stripe_productnumber_yearly;
        setSelectedService(plan)
        setSubscribePlan(selected)
        setSelectedPlanId(selected.id)
        getServicePlanHeader(selected.id);
        setPaymentOpen(true);
        setStripProdNumber(priceProdNum);
    }

    const styles = {
        mainForm: {
            display: 'grid',
            alignItems: 'center',
            maxWidth: "495px",
        },
        backBtn: {
            backgroundColor: '#7030a0',
            color: 'white',
            padding: '6px 12px',
            textTransform: 'capitalize',
            fontWeight: 'normal',
        },
        widthMax1: {
            maxWidth: '800px',
            border: '3px solid grey',
            padding: '12px',
            minHeight:'625px',
            marginTop: '12px'
        }
    }


    const OpenPaymnetBtn = () => {
        setSelectedData()
        setPaymentOpen(true);
    };

    const handleBackBtn = () => {
        setPaymentOpen(false);
        setCurrentOption('')
    };
    const handleChange = (event) => {
        setCurrentOption(event.target.value);
    };

    const getOptionData = (id) => {
        let dayvalue = ["Sunday","Monday","Tuesdsay","Wednesday","Thursday","Friday","Saturday"]
        let days=[]
        let twovalue = ["M/W/Fr","T/Th/Sa"]
        let time = ['04:00PM', '05:00PM', '06:00PM', '07:00PM']
        for (let i = 1; i <= 31; i++) {
            days.push(i)
        }
        switch (id) {
            case 1:
            case 10:
            case 28:
                return (days.map((day,index) =><MenuItem className={'cursor-pointer'} value={day} key={index}>{day}</MenuItem>));
            case 25:
                return (dayvalue.map((day,index) => <MenuItem className={'cursor-pointer'}
                                                              value={index+1} key={index}>{day}</MenuItem>))
            case 8:
                return (time.map((hour,index) => <MenuItem className={'cursor-pointer'}
                                                   value={hour.charAt(1)} key={index}>{hour}</MenuItem>))
            case 9:
                return (twovalue.map((day,index) => <MenuItem className={'cursor-pointer'}
                                                              value={index+1} key={index}>{day}</MenuItem>))
            default:
                return null
        }
    }


    const [ServiceHeader, setServiceHeader] = useState([]);

    const getServicePlanHeader = (Id) => {
        var userId = localStorage.getItem("userid");
        axios.get(process.env.REACT_APP_SERVER + `user/userTZoneAndPlanBkgd?nUserID=${userId}&nPlanID=${Id}`,
            {headers: {Authorization: `Bearer ${token}`}}
        ).then((res) => {
            setServiceHeader(res.data[0]);
            setNTimeZoneID(res.data[0].TimeZoneID);
        }).catch((err) => {
            console.log(err)
        })
    }
    const selectServiceType = (e) => {
        setSelectedService(e.target.value)
        const findMyPlan = priceplans.find((plan)=>plan.price == e.target.value);
        if (findMyPlan){
            const selected = PackageList.find(r => (r.monthlyrate == e.target.value || r.yearlyrate == e.target.value));
            setpricingType(findMyPlan.type)
            const priceProdNum = (findMyPlan.type === "monthly") ? selected.stripe_productnumber_monthly : selected.stripe_productnumber_yearly;
            setStripProdNumber(priceProdNum);
        }
    }

    useEffect(()=>{
        const day = new Date();
        setCurrentOption(day.getDate());
    },[selectedDays === ''])


    return (
        <>
            {
                TokenExpired
                && <SessionExpired/>
            }
            <section className="service-plan-section ">
                {
                    PaymentOpen
                        ? <Header title="PAYMENT"/>
                        : <Header title="SERVICE PLAN"/>
                }
                <Row className="mx-auto text-center mobile-button back-logout-btn m-0">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        {
                            PaymentOpen
                                ? <Button onClick={handleBackBtn} style={styles.backBtn}
                                          className="btn custom-link btn-primary">
                                    <img src={Back} alt="Back" className="w-5 mr-1"/> Back
                                </Button>
                                : <span onClick={()=>history.goBack()} className="btn custom-link btn-primary">
                                    <img src={Back} alt="Back" className="w-5"/> Back
                                </span>
                        }
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <Logout/>
                    </Col>
                </Row>

                {/*{*/}
                {/*    PaymentOpen === false &&*/}
                {/*    <div className={'font-weight-bold text-black plan px-3'}>Choose Your Service Plan</div>*/}
                {/*}*/}
                <div className={'mainCardDiv'}>
                    {
                        PaymentOpen === false
                            ?
                            <div className={'d-flex flex-wrap center flex-column service_content p-0 mt-5'} >
                                {
                                    PackageList.length !== 0
                                        ? (
                                  <div className="d-flex justify-content-center main_div_sarvice">
                                      {/*<div className="submin_div">*/}
                                      {/*    {*/}
                                      {/*        PaymentOpen === false &&*/}
                                      {/*        <div className={'d-flex  w-100 btnView pl-2'}>*/}
                                      {/*            <Button variant="contained" color="primary"*/}
                                      {/*                    onClick={() => OpenPaymnetBtn()} disabled={!servicePlan} className={'SubmitBtn'}>*/}
                                      {/*                Submit*/}
                                      {/*            </Button>*/}
                                      {/*        </div>*/}
                                      {/*    }*/}
                                      {/*    {*/}
                                      {/*        PackageList.map((pack) => {*/}
                                      {/*            return (*/}
                                      {/*                <div className="d-flex card-plan-radio mt-3" key={pack.id}>*/}
                                      {/*                    <FormControl component="fieldset">*/}
                                      {/*                        <RadioGroup*/}
                                      {/*                            aria-label="timeslot"*/}
                                      {/*                            name="servicePlan"*/}
                                      {/*                            value={servicePlan}*/}
                                      {/*                            onChange={(e) => handleChangeServicePlan(e.target.value, pack)}*/}
                                      {/*                        >*/}
                                      {/*                            <Card style={styles.mainForm}*/}
                                      {/*                                  className={'main-container-for-description'}>*/}
                                      {/*                                <div className="d-flex title-text">*/}
                                      {/*                                    <div*/}
                                      {/*                                        className={'d-flex flex-column radiobtn '}>*/}
                                      {/*                                        <div className="time-text ml-1">*/}
                                      {/*                                            <p className={'text-capitalize planDescription ml-2'}>*/}
                                      {/*                                                <StarIcon*/}
                                      {/*                                                    color={'secondary'}/>{'     '}{pack.shortdescription}*/}
                                      {/*                                            </p>*/}
                                      {/*                                            <p className={'font-weight-bold text-uppercase nameofplan ml-2'}>{pack.name}</p>*/}
                                      {/*                                        </div>*/}
                                      {/*                                        <div className="ml-2 d-flex checkbox_seriveplan">*/}
                                      {/*                                            <div*/}
                                      {/*                                                className="d-flex align-items-center choose_serive_chackbox">*/}
                                      {/*                                                <Radio className="p-1"*/}
                                      {/*                                                       value={pack.monthlyrate.toString().trim()}*/}
                                      {/*                                                       name="radio-button-demo"*/}
                                      {/*                                                       inputProps={{'aria-label': 'A'}}*/}
                                      {/*                                                       checked={pack.monthlyrate == servicePlan}*/}
                                      {/*                                                />*/}
                                      {/*                                                <p className="m-0">${pack.monthlyrate}/month</p>*/}
                                      {/*                                            </div>*/}
                                      {/*                                            <div*/}
                                      {/*                                                className="ml-5 d-flex align-items-center choose_serive_chackbox_yearly">*/}
                                      {/*                                                <Radio className="p-1"*/}
                                      {/*                                                       value={pack.yearlyrate.toString().trim()}*/}
                                      {/*                                                       name="radio-button-demo"*/}
                                      {/*                                                       inputProps={{'aria-label': 'A'}}*/}
                                      {/*                                                       checked={pack.yearlyrate == servicePlan}*/}
                                      {/*                                                />*/}
                                      {/*                                                <p className="m-0">${pack.yearlyrate}/year</p>*/}
                                      {/*                                            </div>*/}
                                      {/*                                        </div>*/}
                                      {/*                                    </div>*/}
                                      {/*                                </div>*/}
                                      {/*                            </Card>*/}
                                      {/*                        </RadioGroup>*/}
                                      {/*                    </FormControl>*/}
                                      {/*                </div>*/}
                                      {/*            )*/}
                                      {/*        })}*/}
                                      {/*</div>*/}
                                      {/*{*/}
                                      {/*    servicePlan && (<div style={{marginTop: "40px"}}>*/}
                                      {/*        <div*/}
                                      {/*            style={styles.widthMax1}*/}
                                      {/*            dangerouslySetInnerHTML={{__html: serviceplandata?.replace(/\n/g, "<br />")}}*/}
                                      {/*        >*/}
                                      {/*        </div>*/}
                                      {/*    </div>)*/}
                                      {/*}*/}

                                  </div>
                                        ) : null
                                }
                                </div>
                            : <div className="payment-section-data">
                                <div className="payment_deta">
                                    <div className="d-flex paymnet-popup px-3">
                                        <div className="plan-details-section w-80  mt-4">
                                            <div className="payment_details">
                                                <div className=" timezone mb-2">
                                                   <span style={{width:'98%'}} className="mb-1 d-inline-block">
                                                       <b>
                                                           You have selected Service Plan
                                                       </b>
                                                   </span>
                                                    <Select
                                                        style={{width: '80%',maxHeight:'37px'}}
                                                        className="timezonedata"
                                                        variant="outlined"
                                                        value={servicePlan ? servicePlan : subscribePlan?.yearlyrate}
                                                        input={<BootstrapInput/>}
                                                    >
                                                        <MenuItem className={'cursor-pointer'} value={subscribePlan?.yearlyrate} >{plan ? plan : ""} Check In</MenuItem>
                                                        <MenuItem className={'cursor-pointer'} value={subscribePlan?.monthlyrate} >{plan ? plan : ""} Check In</MenuItem>
                                                    </Select>
                                                </div>
                                                <div className=" timezone mb-2">
                                                    <span style={{width:'98%'}} className="mb-1 d-inline-block">
                                                       <b>
                                                           Billing Frequency
                                                       </b>
                                                   </span>
                                                    <Select
                                                        style={{width: '80%',maxHeight:'37px'}}
                                                        className="timezonedata"
                                                        variant="outlined"
                                                        value={servicePlan ? servicePlan : subscribePlan?.yearlyrate}
                                                        onChange={(e) => {
                                                            selectServiceType(e)
                                                            setBillingFrequency(subscribePlan?.yearlyrate===e.target.value ? "Billed Yearly":"Billed Monthly")
                                                        }}
                                                        input={<BootstrapInput/>}
                                                    >
                                                        <MenuItem className={'cursor-pointer'} value={subscribePlan?.yearlyrate} > Yearly Billing </MenuItem>
                                                        <MenuItem className={'cursor-pointer'} value={subscribePlan?.monthlyrate} > Monthly Billing </MenuItem>
                                                    </Select>
                                                </div>
                                                <div className="timezone justify-content-between align-items-center mb-2">
                                                    <span className="d-block mb-1"><b>What timezone are you in?</b></span>
                                                    <Select
                                                        style={{width: '80%',maxHeight:'37px'}}
                                                        name="timeslot"
                                                        className="timezonedata "
                                                        inputProps={{"aria-label": "Without label"}}
                                                        variant="outlined"
                                                        value={nTimeZoneID}
                                                        onChange={(e) => setNTimeZoneID(e.target.value)}
                                                    >
                                                        {timezone.map((res,index) => (
                                                            <MenuItem value={res.id} key={index}>{res.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className="timezone mb-2">
                                                   <span style={{width:'98%'}} className="mb-1 d-inline-block">
                                                       <b>
                                                           {ServiceHeader.bkgdDescription}
                                                       </b>
                                                   </span>
                                                        <Select
                                                            style={{width: '80%',maxHeight:'37px'}}
                                                            className="timezonedata"
                                                            variant="outlined"
                                                            value={selectedDays}
                                                            onChange={handleChange}
                                                            input={<BootstrapInput/>}
                                                        >{getOptionData(subscribePlan?.id)}
                                                        </Select>
                                                </div>

                                            </div>
                                            {/*<div className={'d-flex align-items-center justify-content-between border-dotted-bottom '}>*/}
                                            {/*    <div className={'d-flex align-items-center'}>*/}
                                            {/*        <h6 className="font-weight-bold p-0 m-0" style={{fontSize:'18px'}} >Service Plan:</h6>*/}
                                            {/*        <span className="ml-2 font-weight-bold" style={{fontSize:'18px'}}>{subscribePlan?.shortdescription}</span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div>*/}
                                            {/*    <div className="paymnet-inner-content mt-3">*/}
                                            {/*        <div className="d-flex paymnet-plan-data">*/}
                                            {/*            <h6 className={'font-weight-bold'}>Plan Name </h6>*/}
                                            {/*            <p className={'font-weight-bold'}>{subscribePlan?.name}</p>*/}
                                            {/*        </div>*/}
                                            {/*        <div className="d-flex MainRadioBtn">*/}
                                            {/*            <h6 className={'font-weight-bold mt-1'} style={{width: '25%'}}>Plan*/}
                                            {/*                Price</h6>*/}
                                            {/*            <FormControl component="fieldset">*/}
                                            {/*                <RadioGroup*/}
                                            {/*                    aria-label="timeslot"*/}
                                            {/*                    name="timeslot"*/}
                                            {/*                    value={servicePlan}*/}
                                            {/*                    onChange={(e) => setSelectedService(e.target.value)}*/}
                                            {/*                >*/}
                                            {/*                    <div className={''}>*/}
                                            {/*                        <FormControlLabel*/}
                                            {/*                            className={'formControlRadio w-100'}*/}
                                            {/*                            value={subscribePlan?.monthlyrate.toString().trim()}*/}
                                            {/*                            control={<Radio color="primary"/>}*/}
                                            {/*                            label={<span*/}
                                            {/*                                className={'font-weight-bold'}>$ {subscribePlan?.monthlyrate}/monthly</span>}*/}
                                            {/*                        />*/}
                                            {/*                        <FormControlLabel*/}
                                            {/*                            className={'w-100'}*/}
                                            {/*                            value={subscribePlan?.yearlyrate.toString().trim()}*/}
                                            {/*                            control={<Radio color="primary"/>}*/}
                                            {/*                            label={<span*/}
                                            {/*                                className={'font-weight-bold'}>$ {subscribePlan?.yearlyrate}/Yearly</span>}*/}
                                            {/*                        />*/}
                                            {/*                    </div>*/}
                                            {/*                </RadioGroup>*/}
                                            {/*            </FormControl>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {priceplans.filter(e => (e.price == (servicePlan ? servicePlan : subscribePlan?.yearlyrate))).map((e)=>{
                                                return (
                                                    <div className={`selected-plan-info ${e.plan}`}>
                                                        <div className="imag-box"><img src={e.image}/></div>
                                                        <div className="checkin-text">{e.CheckIn} Service Plan</div>
                                                        <div className="billed-price">{e.billed}</div>
                                                        <div className="plan_price">${e.price.toFixed( 2)}{e.billed == "Billed Annually" ? "/yr" : "/mo"}</div>
                                                        <div className="text-center"><img src={rightIcon} alt="rightIcon" width="34px"/></div>
                                                    </div>
                                                )
                                            })}
                                            <Standardfeatures box={false} />
                                        </div>
                                        <SubscribePayment
                                            planPrice={servicePlan}
                                            nServicePlanID={nServicePlanID}
                                            selectedDays={selectedDays}
                                            nTimeZoneID={nTimeZoneID}
                                            stripProdNumber={stripProdNumber}
                                            pricingType={pricingType}
                                            subscribePlan={subscribePlan}
                                            description={description}
                                        />
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                {PaymentOpen === false && (
                    <>
                <div className="service_plan_section">
                    <div className="title text-center">
                        <h2 className="service_plan_title">CHOOSE A SERVICE PLAN </h2>
                        <h6 className="service_plan_des">Save 2 Months Free with Annual Billing</h6>
                        <div className="plan_btn_group">
                            <button
                                className={`${
                                    pricingType === "monthly" ? "btn active" : "btn"
                                }`}
                                onClick={() => setpricingType("monthly")}
                            >
                                Monthly
                            </button>
                            <button
                                className={`${pricingType === "yearly" ? "btn active" : "btn"}`}
                                onClick={() => setpricingType("yearly")}
                            >
                                Annual
                            </button>
                        </div>
                    </div>
                </div>


                    <div className="service_plan_section">
                        <div className="service_plan_main">
                            <table className="text-center service_plan_table w-100">
                                <tbody>
                                <tr>
                                    <td className="sticky-col"></td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <div className="service_plan_box">
                                                <img src={Maskgroup1}/>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <div className="service_plan_box">
                                                <img src={Maskgroup2}/>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <div className="service_plan_box">
                                                <img src={Maskgroup3}/>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <div className="service_plan_box">
                                                <img src={Maskgroup4}/>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <div className="service_plan_box">
                                                <img src={Maskgroup5}/>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-center">
                                            <div className="service_plan_box">
                                                <img src={Maskgroup6}/>
                                            </div>
                                        </div>
                                    </td>


                                </tr>
                                <tr>
                                    <td className="sticky-col"></td>
                                    <td>Yearly</td>
                                    <td>Quarterly</td>
                                    <td>Monthly</td>
                                    <td>Weekly</td>
                                    <td>3-days</td>
                                    <td>Daily</td>
                                </tr>
                                <tr>
                                    <td className="sticky-col"></td>
                                    <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
                                    <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
                                    <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
                                    <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
                                    <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
                                    <td>{pricingType === "monthly" ? ("Billed Monthly") : ("Billed Annually")}</td>
                                </tr>
                                <tr>
                                    <td className="text-left price-lable sticky-col">Price</td>
                                    <td>
                                        {pricingType === "monthly" ? (
                                            <h2 className="plan_price">
                                                $4.99<span>/mo</span>
                                            </h2>
                                        ) : (
                                            <h2 className="plan_price">
                                                $49.90<span>/yr</span>
                                            </h2>
                                        )}
                                    </td>
                                    <td>

                                        {pricingType === "monthly" ? (
                                            <h2 className="plan_price">
                                                $6.99<span>/mo</span>
                                            </h2>
                                        ) : (
                                            <h2 className="plan_price">
                                                $69.90<span>/yr</span>
                                            </h2>
                                        )}

                                    </td>
                                    <td>
                                        {pricingType === "monthly" ? (
                                            <h2 className="plan_price">
                                                $9.99<span>/mo</span>
                                            </h2>
                                        ) : (
                                            <h2 className="plan_price">
                                                $99.90<span>/yr</span>
                                            </h2>
                                        )}
                                    </td>
                                    <td>
                                        {pricingType === "monthly" ? (
                                            <h2 className="plan_price">
                                                $14.99<span>/mo</span>
                                            </h2>
                                        ) : (
                                            <h2 className="plan_price">
                                                $149.90<span>/yr</span>
                                            </h2>
                                        )}
                                    </td>
                                    <td>
                                        {pricingType === "monthly" ? (
                                            <h2 className="plan_price">
                                                $19.99<span>/mo</span>
                                            </h2>
                                        ) : (
                                            <h2 className="plan_price">
                                                $199.90<span>/yr</span>
                                            </h2>
                                        )}
                                    </td>
                                    <td>
                                        {pricingType === "monthly" ? (
                                            <h2 className="plan_price">
                                                $24.99<span>/mo</span>
                                            </h2>
                                        ) : (
                                            <h2 className="plan_price">
                                                $249.90<span>/yr</span>
                                            </h2>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-left sticky-col">Check In Frequency</td>
                                    <td>Yearly</td>
                                    <td>Quarterly</td>
                                    <td>Monthly</td>
                                    <td>Weekly</td>
                                    <td>3 Days/Week</td>
                                    <td>Daily</td>
                                </tr>
                                <tr>
                                    <td className="text-left sticky-col"> KTMW Standard Features</td>
                                    <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
                                    <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
                                    <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
                                    <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
                                    <td><img src={rightIcon} alt="rightIcon" width="34px" /></td>
                                    <td><img src={rightIcon} alt="rightIcon" width="34px"/></td>
                                </tr>
                                <tr>
                                    <td className="sticky-col"></td>
                                    <td>
                                        <p className="plan_start_btn">
                                            <button
                                                className="btn"
                                                disabled={
                                                    pricingType === "monthly" ? (
                                                        servicePlan === "4.99" ? true : ""
                                                    ) : (
                                                        servicePlan === "49.90" ? true : ""
                                                    )
                                                }
                                                onClick={()=> {
                                                    setPlan("Yearly")
                                                    if(pricingType === "monthly"){
                                                        selectServicePlan("4.99");
                                                    } else {
                                                        selectServicePlan("49.90");
                                                    }
                                                }}
                                            >
                                                {pricingType === "monthly" ? (
                                                    servicePlan === "4.99" ? "Selected" : "Change Plan"
                                                ) : (
                                                    servicePlan === "49.90" ? "Selected" : "Change Plan"
                                                )}
                                            </button>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="plan_start_btn">
                                            <button
                                                className="btn"
                                                disabled={
                                                    pricingType === "monthly" ? (
                                                        servicePlan === "6.99" ? true : ""
                                                    ) : (
                                                        servicePlan === "69.90" ? true : ""
                                                    )
                                                }
                                                onClick={()=> {
                                                    setPlan("Quarterly")
                                                    if(pricingType === "monthly"){
                                                        selectServicePlan("6.99");
                                                    } else {
                                                        selectServicePlan("69.90");
                                                    }
                                                }}
                                            >
                                                {pricingType === "monthly" ? (
                                                    servicePlan === "6.99" ? "Selected" : "Change Plan"
                                                ) : (
                                                    servicePlan === "69.90" ? "Selected" : "Change Plan"
                                                )}
                                            </button>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="plan_start_btn">
                                            <button
                                                className="btn"
                                                disabled={
                                                    pricingType === "monthly" ? (
                                                        servicePlan === "9.99" ? true : ""
                                                    ) : (
                                                        servicePlan === "99.90" ? true : ""
                                                    )
                                                }

                                                onClick={()=> {
                                                    setPlan("Monthly")
                                                    if(pricingType === "monthly"){
                                                        selectServicePlan("9.99");
                                                    } else {
                                                        selectServicePlan("99.90");
                                                    }
                                                }}
                                            >
                                                {pricingType === "monthly" ? (
                                                    servicePlan === "9.99" ? "Selected" : "Change Plan"
                                                ) : (
                                                    servicePlan === "99.90" ? "Selected" : "Change Plan"
                                                )}
                                            </button>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="plan_start_btn">
                                            <button
                                                className="btn"
                                                disabled={
                                                    pricingType === "monthly" ? (
                                                        servicePlan === "14.99" ? true : ""
                                                    ) : (
                                                        servicePlan === "149.90" ? true : ""
                                                    )
                                                }
                                                onClick={()=> {
                                                    setPlan("Weekly")
                                                    if(pricingType === "monthly"){
                                                        selectServicePlan("14.99");
                                                    }
                                                    else {
                                                        selectServicePlan("149.90");
                                                    }
                                                }}
                                            >
                                                {pricingType === "monthly" ? (
                                                    servicePlan === "14.99" ? "Selected" : "Change Plan"
                                                ) : (
                                                    servicePlan === "149.90" ? "Selected" : "Change Plan"
                                                )}
                                            </button>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="plan_start_btn">
                                            <button
                                                onClick={()=> {
                                                    setPlan("3-days")
                                                    if(pricingType === "monthly"){
                                                        selectServicePlan("19.99");
                                                    }
                                                    else {
                                                        selectServicePlan("199.90");
                                                    }
                                                }}
                                                disabled={
                                                    pricingType === "monthly" ? (
                                                        servicePlan === "19.99" ? true : ""
                                                    ) : (
                                                        servicePlan === "199.90" ? true : ""
                                                    )
                                                }
                                                className="btn"
                                            >
                                                {pricingType === "monthly" ? (
                                                    servicePlan === "19.99" ? "Selected" : "Change Plan"
                                                ) : (
                                                    servicePlan === "199.90" ? "Selected" : "Change Plan"
                                                )}
                                            </button>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="plan_start_btn">
                                            <button
                                                onClick={()=> {
                                                    setPlan("Daily")
                                                    if(pricingType === "monthly"){
                                                        selectServicePlan("24.99");
                                                    }
                                                    else {
                                                        selectServicePlan("249.90");
                                                    }

                                                }}
                                                disabled={
                                                    pricingType === "monthly" ? (
                                                        servicePlan === "24.99" ? true : ""
                                                    ) : (
                                                        servicePlan === "249.90" ? true : ""
                                                    )
                                                }
                                                className="btn"
                                            >
                                                {pricingType === "monthly" ? (
                                                    servicePlan === "24.99" ? "Selected" : "Change Plan"
                                                ) : (
                                                    servicePlan === "249.90" ? "Selected" : "Change Plan"
                                                )}
                                            </button>
                                        </p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                        <Standardfeatures box={true} />
                </>
                )}
            </section>
        </>
    )
}
export default ChooseServicePlan;
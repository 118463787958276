import React, {useEffect, useState} from "react";
import Header from "../../components/NewHeader/Header";
import {Col, Row, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import Logout from "../../components/logout/Logout";
import "./WishesSharedWithMe.css";
import {Button} from "@material-ui/core";
import axios from "axios";
import genie_img from '../../assets/images/genie_image.jpg'
import SessionExpired from '../../components/SessionExpired/SessionExpired'
import Back from '../../assets/images/Back_white.png';
import DraggebleModal from "../../components/DraggebleModal";
import defaultProfile from "../../assets/images/default-profile-icon.jpg";

const WishesSharedWithMe = () => {
    const [sharedewishes, setSharedWishes] = useState([]);
    const [isLoadedContactData, setIsLoadedContactData] = useState(false);
    const [showLegend, setShowLegend] = useState(false);
    const [TokenExpired, setTokenExpired] = useState(false);

    const handleLegendDailog = () => {
        setShowLegend(true)
    }

    useEffect(() => {
        const id = localStorage.getItem("userid");
        const token = localStorage.getItem("token");
        axios.get(process.env.REACT_APP_SERVER + `inventory/getsharedwithwhom?nUserID=${id}`,  {
                    headers: {Authorization: `Bearer ${token}`},
            }).then((res) => {
                if (res.data.status) {
                    setTokenExpired(true)
                } else {
                    setSharedWishes(res.data);
                    setIsLoadedContactData(true)
                }
            }).catch((err) => {
                console.log(err);
            });
    }, []);

    const styles = {
        HandleRow: {
            maxWidth: "900px",
        },
    };
    return (
        <>
            {TokenExpired && <SessionExpired />}
            <section className="wishes-shared-section">
                <Header title="WISHES SHARED WITH ME"/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maindashboard" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <Logout/>
                    </Col>
                </Row>
                <div>
                    <img src={genie_img} className="genie ml-5" onClick={handleLegendDailog}/>
                </div>
                {
                    isLoadedContactData && sharedewishes.length === 0
                        ? <div className='wishesrelease'>
                            <h1 className={'d-flex justify-content-center text-secondary align-items-center h-100'} style={{fontSize: '20px',width:'50%'}}>
                                No Wishes have been Shared with you.
                                <br/><br/>Shared With indicates all of the Wishes that you and/or your contacts in our system have shared with each other and are always available
                                to be viewed to enable your family to have the most crucial information available when they need it the most.
                                <br/><br/>You can accomplish this in the My Wishes module.
                                <br/><br/>By selecting the + you are able to choose as many of your contacts that you want to have access to the information in this Wish Item. This gives all parties the ability to
                                decide what information they want their loved ones to have access to and when they want them to see it.
                                <br/><br/>Example: Life Insurance policy location, your medical directive, your primary doctor/hospital preferences, your instructions for your care.

                            </h1>
                        </div>
                        : sharedewishes.map((shared,index) => {
                            return (
                                <div key={index}>
                                    <div className={"m-2"}>
                                        <div
                                            className={"main-container-wishes card"}
                                            style={styles.HandleRow}
                                        >
                                            <div className={""}>
                                                <div className={"main-wishes"}>
                                                    <img
                                                        src={
                                                            shared["ownerProfileImage"]
                                                             ? `https://reactnotes.s3.us-east-2.amazonaws.com/${shared["ownerProfileImage"]}`
                                                             : defaultProfile
                                                        }
                                                    />
                                                    <div>
                                                        <h5 className={"mt-3 ml-2"}>
                                                            {shared["ownerFirstName"]} {shared["ownerLastName"]}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className={"mt-1 WishesbyUser"}>
                                                    <p className={"ml-3 mt-1"} style={{maxHeight: "50px"}}>
                                                        {shared.itemname?.split("\n").map((str) => <span className='d-block' key={str}>{str}</span>)}
                                                    </p>
                                                    <Link to={`/View-Shared-Wish/${shared.WishID}`}>
                                                        <Button variant="contained" color="primary">
                                                            Details...
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                }
            </section>
            <DraggebleModal
                screen={7}
                isOpen={showLegend}
                setOpenModal={setShowLegend}
            />
        </>
    );
};

export default WishesSharedWithMe;

import React, {useEffect, useState} from 'react';
import Header from "../../components/NewHeader/Header";
import {Col, Row, Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import Logout from "../../components/logout/Logout";
import genie_img from '../../assets/images/genie_image.jpg'
import axios from "axios";
import {Button} from "@material-ui/core";
import './WishesReleasedWithMe.css';
import Back from '../../assets/images/Back_white.png';
import DraggebleModal from "../../components/DraggebleModal";


const WishesReleasedWithMe = () => {
    const [ReleasedWishes, setReleasedWishes] = useState([]);
    const [showLegend, setShowLegend] = React.useState(false);

    const handleLegendDailog = () => {
        setShowLegend(true)
    }

    useEffect(() => {
        const id = localStorage.getItem("userid");
        const token = localStorage.getItem("token");
        axios.get(process.env.REACT_APP_SERVER +
                `inventory/getreleasedtowhom?nUserID=${id}`,{
                    headers: {Authorization: `Bearer ${token}`},
            }).then((res) => {
                setReleasedWishes(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const styles = {
        HandleRow: {
            maxWidth: "900px",
        },
    };
    return (
        <>
            <section className="wishes-Released-section">
                <Header title=" WISHES RELEASED TO ME "/>
                <Row className="mx-auto text-center mobile-button back-logout-btn">
                    <Col
                        lg={6}
                        md={6}
                        sm={6}
                        xs={6}
                        className="p-2 text-start profile-left-btn d-flex btnall"
                    >
                        <Link to="/maindashboard" className="btn custom-link btn-primary">
                            <img src={Back} alt="Back" className="w-5"/> Back
                        </Link>
                    </Col>
                    <Col
                        lg={6} md={6} sm={6} xs={6} className="btnall"
                    >
                        <Logout/>
                    </Col>
                </Row>
                <div>
                    <img src={genie_img} alt="genie_img" className="genie ml-5" onClick={handleLegendDailog}/>
                </div>
                {
                    ReleasedWishes.length === 0
                        ?<div className="wishesrelease">
                            <h1 className={'d-flex justify-content-center text-secondary mainreleasedata align-items-center h-100'} style={{fontSize:'20px',width:'50%'}}>No Wishes have been Released to you.
                                <br/><br/>Released To indicates all of the Wishes being made available to be viewed and executed according to your instructions by one or more of your contacts when you become Incapacitated or have died.
                                <br/><br/>You can accomplish this in the My Wishes module.
                                <br/><br/>By selecting the + you are able to choose one or more of your contacts that you want to have access to the information of this Wish Item when you become Incapacitated or upon Death.
                            </h1>
                        </div>
                        :ReleasedWishes.map((shared) => {
                            return (
                                <>
                                    <div className={"m-2"}>
                                        <div
                                            className={"main-container-wishes card"}
                                            style={styles.HandleRow}
                                        >
                                            <div className={""}>
                                                <div className={"main-wishes"}>
                                                    <img
                                                        src={`https://reactnotes.s3.us-east-2.amazonaws.com/${shared.s3filename}`}
                                                    />
                                                    <div>
                                                        <h5 className={"mt-3 ml-2"}>
                                                            {shared.fname} {shared.lname}
                                                        </h5>
                                                    </div>
                                                </div>
                                                <div className={"mt-1 WishesbyUser"}>
                                                    <p className={"ml-3 mt-1"} style={{maxHeight: "50px"}}>
                                                        {shared.itemname}
                                                    </p>
                                                    <Link to={`/View-Released-Wish/${shared.id}`}>
                                                        <Button variant="contained" color="primary">
                                                            Details...
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        })
                }
            </section>
            <DraggebleModal
                screen={8}
                isOpen={showLegend}
                setOpenModal={setShowLegend}
            />
        </>
    );
};

export default WishesReleasedWithMe;
import React, {useState, useEffect} from 'react';

import {Link, useHistory, useLocation} from 'react-router-dom';
import {Row, Form, Button, Toast} from 'react-bootstrap';
import axios from 'axios';
import '@pathofdev/react-tag-input/build/index.css';
import './notesform.css';
import SectionTabBars from './SectionTabBars';
import Section1 from './Section1';
import Section2 from './Section2';
import DeleteModal from './DeleteModal';
import Backdrop from '@material-ui/core/Backdrop';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const Notesform = ({
                       contactsData,
                       getAllItems,
                       suggestionsData,
                       releaseMyInformation,
                       getReleaseMyInformation,
                   }) => {
    const [itemName, setItemName] = useState('');
    const [itemNotes, setItemNotes] = useState('');
    const [
        existingSharedReleaseWithTags,
        setExistingSharedReleaseWithTags,
    ] = useState([]);
    const [existingSharedContacts, setExistingSharedContacts] = useState([]);
    const [shareReleaseContacts, setShareReleaseContacts] = useState(
        contactsData
    );
    const [existingFiles, setExistingFiles] = useState([]);
    const [files, setFiles] = useState([]);

    const [deleteModalShow, setDeleteModalShow] = React.useState(false);

    const [searchData, setSearchData] = useState({});
    const [showTost, setShowTost] = useState(false);

    let id = useLocation().search;
    const history = useHistory();
    const rowid = id.replace('?', '');

    const [isProgress, setIsprogress] = useState(false)

    useEffect(() => {
        if (rowid) {
            setShareReleaseContacts([]);
            setFiles([]);
            const data = {
                nUserID: localStorage.getItem('userid'),
                nID: rowid,
            };
            var token = localStorage.getItem('token');
            axios
                .post(
                    process.env.REACT_APP_SERVER + 'inventory/getdetails',
                    data,
                    {headers: {Authorization: `Bearer ${token}`}}
                )
                .then((res) => {
                    console.log(res.data)
                    setSearchData((res.data || []).length ? res.data[0] : {});
                })
                .catch((err) => {
                    console.log(err);
                });
            getmedia();

            axios
                .get(
                    process.env.REACT_APP_SERVER +
                    `contact/getisshareisreleaseto?nUserID=${localStorage.getItem(
                        'userid'
                    )}&nInvItemID=${rowid}`,
                    {headers: {Authorization: `Bearer ${token}`}}
                )
                .then((res) => {
                    const data = (res.data || []).length ? res.data : [];
                    setExistingSharedReleaseWithTags(data);
                    let SharedContacts = [];
                    if (data && data.length) {
                        data.map((contact) => {
                            return contact.sharedwith === 1
                                ? SharedContacts.push({
                                    ...contact,
                                    isSendText: 0,
                                    isSendEmail: 0,
                                })
                                : null;
                        });
                    }
                    setExistingSharedContacts(SharedContacts);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [rowid]);

    useEffect(() => {
        setItemName(searchData !== undefined ? searchData.itemname : '');
        setItemNotes(searchData !== undefined ? searchData.itemnotes : '');
    }, [searchData]);

    useEffect(() => {
        if (!rowid) {
            setShareReleaseContacts(contactsData);
        }
    }, [rowid, contactsData]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTost(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, [showTost]);

    const toggleTost = () => setShowTost(!showTost);

    function getmedia() {
        var token = localStorage.getItem('token');
        axios
            .post(
                process.env.REACT_APP_SERVER + 'inventory/getmedia',
                {nInvItemID: rowid},
                {headers: {Authorization: `Bearer ${token}`}}
            )
            .then((res) => {
                setExistingFiles((res.data || []).length ? res.data : []);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function addNewItem() {
        setItemName('');
        setItemNotes('');
        setFiles([]);
        setExistingFiles([]);
        setExistingSharedReleaseWithTags([]);
        setShareReleaseContacts(contactsData);
        setSearchData({});
        history.push('/newDashboard/');
    }

    function saveIsShareIsReleaseto(nInvItemID) {
        if (existingSharedReleaseWithTags && existingSharedReleaseWithTags.length) {
            return existingSharedReleaseWithTags
                .filter(
                    (existing) =>
                        existing.id === null && (existing.sharedwith || existing.releaseto)
                )
                .map((f) => {
                    const data = {
                        nUserID: localStorage.getItem('userid'),
                        nContactID: f.contactid,
                        nInvItemID: nInvItemID,
                        nIsSharedWith: f.sharedwith,
                        nIsReleaseTo: f.releaseto,
                    };

                    var token = localStorage.getItem('token');
                    return axios
                        .post(
                            process.env.REACT_APP_SERVER +
                            'contact/saveisshareisreleaseto',
                            data,
                            {headers: {Authorization: `Bearer ${token}`}}
                        )
                        .then((res) => {
                            console.log('res::', res);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        }
        if (shareReleaseContacts && shareReleaseContacts.length) {
            return shareReleaseContacts.map((f) => {
                const data = {
                    nUserID: localStorage.getItem('userid'),
                    nContactID: f.contactid,
                    nInvItemID: nInvItemID,
                    nIsSharedWith: f.sharedwith,
                    nIsReleaseTo: f.releaseto,
                };

                var token = localStorage.getItem('token');
                return axios
                    .post(
                        process.env.REACT_APP_SERVER +
                        'contact/saveisshareisreleaseto',
                        data,
                        {headers: {Authorization: `Bearer ${token}`}}
                    )
                    .then((res) => {
                        console.log('res::', res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        }
    }

    function saveMedia(nInvItemID) {
        setIsprogress(true)
        if (files && files.length) {
            return files.map((f) => {
                var token = localStorage.getItem('token');

                var saveFiledata = new FormData();
                saveFiledata.append('nUserID', localStorage.getItem('userid'));
                saveFiledata.append('nInvItemID', nInvItemID);
                saveFiledata.append('imgfile', f);

                return axios.post(
                    process.env.REACT_APP_SERVER + 'uploads3file',
                    saveFiledata,
                    {headers: {Authorization: `Bearer ${token}`}}
                ).then((res) => {
                    setIsprogress(false)
                    var data = new FormData();
                    data.append('nUserID', localStorage.getItem('userid'));
                    data.append('nInvItemID', nInvItemID);
                    data.append('sFileName', res.data.filename);
                    data.append('nFileSize', f.size);

                    axios.post(process.env.REACT_APP_SERVER + 'inventory/addmedia', data,
                        {headers: {Authorization: `Bearer ${token}`}}
                    ).then((res) => {
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch((err) => {
                    setIsprogress(false)
                    console.log(err);
                });
            });
        }
        setFiles([]);
        getmedia();
        setIsprogress(false)
    }

    const onChangeFiles = (e) => {
        if (e.target.files && e.target.files.length) {
            setFiles([...files, e.target.files[0]]);
        }
    };

    function removedFiles(index) {
        let newFiles = [];
        files.map((file, fIndex) =>
            index !== fIndex ? newFiles.push(file) : null
        );
        setFiles(newFiles);
    }

    function removedExistingFiles(mediaID) {
        if (mediaID) {
            const data = {
                nUserID: localStorage.getItem('userid'),
                nID: mediaID,
                nInvItemID: rowid,
            };
            var token = localStorage.getItem('token');
            axios
                .post(
                    process.env.REACT_APP_SERVER + 'inventory/deletemedia',
                    data,
                    {headers: {Authorization: `Bearer ${token}`}}
                )
                .then((res) => {
                    let newFiles = [];
                    existingFiles.map((file, fIndex) =>
                        file.id !== mediaID ? newFiles.push(file) : null
                    );
                    setExistingFiles(newFiles);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    function removedExistingSharedToReleasedTo(
        index,
        ID,
        nContactID,
        isShareIsRelease
    ) {
        if (ID) {
            const data = {
                nUserID: localStorage.getItem('userid'),
                nID: ID,
                nInvItemID: rowid,
                nContactID: nContactID,
            };

            var token = localStorage.getItem('token');
            axios
                .post(
                    process.env.REACT_APP_SERVER +
                    `inventory/delete${isShareIsRelease}`,
                    data,
                    {headers: {Authorization: `Bearer ${token}`}}
                )
                .then((res) => {
                    let newSharedRelease = existingSharedReleaseWithTags.map(
                        (shareRelease) => {
                            return {
                                ...shareRelease,
                                ...(shareRelease.id === ID
                                    ? {
                                        [isShareIsRelease === 'share'
                                            ? 'sharedwith'
                                            : 'releaseto']: 0,
                                    }
                                    : {}),
                            };
                        }
                    );
                    setExistingSharedReleaseWithTags(newSharedRelease);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            const newContactsData = (rowid
                    ? existingSharedReleaseWithTags
                    : shareReleaseContacts
            ).map((contact, cIndex) => {
                return {
                    ...contact,
                    ...(contact.contactid === Number(nContactID) && cIndex === index
                        ? {[isShareIsRelease === 'share' ? 'sharedwith' : 'releaseto']: 0}
                        : {}),
                };
            });
            setExistingSharedReleaseWithTags(newContactsData);
        }
    }

    const handleIsShareToChange = (event) => {
        if (rowid && !event.target.checked) {
            existingSharedReleaseWithTags.map((contact, index) =>
                contact.contactid === Number(event.target.id)
                    ? removedExistingSharedToReleasedTo(
                        index,
                        contact.id,
                        contact.contactid,
                        'share'
                    )
                    : null
            );
        } else {
            const newContactsData = (rowid
                    ? existingSharedReleaseWithTags
                    : shareReleaseContacts
            ).map((contact) => {
                return {
                    ...contact,
                    ...(contact.contactid === Number(event.target.id)
                        ? {sharedwith: event.target.checked ? 1 : 0}
                        : {}),
                };
            });
            if (rowid) setExistingSharedReleaseWithTags(newContactsData);
            else setShareReleaseContacts(newContactsData);
        }
    };

    const handleIsReleaseToChange = (event) => {
        if (rowid && !event.target.checked) {
            existingSharedReleaseWithTags.map((contact, index) =>
                contact.contactid === Number(event.target.id)
                    ? removedExistingSharedToReleasedTo(
                        index,
                        contact.id,
                        contact.contactid,
                        'release'
                    )
                    : null
            );
        } else {
            const newContactsData = (rowid
                    ? existingSharedReleaseWithTags
                    : shareReleaseContacts
            ).map((contact) => {
                return {
                    ...contact,
                    ...(contact.contactid === Number(event.target.id)
                        ? {releaseto: event.target.checked ? 1 : 0}
                        : {}),
                };
            });
            if (rowid) setExistingSharedReleaseWithTags(newContactsData);
            else setShareReleaseContacts(newContactsData);
        }
    };

    const removeItems = (e) => {
        if (rowid) {
            const data = {
                nUserID: localStorage.getItem('userid'),
                nInvItemID: rowid,
            };

            var token = localStorage.getItem('token');
            axios
                .post(
                    process.env.REACT_APP_SERVER + `inventory/deleteitem`,
                    data,
                    {headers: {Authorization: `Bearer ${token}`}}
                )
                .then((res) => {
                    setDeleteModalShow(false);
                    addNewItem();
                    getAllItems();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    return (
        <>
            <div
                aria-live="polite"
                aria-atomic="true"
                style={{
                    position: 'relative',
                    backgroundColor: 'red',
                }}
            >
                <Toast
                    style={{
                        position: 'absolute',
                        top: -85,
                        right: -20,
                        backgroundColor: 'red',
                    }}
                    show={showTost}
                    onClose={toggleTost}
                >
                    <Toast.Header>
                        <strong className="mr-auto">
                            Sorry Wish Description Item cannot exceed 500 characters.
                        </strong>
                    </Toast.Header>
                </Toast>
            </div>
            <Row className="text-right mb-4 justify-content-end">
                <Link to="/addNewItem">
                    <Button
                        variant="success"
                        className="mx-2 ml-auto btn-sm-height"
                        onClick={addNewItem}
                    >
                        Add New Item
                    </Button>
                </Link>
                <Link to={rowid ? `/addNewItem/?${rowid}` : '/AddNewItem'}>
                    <Button
                        variant="primary"
                        disabled={rowid ? false : true}
                        className="mx-2 ml-auto btn-sm-height"
                    >
                        Edit
                    </Button>
                </Link>
                <Button
                    variant="danger"
                    className="mx-2 btn-sm-height"
                    disabled={rowid ? false : true}
                    onClick={() => setDeleteModalShow(true)}
                >
                    Delete
                </Button>
            </Row>
            <Form className="notes-form">
                <Section1
                    isLabelExist={true}
                    nIncapacitated={releaseMyInformation.isdeceased}
                    nDeceased={releaseMyInformation.isincapacitated}
                />
                <Section2
                    isInputDisabled={true}
                    itemName={itemName}
                    setItemName={setItemName}
                    itemNotes={itemNotes}
                    setItemNotes={setItemNotes}
                    suggestionsData={suggestionsData}
                    rowid={rowid}
                    existingSharedContacts={existingSharedContacts}
                />
                {/*/!*<Form.Group*/}
                {/*	controlId="exampleForm.ControlInput2"*/}
                {/*	className="justify-content-center"*/}
                {/*>*/}
                {/*	<Button*/}
                {/*		variant="primary"*/}
                {/*		type="submit"*/}
                {/*		className="btn-lg custom-btn w-50"*/}
                {/*		disabled={itemName && itemNotes ? false : true}*/}
                {/*		onClick={submitNewItem}*/}
                {/*	>*/}
                {/*		{rowid ? 'Save Item Information' : 'Add Item Information'}*/}
                {/*	</Button>*/}
                {/*</Form.Group>*!/*/}
                <SectionTabBars
                    isProgress={isProgress}
                    itemName={itemName}
                    existingSharedReleaseWithTags={existingSharedReleaseWithTags}
                    shareReleaseContacts={shareReleaseContacts}
                    rowid={rowid}
                    handleIsShareToChange={handleIsShareToChange}
                    handleIsReleaseToChange={handleIsReleaseToChange}
                    saveIsShareIsReleaseto={saveIsShareIsReleaseto}
                    onChangeFiles={onChangeFiles}
                    existingFiles={existingFiles}
                    removedExistingFiles={removedExistingFiles}
                    files={files}
                    removedFiles={removedFiles}
                    saveMedia={saveMedia}
                    nIncapacitated={releaseMyInformation.isincapacitated}
                    nDeceased={releaseMyInformation.isdeceased}
                    getReleaseMyInformation={getReleaseMyInformation}
                />
            </Form>

            {deleteModalShow ? (
                <DeleteModal
                    deleteModalShow={deleteModalShow}
                    setDeleteModalShow={setDeleteModalShow}
                    removeItems={removeItems}
                />
            ) : null}
        </>
    );
};

export default Notesform;
